import {Button, Form, Skeleton} from "antd";
import {DownloadOutlined} from "@ant-design/icons";
import {WorkTimeService} from "../../services/WorkTimeService";
import {useState} from "react";
import ApiParamBuilder from "../../utils/ApiParamBuilder";

const DownloadResumeButton = ({loading, validating}) => {

    const form = Form.useFormInstance();

    const [downloading, setDownloading] = useState(false)

    const onDownload = () => {
        setDownloading(true)
        let fieldsValue = form.getFieldsValue();
        WorkTimeService.download({
            ...ApiParamBuilder.periodToQueryParam(fieldsValue.period),
            clients_id: ApiParamBuilder.idsToQueryParam(fieldsValue.clients_id),
            fiscal_years_id: ApiParamBuilder.idsToQueryParam(fieldsValue.fiscal_years_id),
            tasks_id: ApiParamBuilder.idsToQueryParam(fieldsValue.tasks_id),
            users_id: ApiParamBuilder.idsToQueryParam(fieldsValue.users_id)
        }, (_) => {
            setDownloading(false)
        })
    }

    return <>
        {
            loading ?
                <Skeleton.Button active block/> :
                <Button style={{width: "100%"}}
                        loading={validating || downloading}
                        onClick={onDownload}
                        icon={<DownloadOutlined/>}>
                    Télécharger
                </Button>
        }
    </>
}

export default DownloadResumeButton;