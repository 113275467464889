import {TaskRepository} from "../repositories/TaskRepository";
import {NotificationSender} from "../utils/NotificationSender";
import Session from "../session/Session";

const TaskServiceCache = {
    gets: {},
}

export const TaskService = {
    gets : (filters, callback) => {
        Session.resetTimerBeforeLogout()
        let stringifyFilters = JSON.stringify(filters);
        if (TaskServiceCache.gets[stringifyFilters]) {
            callback(false, JSON.parse(JSON.stringify(TaskServiceCache.gets[stringifyFilters])))
        } else {
            TaskRepository.gets(filters, (err, data) => {
                if (err) {
                    if (!err.errors) {
                        NotificationSender.error("Chargement", "Une erreur est survenue lors du chargement des tâches")
                    }
                    callback(err)
                } else {
                    TaskServiceCache.gets[stringifyFilters] = JSON.parse(JSON.stringify(data))
                    callback(false, data)
                }
            })
        }
    },
    delete: (id, callback) => {
        Session.resetTimerBeforeLogout()
        TaskRepository.delete(id, (err, data) => {
            if (err) {
                if (!err.errors) {
                    NotificationSender.error("Suppression", "Une erreur est survenue lors de la suppression de la tâche")
                }
                callback(err)
            } else {
                TaskService.clearCache();
                NotificationSender.success("Suppression", "La tâche à bien été supprimée")
                callback(false, data)
            }
        })
    },
    put: (data, callback) => {
        Session.resetTimerBeforeLogout()
        TaskRepository.put(data.id, data, (err, data) => {
            if (err) {
                if (!err.errors) {
                    NotificationSender.error("Modification", "Une erreur est survenue lors de la modification de la tâche")
                }
                callback(err)
            } else {
                TaskService.clearCache();
                NotificationSender.success("Modification", "La tâche à bien été modifiée")
                callback(false, data)
            }
        })
    },
    post: (data, callback) => {
        Session.resetTimerBeforeLogout()
        TaskRepository.post(data, (err, data) => {
            if (err) {
                if (!err.errors) {
                    NotificationSender.error("Création", "Une erreur est survenue lors de la création de la tâche")
                }
                callback(err)
            } else {
                TaskService.clearCache();
                NotificationSender.success("Création", "La tâche à bien été créée")
                callback(false, data)
            }
        })
    },
    clearCache: () => {
        TaskServiceCache.gets = {};
    }
}
