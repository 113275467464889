import {Button, Col, Form, Input, Modal, Radio, Row} from "antd";
import {useEffect, useState} from "react";
import {SaveOutlined} from "@ant-design/icons";
import {RoleService} from "../../../services/RoleService";
import {UserService} from "../../../services/UserService";
import RoleSelect from "../../../utils/form/select/enums/RoleSelect";
import UserAuthority from "../../../utils/UserAuthority";
import dayjs from "dayjs";
import {MilliFormater} from "../../../utils/MilliFormater";
import AuthoritySelect from "../../../utils/form/select/enums/AuthoritySelect";
import Session from "../../../session/Session";
import MaxDurationsForm from "./MaxDurationsForm";

const userToFormData = (user) => {
    if (!!user) {
        let workLimits = user.workLimits.map(workLimit => ({
            id: workLimit.id,
            dayOfWeek: workLimit.dayOfWeek,
            maxWorkDuration: workLimit.maxWorkDuration !== -1 ? dayjs.unix(workLimit.maxWorkDuration * 60).utc() : null
        }));
        workLimits.sort((a, b) => a.dayOfWeek - b.dayOfWeek)
        return {
            ...user,
            rolesId: user.roles.map(role => role.id),
            authorityId: user.authority.id,
            workLimits: workLimits
        }
    }
}

const DEFAULT_WORK_LIMITS = {
    [UserAuthority.COLLABORATEUR.id]: {
        0: dayjs.unix(8 * 60 * 60).utc(),
        1: dayjs.unix(8 * 60 * 60).utc(),
        2: dayjs.unix(8 * 60 * 60).utc(),
        3: dayjs.unix(8 * 60 * 60).utc(),
        4: dayjs.unix(8 * 60 * 60).utc(),
        5: dayjs.unix(0).utc(),
        6: dayjs.unix(0).utc()
    },
    [UserAuthority.ASSOCIE.id]: {
        0: null,
        1: null,
        2: null,
        3: null,
        4: null,
        5: null,
        6: null
    }
}

const getAllKeys = (obj) => {
    let res = [];
    if (Array.isArray(obj)) {
        obj.forEach((e, index) => {

            getAllKeys(e).forEach(t => {
                res.push([index, ...t])
            })
        })
    } else if (!!obj && typeof obj === "object" && !obj.$d) {
        Object.keys(obj).forEach(key => {
            if (!obj[key]) {
            } else if (typeof obj[key] !== "object") {
                res.push([key])
            } else {
                let allKeys = getAllKeys(obj[key]);
                if (allKeys.length === 0) {
                    allKeys = [[]]
                }
                allKeys.forEach(t => {
                    res.push([key, ...t])
                })
            }
        })
    }
    return res;
}

const UserFormModal = ({user, onCancel, userSaved}) => {
    const [form] = Form.useForm();

    const [roles, setRoles] = useState([])
    const [authorities] = useState(Object.values(UserAuthority)
        .filter(authority => authority.id !== UserAuthority.ADMINISTRATEUR.id))

    const [formValidating, setFormValidating] = useState(false)

    const [fieldsDisplay, setFieldsDisplay] = useState({
        rolesId: {
            loading: true
        }
    })

    const [initialValues, _] = useState(
        userToFormData(user) ||
        {
            rolesId: ["all"],
            enabled: false,
            workLimits: [
                {dayOfWeek: 0, maxWorkDuration: null},
                {dayOfWeek: 1, maxWorkDuration: null},
                {dayOfWeek: 2, maxWorkDuration: null},
                {dayOfWeek: 3, maxWorkDuration: null},
                {dayOfWeek: 4, maxWorkDuration: null},
                {dayOfWeek: 5, maxWorkDuration: null},
                {dayOfWeek: 6, maxWorkDuration: null}
            ]
        }
    )

    const setLoading = (field, loading) => {
        setFieldsDisplay(current => ({
            ...current,
            [field]: {
                ...current[field],
                loading
            }
        }))
    }

    const refreshRoles = (newRoles, allRoles) => {
        if (newRoles.length > 1) {
            let newRolesValue = ["all"]
            if (newRoles[newRoles.length - 1] !== "all" && newRoles.length < allRoles.length - 1) {
                newRolesValue = newRoles.filter(v => v !== "all")
            }
            form.setFieldValue("rolesId", newRolesValue)
        }
    }

    useEffect(() => {
        RoleService.gets( (err, data) => {
            if (!err) {
                let newRoles = [{id: 'all', name: 'Tous les taux'}, ...data];
                setRoles(newRoles);
                setLoading("rolesId", false)
                refreshRoles(initialValues.rolesId, newRoles)
            }
        })
    }, []);

    const onChangeRoles = (e) => {
        refreshRoles(e, roles)
    }

    const onFinish = (values) => {
        form.setFields(getAllKeys(values).map(fieldName => ({
            name: fieldName,
            errors: null,
            validating: true
        })))
        setFormValidating(true)
        let body = {
            ...values,
            rolesId: values.rolesId[0] === "all" ? roles.map(_role => _role.id).filter(id => id !== "all") : values.rolesId,
            authorityId: values.authorityId,
            workLimits: values.workLimits.map(workLimit => ({
                id: workLimit.id,
                dayOfWeek: workLimit.dayOfWeek,
                maxWorkDuration: workLimit.maxWorkDuration ? MilliFormater.hourDurationToMin(workLimit.maxWorkDuration) : -1
            }))
        };
        let request = UserService.post
        if (!!user) {
            request = UserService.put
            body.id = user.id
        }
        request(body, (err, data) => {
            if (err) {
                let fields = Object.keys(values).map(fieldName => ({
                    name: fieldName,
                    errors: null,
                    validating: false
                }));
                if (!!err.errors) {
                    Object.values(err.errors.fields
                        .map(fields => ({
                            name: fields.field.map(field => isNaN(Number(field)) ? field : Number(field)),
                            errors: [fields.message]
                        })
                        )
                        .reduce((obj, fields) => {
                            let fieldStringiFy = JSON.stringify(fields.name);
                            return ({
                                ...obj,
                                [fieldStringiFy]: obj[fieldStringiFy] ? {
                                    ...obj[fieldStringiFy],
                                    errors: [...obj[fieldStringiFy].errors, ...fields.errors]
                                } : fields
                            })
                        }, {}))
                        .forEach(fieldsError => {
                            fields.push({
                                ...fieldsError,
                                validating: false
                            })
                        })
                }
                form.setFields(fields)
                setFormValidating(false)
            } else {
                if (Session.user.id === data.id) {
                    Session.user = data
                }
                userSaved(data, !user)
            }
        })
    }

    const onChangeAuthority = (e) => {
        form.getFieldValue("workLimits").forEach(workLimit => {
            let fieldName = ["workLimits", workLimit.dayOfWeek, "maxWorkDuration"];
            if (!form.isFieldTouched(fieldName)) {
                form.setFieldValue(fieldName, DEFAULT_WORK_LIMITS[e][workLimit.dayOfWeek])
            }
        })
    }

    const onCancelAskConfirm = () => {
        if (form.isFieldsTouched()) {
            Modal.confirm({
                title: (user ? "Modification" : "Ajout")+ " en cours",
                content: "Voulez-vous sauvegarder les modifications ?",
                okText: "Oui",
                cancelText: "Non",
                onCancel: onCancel
            })
        } else {
            onCancel()
        }
    }

    return <Modal
        open
        onOk={formValidating ? null : onCancelAskConfirm}
        onCancel={formValidating ? null : onCancelAskConfirm}
        title={user ? "Modifier l'utilisateur" : "Ajouter un utilisateur"}
        footer={null}
        width={750}
        centered
    >
        <Form
            layout="vertical"
            name="userForm"
            form={form}
            onFinish={onFinish}
            initialValues={initialValues}
        >
            <Row gutter={16}>
                <Col span={9}>
                    <Form.Item
                        name="firstName"
                        label="Prénom"
                        hasFeedback={form.isFieldValidating("firstName")}
                        rules={[{required: true, message: "Prénom à saisir"}]}
                    >
                        <Input
                            disabled={form.isFieldValidating("firstName")}
                            placeholder="Saisir le prénom de l'utilisateur"
                        />
                    </Form.Item>
                </Col>
                <Col span={9}>
                    <Form.Item
                        name="lastName"
                        label="Nom"
                        hasFeedback={form.isFieldValidating("lastName")}
                        rules={[{required: true, message: "Nom à saisir"}]}
                    >
                        <Input
                            disabled={form.isFieldValidating("lastName")}
                            placeholder="Saisir le nom de l'utilisateur"
                        />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        name="trigram"
                        label="Trigramme"
                        hasFeedback={form.isFieldValidating("trigram")}
                        rules={[
                            {
                                required: true,
                                message: "Trigramme à saisir"
                            },
                            {
                                min: 3,
                                max: 3,
                                message: "Trigramme invalide"
                            }
                        ]}
                    >
                        <Input
                            disabled={form.isFieldValidating("trigram")}
                            placeholder="Saisir le trigramme de l'utilisateur"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                        name="email"
                        label="Mail"
                        hasFeedback={form.isFieldValidating("email")}
                        rules={[
                            {
                                required: true,
                                message: "Mail à saisir"
                            },
                            {
                                type: 'email',
                                message: "Mail invalide"
                            }
                        ]}
                    >
                        <Input
                            disabled={form.isFieldValidating("email")}
                            placeholder="Saisir le mail de l'utilisateur"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name="rolesId"
                        label="Taux autorisés"
                        hasFeedback={form.isFieldValidating("rolesId")}
                        rules={[{required: true, message: "Taux à saisir"}]}
                    >
                        <RoleSelect
                            onChange={onChangeRoles}
                            roles={roles}
                            loading={fieldsDisplay.rolesId.loading}
                            disabled={form.isFieldValidating("rolesId")}
                            allowMultiple
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="authorityId"
                        label="Statut"
                        hasFeedback={form.isFieldValidating("authorityId")}
                        rules={[
                            {
                                required: true,
                                message: "Statut à sélectionner"
                            }
                        ]}
                    >
                        <AuthoritySelect
                            disabled={form.isFieldValidating("authorityId") || Session.user.id === user?.id}
                            onChange={onChangeAuthority}
                            authorities={authorities}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                        label="Temps de travail maximum par jour"
                        hasFeedback={form.isFieldValidating("maxDayWorkingDuration")}
                    >
                        <MaxDurationsForm />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item
                name="enabled"
                hasFeedback={form.isFieldValidating("enabled")}
                style={{width: "100%"}}
            >
                <Radio.Group style={{width: "100%"}}>
                    <Radio.Button
                        style={{width: "50%"}}
                        value={true}
                        disabled={form.isFieldValidating("enabled") || Session.user.id === user?.id}
                    >Actif</Radio.Button>
                    <Radio.Button
                        style={{width: "50%"}}
                        value={false}
                        disabled={form.isFieldValidating("enabled") || Session.user.id === user?.id}
                    >Inactif</Radio.Button>
                </Radio.Group>
            </Form.Item>
            <Form.Item>
                <Row justify="center" style={{marginTop: "1.5em"}}>
                    <Col span={12}>
                        <Button style={{width: "100%"}} type="primary"
                                onClick={form.submit}
                                disabled={formValidating || fieldsDisplay.rolesId.loading}
                                icon={<SaveOutlined/>}
                        >
                            {user ? "Sauvegarder" : "Ajouter"}
                        </Button>
                    </Col>
                </Row>
            </Form.Item>
        </Form>
    </Modal>
}

export default UserFormModal