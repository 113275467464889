import {useEffect, useState} from "react";
import {DatePicker, Segmented} from "antd";
import PICKER from "./RangePickerPickerEnum";
import StateInUrlDAO from "../../StateInUrlDAO";

const {RangePicker} = DatePicker;

const CustomRangePicker = ({
                               customOnChange,
                               presets,
                               pickers = [PICKER.DATE],
                               ...props
                           }) => {

    const [selectedPicker, setSelectedPicker] = useState(pickers[0])
    const [usedPreset, setUsedPreset] = useState(null)

    useEffect(() => {
        if (pickers.length > 1) {
            let url = new URL(window.location);
            setSelectedPicker(Object.values(pickers).find(picker => picker.picker === url.searchParams.get('period_picker')) || pickers[0])
        }
    }, [])

    useEffect(() => {
        if (pickers.length > 1) {
            StateInUrlDAO.setQueryParam('period_picker', selectedPicker.picker);
        }
    }, [selectedPicker])

    const onChange = (period) => {
        customOnChange(selectedPicker.onChange(period))
        StateInUrlDAO.removeQueryParam('period_preset')
        if (period && (period[0] || period[1])) {
            StateInUrlDAO.setQueryParam('period', period.map(value => value ? value.format('YYYY-MM-DD') : "").join(','))
        } else {
            StateInUrlDAO.removeQueryParam('period')
        }
    }

    useEffect(() => {
        if (usedPreset) {
            setUsedPreset(null)
            customOnChange(usedPreset.value)
            StateInUrlDAO.setQueryParam('period_preset', usedPreset.query_label)
            StateInUrlDAO.removeQueryParam('period')
        }
    }, [usedPreset])

    return (
        <RangePicker
            {...props}
            onChange={onChange}
            picker={selectedPicker.picker}
            renderExtraFooter={pickers.length > 1 ? () => <Segmented
                block
                onClick={e => {
                    setSelectedPicker(Object.values(pickers).find(picker => picker.label === e.target.innerText))
                    e.preventDefault()
                }}
                value={selectedPicker.label}
                options={Object.values(pickers).map(picker => picker.label)}
                style={{margin: "0.25em 0"}}
            /> : undefined}
            presets={presets?.map(preset => ({
                label: <div onClick={() => setUsedPreset(preset)}
                            style={{width: "100%", height: "100%"}}>{preset.label}</div>,
                value: preset.value
            }))}
        />
    );
}

export default CustomRangePicker;