import {Button, Card, Col, Divider, Popover, Row, Tag} from "antd";
import {
    DeleteOutlined,
    EditOutlined,
    EyeOutlined,
    LockOutlined,
    UnlockOutlined
} from "@ant-design/icons";
import ConfirmPopUp from "../../workTimes/ConfirmPopUp";
import Session from "../../../session/Session";

const UserComponent = ({user, deleteUser, setEnabled, openEditForm}) => {

    return (
        <Card
            bodyStyle={{padding: "0.65em 1em", color: (user.enabled ? undefined : "lightgray")}}
            style={{width: "100%", borderRadius: "0.5em", marginBottom: "0.5em"}}>
            <Row justify="space-between" align="middle">
                <Col span={5} style={{paddingRight: "0.5em"}}>
                    <div style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        width: "100%",
                        overflow: "hidden"
                    }}>{user.firstName} {user.lastName}</div>
                </Col>
                <Col span={2} style={{paddingRight: "0.5em"}}>
                    <div style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        width: "100%",
                        overflow: "hidden"
                    }}>{user.trigram}</div>
                </Col>
                <Col span={5} style={{paddingRight: "0.5em"}}>
                    <div style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        width: "100%",
                        overflow: "hidden"
                    }}>{user.email}</div>
                </Col>
                <Col span={3} style={{paddingRight: "0.5em"}}>
                    <div style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        width: "100%",
                        overflow: "hidden"
                    }}>{user.authority.name}</div>
                </Col>
                <Col span={5} style={{paddingRight: "0.5em"}}>
                    {user.roles.map(role => <Tag key={role.id}>{role.name}</Tag>)}
                </Col>
                <Col span={4}>
                    <Row justify="end">
                        <Col style={{marginRight: "0.5em"}}>
                            <Button
                                type={user.enabled ? undefined : "dashed"}
                                onClick={openEditForm}
                            >
                                    <EyeOutlined/>
                                    <Divider type="vertical"/>
                                    <EditOutlined/>
                            </Button>
                        </Col>
                        <Col style={{marginRight: "0.5em"}}>
                            {user.id === Session.user.id ?
                                <Popover content="Vous ne pouvez pas vous désactiver vous même" trigger="hover">
                                    <Button
                                        disabled
                                        type={user.enabled ? "primary" : "dashed"}
                                        icon={user.enabled ? <UnlockOutlined/> : <LockOutlined/>}
                                    />
                                </Popover>
                                :<ConfirmPopUp confirm={() => setEnabled(!user.enabled)}
                                           placement="topLeft"
                                           action={user.enabled ? "désactiver" : "activer"}
                                           element={" utilisateur"}
                            >
                                <Button
                                    type={user.enabled ? "primary" : "dashed"}
                                    icon={user.enabled ? <UnlockOutlined/> : <LockOutlined/>}
                                />
                            </ConfirmPopUp>}
                        </Col>
                        <Col>
                            {
                                user.deletable && user.id !== Session.user.id ?
                                    <ConfirmPopUp confirm={deleteUser}
                                                  placement="topLeft"
                                                  action="supprimer"
                                                  element=" utilisateur"
                                    >
                                        <Button type={user.enabled ? "primary" : "dashed"}
                                                danger
                                                icon={<DeleteOutlined/>}/>
                                    </ConfirmPopUp>
                                    :
                                    <Popover content={user.id === Session.user.id ? "Vous ne pouvez pas vous supprimer vous même" : "Cet utilisateur ne peut pas être supprimé car il est lié à des temps de travail"} trigger="hover">
                                        <Button type={user.enabled ? "primary" : "dashed"} danger disabled
                                                icon={<DeleteOutlined/>}/>
                                    </Popover>
                            }
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Card>
    );
}

export default UserComponent;