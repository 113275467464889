import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {ConfigProvider} from "antd";

import frFR from "antd/es/locale/fr_FR";

import dayjs from "dayjs";
import "dayjs/locale/fr"

dayjs.locale("fr")

ReactDOM.createRoot(document.getElementById("root")).render(
    <>
        <ConfigProvider locale={frFR}>
            <App />
        </ConfigProvider>
    </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
