 import {Button, Col, Form, Row, Skeleton} from 'antd';
import {SearchOutlined} from "@ant-design/icons";
import {useEffect, useState} from "react";
import useForm from "antd/es/form/hooks/useForm";
import dayjs from "dayjs";
import Session from "../../session/Session";
import ClientSelect from "../../utils/form/select/ClientSelect";
import UserSelect from "../../utils/form/select/UserSelect";
import {UserService} from "../../services/UserService";
import UserAuthority from "../../utils/UserAuthority";
import {ClientService} from "../../services/ClientService";
import WorkTimesArchivingButton from "./WorkTimesArchivingButton";
import CustomRangePicker from "../../utils/form/datePicker/CustomRangePicker";
import PRESETS from "../../utils/form/datePicker/RangePickerPresetEnum";
import StateInUrlDAO from "../../utils/StateInUrlDAO";

function getFilterValues() {
    let validPeriodInUrl = false;
    let periodPreset = null;
    let period = null;
    if (StateInUrlDAO.hasQueryParam('period_preset')) {
        let periodPresetQueryValue = StateInUrlDAO.getQueryParam('period_preset');
        periodPreset = Object.values(PRESETS).find(preset => preset.query_label === periodPresetQueryValue);
        if (periodPreset) {
            period = periodPreset.value
            validPeriodInUrl = true;
        }
    } else if (StateInUrlDAO.hasQueryParam('period')) {
        period = StateInUrlDAO.getQueryParam('period').split(',').map(value => {
            let date = dayjs(value);
            return date.isValid() ? date : null
        })
        if (period.length === 2 && period[0] && period[1]) {
            validPeriodInUrl = true;
        }
    }

    let filters = {
        users_id: Session.isAdministrateur() ? ["all"] : [Session.user.id],
        clients_id: ["all"],
        period: PRESETS.CURRENT_MONTH.value
    }

    if (validPeriodInUrl) {
        let users_id = [Session.user.id];
        if (!Session.isCollaborateur()) {
            let usersIdQueryParam = StateInUrlDAO.getQueryParam('users_id');
            if (usersIdQueryParam === "all") {
                users_id = ["all"];
            } else {
                users_id = usersIdQueryParam.split(",").map(id => parseInt(id));
                if (users_id.filter(id => isNaN(id)).length) {
                    users_id = [Session.user.id];
                }
            }

        }
        let clientsIdQueryParam = StateInUrlDAO.getQueryParam('clients_id');
        let clients_id;
        if (clientsIdQueryParam === "all") {
            clients_id = ["all"];
        } else {
            clients_id = clientsIdQueryParam.split(",").map(id => parseInt(id));
            if (clients_id.filter(id => isNaN(id)).length) {
                clients_id = ["all"];
            }
        }

        filters = {
            period: period,
            users_id: users_id,
            clients_id: clients_id
        }
    } else {
        periodPreset = PRESETS.CURRENT_MONTH
    }
    StateInUrlDAO.setQueryParams({
        ...filters,
        period: filters.period.map(date => date.format('YYYY-MM-DD')).join(',')
    })
    if (periodPreset) {
        StateInUrlDAO.setQueryParam('period_preset', periodPreset.query_label)
        StateInUrlDAO.removeQueryParam('period')
    }
    return filters;
}



const WorkTimesFilterComponent = ({setFilters, loadingWorktimes, setArchivedDate, archivedDate}) => {
    const [users, setUsers] = useState([]);
    const [clients, setClients] = useState([]);
    const [loadingUsers, setLoadingUsers] = useState(true);
    const [loadingClients, setLoadingClients] = useState(true);

    const [loading, setLoading] = useState(true);
    const [validating, setValidating] = useState(false)

    const [form] = useForm();

    const [endPeriod, setEndPeriod] = useState(dayjs().endOf('month'))

    const refreshMultiSelection = (selection, allElement, setter, allowEmpty = true) => {
        let newSelection = selection
        if (selection.length > 1) {
            newSelection = ["all"]
            let allIndex = selection.findIndex(v => v === "all")
            if ((allIndex !== -1 && allIndex !== selection.length - 1) || (allIndex === -1 && selection.length !== allElement.length - 1)) {
                newSelection = selection.filter(v => v !== "all")
            }
        } else if (selection.length === 0 && !allowEmpty) {
            newSelection = ["all"]
        }
        setter(newSelection)
    }

    useEffect(() => {
        setValidating(loadingWorktimes)
    }, [loadingWorktimes])

    useEffect(() => {
        UserService.gets({authority_ids: [UserAuthority.ASSOCIE.id, UserAuthority.COLLABORATEUR.id]}, (err, data) => {
            if (!err) {
                setUsers([{id: 'all', firstName: 'Tous les utilisateurs', lastName: '', authority: {name: 'Tous'}}, ...data]);
                setLoadingUsers(false);
            }
        })

        ClientService.gets((err, data) => {
            if (!err) {
                setClients([{id: 'all', name: 'Tous les clients', companyType: {name: 'Tous'}}, ...data]);
                setLoadingClients(false);
            }
        })
    }, [])

    const filterValuesToApi = (values) => {
        return {
            users: Session.isCollaborateur() ? [Session.user] : values.users_id?.length > 0 ? values.users_id.map(userId => users.find(user => user.id === userId)) : undefined,
            clients: values.clients_id?.length > 0 ? values.clients_id.map(clientId => clients.find(client => client.id === clientId)) : undefined,
            after: values.period[0],
            before: values.period[1]
        }
    }

    useEffect(() => {
        if (!loadingClients && !loadingUsers) {
            let filters = getFilterValues();
            setFilters(filterValuesToApi(filters));
            form.setFieldsValue(filters)
            setLoading(false);
        }
    }, [loadingClients, loadingUsers])

    const onFinish = (values) => {
        setValidating(true)
        setFilters(filterValuesToApi(values));
        setValidating(false)
    }

    const onChangePeriod = (period) => {
        form.setFieldValue("period", period)
        setEndPeriod(period[1])
    }

    const onChangeFilter = (values) => {
        StateInUrlDAO.setQueryParams({...values, period: null})
    }

    return (
        <div style={{width: "100%"}}>
            <Row justify="space-between" align="middle">
                <Col span={24}>
                    <Row justify="end" align="middle">
                        <Form
                            onValuesChange={(_, values) => onChangeFilter(values)}
                            style={{width: "100%"}}
                            name="workTimesFilter"
                            form={form}
                            onFinish={onFinish}
                            layout="inline"
                        >
                            <Col span={Session.isCollaborateur() ? 20 : 18}>
                                <Row>
                                    <Col span={8}>
                                        <Form.Item
                                            name="users_id"
                                            hasFeedback={validating}
                                            validateStatus={validating ? "validating" : null}
                                        >
                                            <UserSelect
                                                onChange={(selection) => {
                                                    refreshMultiSelection(selection, users, (newValue) => {
                                                        form.setFieldValue('users_id', newValue)
                                                        onChangeFilter(
                                                            {
                                                                ...form.getFieldsValue(),
                                                                users_id: newValue
                                                            }
                                                        )
                                                    }, false)
                                                }}
                                                allowMultiple={true}
                                                loading={loading}
                                                disabled={Session.isCollaborateur() || validating}
                                                users={users}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            name="clients_id"
                                            hasFeedback={validating}
                                            validateStatus={validating ? "validating" : null}
                                        >
                                            <ClientSelect
                                                onChange={(selection) => {
                                                    refreshMultiSelection(selection, clients, (newValue) => {
                                                        form.setFieldValue('clients_id', newValue)
                                                        onChangeFilter(
                                                            {
                                                                ...form.getFieldsValue(),
                                                                clients_id: newValue
                                                            }
                                                        )
                                                    }, false)
                                                }}
                                                disabled={validating}
                                                loading={loading}
                                                allowMultiple={true}
                                                clients={clients}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            name="period"
                                            hasFeedback={validating}
                                            validateStatus={validating ? "validating" : null}
                                        >
                                            {
                                                loading ?
                                                    <Skeleton.Input active block/> :
                                                    <CustomRangePicker
                                                        customOnChange={onChangePeriod}
                                                        presets={[PRESETS.CURRENT_MONTH, PRESETS.LAST_MONTH]}
                                                        allowClear={false}
                                                        disabled={validating}
                                                        style={{width: "100%"}}
                                                        format="D MMM YYYY"
                                                    />
                                            }
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={Session.isCollaborateur() ? 4 : 6}>
                                <Row gutter={8}>
                                    <Col span={Session.isCollaborateur() ? 24 : 16}>
                                        {
                                            loading ?
                                                <Skeleton.Button active block/> :
                                                <Button type="primary" htmlType="submit"
                                                        style={{width: "100%"}}
                                                        loading={validating}
                                                        icon={<SearchOutlined/>}>
                                                    Rechercher
                                                </Button>
                                        }
                                    </Col>
                                    {!Session.isCollaborateur() && <Col span={8}>
                                        {
                                            loading ?
                                                <Skeleton.Button active block/> :
                                                <WorkTimesArchivingButton
                                                    archivedDate={archivedDate}
                                                    setArchivedDate={setArchivedDate}
                                                    validating={validating}
                                                    endFilterDate={endPeriod}
                                                />
                                        }
                                    </Col>}
                                </Row>
                            </Col>
                        </Form>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default WorkTimesFilterComponent
