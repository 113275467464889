import {GeneratorRequest} from "./GeneratorRequest";

const nomApi = "tasks"

export const TaskRepository = {
    gets : GeneratorRequest.gets(nomApi, true),
    delete: GeneratorRequest.delete(nomApi, true),
    put: GeneratorRequest.put(nomApi, true),
    post: GeneratorRequest.post(nomApi, true),
}
