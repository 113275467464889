import {useEffect, useState} from "react";
import useForm from "antd/es/form/hooks/useForm";
import {Col, Form, Input, Row} from "antd";
import StateInUrlDAO from "../../../utils/StateInUrlDAO";

const ClientFiltreComponent = ({setFilters, loadingClients}) => {

    const [validating, setValidating] = useState(false)
    const [startedTimeoutId, setStartedTimeoutId] = useState(null)

    const [form] = useForm();

    useEffect(() => {
        if (StateInUrlDAO.hasQueryParam("search")) {
            let filter = {
                search: StateInUrlDAO.getQueryParam("search")
            };
            form.setFieldsValue(filter)
            setFilters(filter)
        }
        setValidating(loadingClients)
    }, [loadingClients])

    const onChangeSearch = (e) => {
        clearTimeout(startedTimeoutId)
        setStartedTimeoutId(setTimeout(() => {
            if (e?.target?.value === form.getFieldValue("search")) {
                let filter = {
                    search: e?.target?.value
                };
                StateInUrlDAO.setQueryParams({
                    search: filter.search.length > 0 ? filter.search : undefined
                })
                setFilters(filter)
            }
        }, 500))
    }

    return (
        <div style={{width: "100%"}}>
            <Row justify="space-between" align="middle">
                <Col span={24}>
                    <Row justify="end" align="middle">
                        <Form
                            style={{width: "100%"}}
                            name="clientFilter"
                            form={form}
                            layout="inline"
                        >
                            <Col span={24}>
                                <Form.Item
                                    style={{width: "100%", margin: 0}}
                                    name="search"
                                    hasFeedback={validating}
                                    validateStatus={validating ? "validating" : null}
                                >
                                    <Input
                                        placeholder="Recherche par nom ou identifiant"
                                        onChange={onChangeSearch}
                                    />
                                </Form.Item>
                            </Col>
                        </Form>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default ClientFiltreComponent;