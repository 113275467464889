import {Button, Col, Form, Input, Modal, Radio, Row, Skeleton} from "antd";
import {useEffect, useState} from "react";
import {CompanyTypeService} from "../../../services/CompanyTypeService";
import CompanyTypeSelect from "../../../utils/form/select/enums/CompanyTypeSelect";
import {TaskService} from "../../../services/TaskService";
import TaskSelect from "../../../utils/form/select/enums/TaskSelect";
import MissionLettersFormList from "./MissionLettersFormList";
import {ClientService} from "../../../services/ClientService";
import dayjs from "dayjs";
import {SaveOutlined} from "@ant-design/icons";

const getAllKeys = (obj) => {
    let res = [];
    if (Array.isArray(obj)) {
        obj.forEach((e, index) => {

            getAllKeys(e).forEach(t => {
                res.push([index, ...t])
            })
        })
    } else if (!!obj && typeof obj === "object" && !obj.$d) {
        Object.keys(obj).forEach(key => {
            if (!obj[key]) {
            } else if (typeof obj[key] !== "object") {
                res.push([key])
            } else {
                let allKeys = getAllKeys(obj[key]);
                if (allKeys.length === 0) {
                    allKeys = [[]]
                }
                allKeys.forEach(t => {
                    res.push([key, ...t])
                })
            }
        })
    }
    return res;
}

const clientToFormData = (client) => {
    if (!client) {
        return null
    }
    return {
        name: client.name,
        identifier: client.identifier,
        companyTypeId: client.companyType.id,
        tasksId: client.tasks.map(task => task.id),
        actif: client.actif,
        missionLetters: client.missionLetters?.map(missionLetter => ({
            id: missionLetter.id,
            signature: dayjs(missionLetter.signature),
            billedHours: missionLetter.billedDuration ? missionLetter.billedDuration / 60 : null,
            billedPrice: missionLetter.billedPrice ? missionLetter.billedPrice / 100 : null,
            deletable: missionLetter.deletable,
            fiscalYears: missionLetter.fiscalYears?.map(fiscalYear => ({
                id: fiscalYear.id,
                dates: [dayjs(fiscalYear.start), dayjs(fiscalYear.end)],
                actif: fiscalYear.actif,
                deletable: fiscalYear.deletable
            }))
        }))
    }
}

const ClientFormModal = ({client, onCancel, clientSaved}) => {
    const [form] = Form.useForm();

    const [companyTypes, setCompanyTypes] = useState([])
    const [tasks, setTasks] = useState([])

    const [formValidating, setFormValidating] = useState(false)

    const [fieldsDisplay, setFieldsDisplay] = useState({
        companyTypeId: {
            loading: true
        },
        tasksId: {
            loading: true
        }
    })

    const [initialValues, setInitialValues] = useState(
        clientToFormData(client) ||
        {
            tasksId: ["all"],
            actif: true
        }
    )

    const setLoading = (field, loading) => {
        setFieldsDisplay(current => ({
            ...current,
            [field]: {
                ...current[field],
                loading
            }
        }))
    }

    const refreshTasks = (newTasks, allTasks) => {
        if (newTasks.length > 1) {
            let newTasksValue = ["all"]
            if (newTasks[newTasks.length - 1] !== "all" && newTasks.length < allTasks.length - 1) {
                newTasksValue = newTasks.filter(v => v !== "all")
            }
            form.setFieldValue("tasksId", newTasksValue)
        }
    }

    useEffect(() => {
        CompanyTypeService.gets({}, (err, data) => {
            if (!err) {
                setCompanyTypes(data)
                setLoading("companyTypeId", false)
            }
        })

        TaskService.gets({is_billable: true}, (err, data) => {
            if (!err) {
                let newTasks = [{id: 'all', name: 'Toutes les tâches facturables', groupTask: {name: 'Toutes'}}, ...data];
                setTasks(newTasks);
                setLoading("tasksId", false)
                refreshTasks(initialValues.tasksId, newTasks)
            }
        })
    }, []);

    const onChangeTasks = (e) => {
        refreshTasks(e, tasks)
    }

    const onFinish = (values) => {
        form.setFields(getAllKeys(values).map(fieldName => ({
            name: fieldName,
            errors: null,
            validating: true
        })))
        setFormValidating(true)
        let body = {
            name: values.name,
            tasksId: values.tasksId[0] === "all" ? tasks.map(_task => _task.id).filter(id => id !== "all") : values.tasksId,
            actif: values.actif,
            companyTypeId: values.companyTypeId,
            identifier: values.identifier,
            missionLetters: values.missionLetters?.map(missionLetter => ({
                id: missionLetter.id,
                signature: missionLetter.signature.format("YYYY-MM-DD"),
                billedDuration: missionLetter.billedHours ? missionLetter.billedHours * 60 : null,
                billedPrice: missionLetter.billedPrice ? Math.round(missionLetter.billedPrice * 100) : null,
                fiscalYears: missionLetter.fiscalYears?.map(fiscalYear => ({
                    id: fiscalYear.id,
                    start: fiscalYear.dates[0].startOf("month").format("YYYY-MM-DD"),
                    end: fiscalYear.dates[1].endOf("month").format("YYYY-MM-DD"),
                    actif: fiscalYear.actif
                }))
            }))
        };
        let request = ClientService.post
        if (!!client) {
            request = ClientService.put
            body.id = client.id
        }
        request(body, (err, data) => {
            if (err) {
                let fields = getAllKeys(values).map(fieldName => ({
                    name: fieldName,
                    errors: null,
                    validating: false
                }));
                if (!!err.errors) {
                    Object.values(err.errors.fields
                        .map(fields => {
                            let lastFieldPath = fields.field[fields.field.length - 1];
                            if (lastFieldPath === "start" || lastFieldPath === "end") {
                                fields.field[fields.field.length - 1] = "dates"
                            }
                            return {
                                name: fields.field.map(field => isNaN(Number(field)) ? field : Number(field)),
                                errors: [fields.message]
                            }
                        })
                        .reduce((obj, fields) => {
                            let fieldStringiFy = JSON.stringify(fields.name);
                            return ({
                                ...obj,
                                [fieldStringiFy]: obj[fieldStringiFy] ? {
                                    ...obj[fieldStringiFy],
                                    errors: [...obj[fieldStringiFy].errors, ...fields.errors]
                                } : fields
                            })
                        }, {}))
                        .forEach(fieldsError => {
                            fields.push({
                                ...fieldsError,
                                validating: false
                            })
                        })
                }
                form.setFields(fields)
                setFormValidating(false)
            } else {
                clientSaved()
            }
        })
    }

    const onCancelAskConfirm = () => {
        if (form.isFieldsTouched()) {
            Modal.confirm({
                title: (client ? "Modification" : "Ajout")+ " en cours",
                content: "Voulez-vous sauvegarder les modifications ?",
                okText: "Oui",
                cancelText: "Non",
                onOk: () => onFinish(form.getFieldsValue()),
                onCancel: onCancel
            })
        } else {
            onCancel()
        }
    }

    return <Modal
        open
        onOk={formValidating ? null : onCancelAskConfirm}
        onCancel={formValidating ? null : onCancelAskConfirm}
        title={client ? "Modifier le client" : "Ajouter un client"}
        footer={null}
        width={750}
        centered
    >
        <Form
            layout="vertical"
            name="clientForm"
            form={form}
            onFinish={onFinish}
            initialValues={initialValues}
        >
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name="name"
                        label="Nom"
                        hasFeedback={form.isFieldValidating("name")}
                        rules={[{required: true, message: "Nom à saisir"}]}
                    >
                        <Input
                            disabled={form.isFieldValidating("name")}
                            placeholder="Saisir le nom du client"
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="identifier"
                        label="Identifiant"
                        hasFeedback={form.isFieldValidating("identifier")}
                        rules={[{required: true, message: "Identifiant à saisir"}]}
                    >
                        <Input
                            disabled={form.isFieldValidating("identifier")}
                            placeholder="Saisir l'indentifiant du client"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name="companyTypeId"
                        label="Type d'organisation"
                        hasFeedback={form.isFieldValidating("companyTypeId")}
                        rules={[{required: true, message: "Type d'organisation à saisir"}]}
                    >
                        <CompanyTypeSelect
                            value={["all"]}
                            companyTypes={companyTypes}
                            loading={fieldsDisplay.companyTypeId.loading}
                            disabled={form.isFieldValidating("companyTypeId")}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="tasksId"
                        label="Tâches autorisées"
                        hasFeedback={form.isFieldValidating("tasksId")}
                        rules={[{required: true, message: "Tâches à saisir"}]}
                    >
                        <TaskSelect
                            onChange={onChangeTasks}
                            tasks={tasks}
                            loading={fieldsDisplay.tasksId.loading}
                            disabled={form.isFieldValidating("tasksId")}
                            allowMultiple
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item
                name="actif"
                hasFeedback={form.isFieldValidating("actif")}
                style={{width: "100%"}}
            >
                <Radio.Group style={{width: "100%"}}>
                    <Radio.Button
                        style={{width: "50%"}}
                        value={true}
                        disabled={form.isFieldValidating("actif")}
                    >Actif</Radio.Button>
                    <Radio.Button
                        style={{width: "50%"}}
                        value={false}
                        disabled={form.isFieldValidating("actif")}
                    >Inactif</Radio.Button>
                </Radio.Group>
            </Form.Item>
            <MissionLettersFormList
                formValidating={formValidating}
            />
            <Form.Item>
                <Row justify="center" style={{marginTop: "1.5em"}}>
                    <Col span={12}>
                        <Button style={{width: "100%"}} type="primary"
                                onClick={form.submit}
                                disabled={formValidating || fieldsDisplay.tasksId.loading || fieldsDisplay.companyTypeId.loading}
                                icon={<SaveOutlined/>}
                        >
                            {client ? "Sauvegarder" : "Ajouter"}
                        </Button>
                    </Col>
                </Row>
            </Form.Item>
        </Form>
    </Modal>
}

export default ClientFormModal