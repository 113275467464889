import {List} from "antd";
import {useEffect, useState} from "react";
import {WorkTimeService} from "../../services/WorkTimeService";
import WTCalandarWeek from "./WTCalandarWeek";
import WTListModal from "./WTListModal";
import StateInUrlDAO from "../StateInUrlDAO";
import dayjs from "dayjs";

const WTCalandar = ({filters, showWeekNumber, archivedDate, setLoadingWorkTimes}) => {

    const [worktimesGroupByWeek, setWorktimesGroupByWeek] = useState({})
    const [loading, setLoading] = useState(true)
    const [workTimeModalDate, setWorkTimeModalDate] = useState(null)

    useEffect(() => {
        setLoadingWorkTimes(loading)
    }, [loading]);

    useEffect(() => {
        if(StateInUrlDAO.getQueryParam('calandar_modal_date')) {
            let date = dayjs(StateInUrlDAO.getQueryParam('calandar_modal_date'), 'YYYY-MM-DD', true)
            if (date.isValid()) {
                setWorkTimeModalDate(date)
            }
        }
    }, []);

    useEffect(() => {
        if (workTimeModalDate === null) {
            StateInUrlDAO.removeQueryParam('calandar_modal_date')
        } else {
            StateInUrlDAO.setQueryParam('calandar_modal_date', workTimeModalDate.format("YYYY-MM-DD"))
        }
    }, [workTimeModalDate])

    useEffect(() => {
        if (filters?.after && filters?.before) {
            setWorktimesGroupByWeek(WorkTimeService.getsGroupByWeekAndDaySkeleton(filters.after, filters.before))
            setLoading(true)
            WorkTimeService.getsGroupByWeekAndDay({
                after: filters.after.format("YYYY-MM-DD"),
                before: filters.before.format("YYYY-MM-DD"),
                users_id: filters.users[0].id === "all" ? undefined : filters.users.map(user => user.id).join(","),
                clients_id: filters.clients[0].id === "all" ? undefined : filters.clients.map(client => client.id).join(","),
                reduce: true
            }, (err, data) => {
                if (!err) {
                    setWorktimesGroupByWeek(data)
                }
                setLoading(false)
            })
        }
    }, [filters])

    const setEditedWorkTimes = (date, editedWorkTimes) => {
        if (!loading && (date.isSame(filters.after) || date.isAfter(filters.after)) && date.isBefore(filters.before)) {
            let formatedDate = date.startOf("week").format("YYYY-MM-DD");
            let weekday = date.weekday();
            setWorktimesGroupByWeek({
                ...worktimesGroupByWeek,
                [formatedDate]: {
                    ...worktimesGroupByWeek[formatedDate],
                    days: {
                        ...worktimesGroupByWeek[formatedDate].days,
                        [weekday]: {
                            ...worktimesGroupByWeek[formatedDate].days[weekday],
                            workTimes: editedWorkTimes
                        }
                    }
                }
            })
        }
    }

    let dataSource = Object.values(worktimesGroupByWeek);
    return <>
        <List
            className="work-time-calandar"
            split={false}
            itemLayout="vertical"
            dataSource={dataSource}
            pagination={dataSource.length > 6 && {
                pageSize: 6,
            }}
            renderItem={({startOfWeek, days}) => {
                return <List.Item key={startOfWeek.format("YYYY-MM-DD")} style={{padding: (showWeekNumber ? 0 : undefined)}}>
                    <WTCalandarWeek
                        archivedDate={archivedDate}
                        showWeekNumber={showWeekNumber}
                        startOfWeek={startOfWeek}
                        workTimesByDays={days}
                        filters={filters}
                        setWorkTimeModalDate={setWorkTimeModalDate}
                    />
                </List.Item>
            }}
        />
        {workTimeModalDate && filters && <WTListModal
            archivedDate={archivedDate}
            close={() => setWorkTimeModalDate(null)}
            filters={filters}
            date={workTimeModalDate}
            editedWorkTimes={setEditedWorkTimes}
        />}
    </>

}

export default WTCalandar;