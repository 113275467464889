const UserAuthority = {
    ADMINISTRATEUR: {
        id: 1,
        name: "Administrateur"
    },
    ASSOCIE: {
        id: 2,
        name: "Associé"
    },
    COLLABORATEUR: {
        id: 3,
        name: "Collaborateur"
    }
}
export default UserAuthority;
