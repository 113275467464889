import {DatePicker} from "antd";

const CustomDatePicker = ({
                              customOnChange,
                              disabled,
                              value,
                              placeholder = "Saisir une date",
                              onChange,
                              disabledDate,
                              format = "DD/MM/YYYY"
                          }) => {

    const _onChange = (value) => {
        if (customOnChange) {
            customOnChange(value)
        }
        onChange(value);
    }

    return <DatePicker
        disabled={disabled}
        onChange={_onChange}
        value={value}
        disabledDate={disabledDate}
        style={{width: "100%"}}
        placeholder={placeholder}
        format={format}
    />;
}

export default CustomDatePicker;
