import {Button, Col, Row, Typography} from "antd";
import {useEffect, useState} from "react";
import {TaskService} from "../../../services/TaskService";
import GroupTasksComponent from "./GroupTasksComponent";
import {PlusOutlined} from "@ant-design/icons";
import {GroupTasksService} from "../../../services/GroupTasksService";

const TasksPageComponent = () => {

    const [tasksByGroup, setTasksByGroup] = useState([]);

    useEffect(() => {
        GroupTasksService.gets((err, groupTasks) => {
            if (!err) {
                TaskService.gets({}, (err, data) => {
                    if (!err) {
                        setTasksByGroup(Object.values(data.reduce((acc, task) => ({
                            ...acc,
                            [task.groupTask.id]: {
                                ...acc[task.groupTask.id],
                                tasks: [
                                    ...(acc[task.groupTask.id]?.tasks || []),
                                    task
                                ]
                            }
                        }), groupTasks.reduce((acc, groupTask) => ({
                            ...acc,
                            [groupTask.id]: {
                                ...groupTask,
                                tasks: []
                            }
                        }), {}))));
                    }
                })
            }
        })
    }, []);

    const add = () => {
        setTasksByGroup([
            {
                id: Math.random(),
                newEntity: true,
                tasks: []
            },
            ...tasksByGroup
        ])
    }

    return (<div className="content">
        <Row justify="center">
            <Col span={24} style={{maxWidth: "750px"}}>
                <Typography.Title level={1} style={{marginBottom: "20px"}}>Tâches</Typography.Title>
                <Row justify="space-between"
                     style={{
                         backgroundColor: "white",
                         padding: "1.75em",
                         borderRadius: "0.5em",
                         border: "1px solid #f0f0f0"
                     }}>
                    <Col span={24}>
                        <Button
                            size="large"
                            block
                            onClick={add}
                            icon={<PlusOutlined/>}
                            style={{marginBottom: "0.5em"}}
                        >
                            Ajouter un type de tâche
                        </Button>
                        {tasksByGroup.map(group => (<GroupTasksComponent
                            key={group.id}
                            groupTask={group}
                            saved={(newGroupTask, oldId) => setTasksByGroup(tasksByGroup.map(group => group.id === oldId ? newGroupTask : group))}
                            edited={(editedGroupTask) => setTasksByGroup(tasksByGroup.map(group => group.id === editedGroupTask.id ? editedGroupTask : group))}
                            removed={(id) => setTasksByGroup(tasksByGroup.filter(group => group.id !== id))}
                        />))}
                    </Col>
                </Row>
            </Col>
        </Row>
    </div>)
}

export default TasksPageComponent;