import {Button, Col, Row} from 'antd';
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {HourlyRatePriceService} from "../../../services/HourlyRatePriceService";
import ConfirmPopUp from "../../workTimes/ConfirmPopUp";
import {PriceFormater} from "../../../utils/PriceFormater";
import dayjs from "dayjs";

const HourlyRatePriceReadingComponent = ({hourlyRatePrice, edit, remove}) => {

    const removeHourlyRatePrice = () => {
        HourlyRatePriceService.delete(hourlyRatePrice.id, (err) => {
            if(!err) {
                remove()
            }
        })
    }

    return (
        <Row justify="space-between" align="middle">
            <Col style={{paddingRight: "0.5em", width: "30%"}}>{PriceFormater.priceToEuro(hourlyRatePrice.currentBillablePrice, false)}</Col>
            <Col style={{paddingRight: "0.5em", width: "40%"}}>{hourlyRatePrice.applicable === null ? "depuis la nuit des temps ;)" : "à partir du " + dayjs(hourlyRatePrice.applicable).format("D MMM YYYY")}</Col>
            <Col style={{width: "30%"}}>
                <Row justify="end">
                    <Col style={{marginRight: "0.5em"}}>
                        <Button
                            onClick={edit}
                            icon={<EditOutlined/>}
                        />
                    </Col>
                    <Col>
                        <ConfirmPopUp confirm={removeHourlyRatePrice}
                                      placement="topLeft"
                                      action="supprimer"
                                      element={" taux horaire"}
                        >
                            <Button type="primary"
                                    danger
                                    icon={<DeleteOutlined/>}
                                    disabled={hourlyRatePrice.applicable === null}
                            />
                        </ConfirmPopUp>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default HourlyRatePriceReadingComponent
