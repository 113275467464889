import {PriceFormater} from "../../../utils/PriceFormater";
import {Col, Row} from "antd";
import {useState} from "react";
import HourlyRatePriceModal from "./HourlyRatePriceModal";

const HourlyRatePriceCell = ({hourlyRate, rowLength, rowIndex, index}) => {

    const [showEdit, setShowEdit] = useState(false);

    return <>
        <Col
            className="cell"
            onClick={() => setShowEdit(true)}
            style={{
                padding: "0 1em",
                width: (Math.round(100 / rowLength) + "%"),
                minWidth: "15em",
                borderBottom: "1px solid #F0F0F0",
                borderTop: rowIndex === 0 ? "1px solid #F0F0F0" : "none",
                borderRight: index === rowLength - 1 ? "none" : "1px solid #F0F0F0",
                borderRadius: rowIndex === rowLength - 1 && index === hourlyRate.length - 1 ? "0 0 0.5em 0" : "0"
            }}
        >
            <Row style={{
                height: "4em",
            }} align="middle">
                <Col span={24}>
                    <Row justify="space-between" align="middle" style={{width: "100%"}} wrap={false}>
                        <Col className="cell-content" style={{width: "100%"}}>
                            {PriceFormater.priceToEuro(hourlyRate.currentBillablePrice, false)}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Col>
        {
            showEdit && <HourlyRatePriceModal hourlyRate={hourlyRate} setShowEdit={setShowEdit}/>
        }
    </>
}

export default HourlyRatePriceCell;