import {Col, InputNumber, Row, Form} from "antd";
import Cell from "../../../utils/table/Cell";
import HourlyRatePriceCell from "./HourlyRatePriceCell";

const HourlyRatesPriceTable = ({hourlyRatesTable, companyTypes, showAddRoleRow, showAddCompanyTypeColumn}) => {

    return (
        <Row style={{width: "100%"}}>
            <Col span={24}>
                {hourlyRatesTable.map((hourlyRatesRow, rowIndex) => (
                    <Row key={hourlyRatesRow[0].role.id} wrap={false}>
                        {hourlyRatesRow.map((hourlyRate, index) => (
                            <HourlyRatePriceCell
                                    key={hourlyRate.companyType.id}
                                    hourlyRate={hourlyRate}
                                    rowLength={hourlyRatesRow.length}
                                    rowIndex={rowIndex}
                                    index={index}
                                />
                        ))}
                    </Row>
                ))}
                {showAddRoleRow &&
                    <Row style={{width: "100%"}} wrap={false}>
                        {companyTypes.map((companyType, index) => (
                            <Cell
                                style={{
                                    backgroundColor: "white",
                                    padding: "0 1em",
                                    width: (Math.round(100 / companyTypes.length) + "%"),
                                    minWidth: "15em",
                                    borderTop: "none",
                                    borderRight: index === companyTypes.length - 1 ? "none" : "1px solid #F0F0F0",
                                    borderRadius: index === companyTypes.length - 1 ? "0 0 0.5em 0" : "0"
                                }}
                                key={companyType.id}
                                createMode={true}
                                editInput={<Form.Item
                                    name={companyType.id}
                                    style={{margin: 0}}
                                    rules={[{required: true, message: "Doit être saisie"}]}
                                ><InputNumber
                                    style={{width: "100%"}}
                                    placeholder="0,00"
                                    addonAfter="€"
                                    min="0"
                                    decimalSeparator=","
                                    precision={2}
                                    step={10}
                                /></Form.Item>}
                            />
                        ))}
                    </Row>}
            </Col>
        </Row>
    );
}

export default HourlyRatesPriceTable;