import {NotificationSender} from "../utils/NotificationSender";
import {GroupTasksRepository} from "../repositories/GroupTasksRepository";
import {TaskService} from "./TaskService";
import Session from "../session/Session";

export const GroupTasksService = {
    gets: (callback) => {
        Session.resetTimerBeforeLogout()
        GroupTasksRepository.gets({}, (err, data) => {
            if (err) {
                if (!err.errors) {
                    NotificationSender.error("Chargement", "Une erreur est survenue lors du chargement des types de tâches")
                }
                callback(err)
            } else {
                callback(false, data)
            }
        })
    },
    delete: (id, callback) => {
        Session.resetTimerBeforeLogout()
        GroupTasksRepository.delete(id, (err, data) => {
            if (err) {
                if (!err.errors) {
                    NotificationSender.error("Suppression", "Une erreur est survenue lors de la suppression du type de tâche")
                }
                callback(err)
            } else {
                TaskService.clearCache();
                NotificationSender.success("Suppression", "Le type de tâche à bien été supprimée")
                callback(false, data)
            }
        })
    },
    put: (data, callback) => {
        Session.resetTimerBeforeLogout()
        GroupTasksRepository.put(data.id, data, (err, data) => {
            if (err) {
                if (!err.errors) {
                    NotificationSender.error("Modification", "Une erreur est survenue lors de la modification du type de tâche")
                }
                callback(err)
            } else {
                TaskService.clearCache();
                NotificationSender.success("Modification", "Le type de tâche à bien été modifiée")
                callback(false, data)
            }
        })
    },
    post: (data, callback) => {
        Session.resetTimerBeforeLogout()
        GroupTasksRepository.post(data, (err, data) => {
            if (err) {
                if (!err.errors) {
                    NotificationSender.error("Création", "Une erreur est survenue lors de la création du type de tâche")
                }
                callback(err)
            } else {
                TaskService.clearCache();
                NotificationSender.success("Création", "Le type de tâche à bien été créée")
                callback(false, data)
            }
        })
    }
}
