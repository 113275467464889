import {Button, Col, Popover, Row} from 'antd';
import {useState} from "react";

const ConfirmPopUp = ({placement, action, element, confirm, children}) => {

    const [open, setOpen] = useState(false);

    return (
        <Popover placement={placement} trigger="click" open={open} onOpenChange={setOpen}
                 content={<div>
                     <Row style={{marginBottom: "1em"}}>Êtes vous sûr de
                         vouloir {action} ce{element} ?</Row>
                     <Row justify="end">
                         <Col span={5} style={{marginRight: "0.5em"}}>
                             <Button style={{width: "100%"}} type="primary"
                                     onClick={() => {
                                         setOpen(false)
                                         confirm()
                                     }}>Oui</Button>
                         </Col>
                         <Col span={5}>
                             <Button style={{width: "100%"}} type="primary" danger
                                     onClick={() => setOpen(false)}>Non</Button>
                         </Col>
                     </Row>
                 </div>}>
            {children}
        </Popover>
    );
};

export default ConfirmPopUp
