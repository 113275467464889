import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {Col, Form, Row, Skeleton} from "antd";
import {useEffect, useState} from "react";
import ClientSelect from "../../utils/form/select/ClientSelect";
import FiscalYearSelect from "../../utils/form/select/FiscalYearSelect";
import TaskSelect from "../../utils/form/select/enums/TaskSelect";
import UserSelect from "../../utils/form/select/UserSelect";
import StateInUrlDAO from "../../utils/StateInUrlDAO";

const getDragBarStyle = (isDragging) => isDragging ? ({
    backgroundColor: "gray",
    height: "0.25em",
    borderRadius: "0.5em"
}) : undefined;

const getListStyle = (draggingFromThisWith) => draggingFromThisWith ? ({
    backgroundColor: "#f0f0f0",
    borderRadius: "0.5em",
}) : undefined


const DashboardDnDFilters = ({
                                 clients,
                                 tasks,
                                 users,
                                 loading,
                                 validating,
                                 setFiltersSequence,
                                 onChangeFilter
                             }) => {

    const form = Form.useFormInstance();

    const [fiscalYears, setFiscalYears] = useState([])
    const [disableFiscalYears, setDisableFiscalYears] = useState(true)


    const [items, setItems] = useState([
        {
            key: 'clients_id',
            enum: 'CLIENT',
            content: ({
                          clients,
                          users,
                          loading,
                          validating,
                          fiscalYears,
                          disableFiscalYears,
                          customTasks,
                          allowEmpty
                      }) => <Row>
                <Col span={24}>
                    <Form.Item
                        style={{width: "100%", marginBottom: 0}}
                        name="clients_id"
                        hasFeedback={validating}
                        validateStatus={validating ? "validating" : null}
                    >
                        <ClientSelect
                            onChange={(selection) => refreshMultiSelection(
                                selection,
                                clients,
                                (newValue) => {
                                    form.setFieldValue('clients_id', newValue)
                                    onChangeClient(newValue, clients)
                                },
                                allowEmpty)
                            }
                            disabled={validating}
                            loading={loading}
                            clients={clients}
                            allowClear={allowEmpty}
                            allowMultiple
                        />
                    </Form.Item>
                </Col>
                {disableFiscalYears ? null : <Col span={24}>
                    <Form.Item
                        style={{width: "100%", marginTop: "0.25em", marginBottom: 0}}
                        name="fiscal_years_id"
                        hasFeedback={validating}
                        validateStatus={validating ? "validating" : null}
                    >
                        {
                            loading ?
                                <Skeleton.Input active block/> :
                                <FiscalYearSelect
                                    onChange={(selection) => refreshMultiSelection(selection, fiscalYears, (newValue) => form.setFieldValue('fiscal_years_id', newValue))}
                                    disabled={validating || disableFiscalYears}
                                    loading={loading}
                                    allowClear
                                    allowMultiple
                                    fiscalYears={fiscalYears}
                                />
                        }
                    </Form.Item>
                </Col>}
            </Row>
        },
        {
            key: 'tasks_id',
            enum: 'TASK',
            content: ({
                          clients,
                          users,
                          loading,
                          validating,
                          fiscalYears,
                          disableFiscalYears,
                          tasks,
                          allowEmpty
                      }) => <Form.Item
                style={{width: "100%", marginBottom: 0}}
                name="tasks_id"
                hasFeedback={validating}
                validateStatus={validating ? "validating" : null}
            >
                {
                    loading ?
                        <Skeleton.Input active block/> :
                        <TaskSelect
                            onChange={(selection) => refreshMultiSelection(selection, tasks, (newValue) => form.setFieldValue('tasks_id', newValue), allowEmpty)}
                            disabled={validating}
                            loading={loading}
                            tasks={tasks}
                            allowClear={allowEmpty}
                            allowMultiple
                        />
                }
            </Form.Item>
        },
        {
            key: 'users_id',
            enum: 'USER',
            content: ({
                          clients,
                          users,
                          loading,
                          validating,
                          fiscalYears,
                          disableFiscalYears,
                          customTasks,
                          allowEmpty
                      }) => <Form.Item
                style={{width: "100%", marginBottom: 0}}
                name="users_id"
                hasFeedback={validating}
                validateStatus={validating ? "validating" : null}
            >
                <UserSelect
                    onChange={(selection) => refreshMultiSelection(selection, users, (newValue) => form.setFieldValue('users_id', newValue), allowEmpty)}
                    loading={loading}
                    disabled={validating}
                    users={users}
                    allowClear={allowEmpty}
                    allowMultiple
                />
            </Form.Item>
        }
    ])

    useEffect(() => {
        let filters_sequence = StateInUrlDAO.getQueryParam('filters_sequence');
        if (filters_sequence) {
            setItems(filters_sequence.split(',').map(enumName => items.find(item => item.enum === enumName)))
        }
    }, []);

    useEffect(() => {
        let clientsId = StateInUrlDAO.getQueryParam('clients_id');
        if (clients.length && clientsId) {
            onChangeClient(clientsId.split(',').map(v => {
                let id = parseInt(v)
                return isNaN(id) ? v : id
            }), clients)
        }
    }, [clients]);

    useEffect(() => {
        setFiltersSequence(items.map(item => item.enum))
    }, [items])

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        if (!form.getFieldValue(result[0].key)?.length) {
            form.setFieldValue(result[0].key, ['all'])
        }
        return result;
    }

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        setItems(reorder(
            items,
            result.source.index,
            result.destination.index
        ))
    }

    const refreshMultiSelection = (selection, allElement, setter, allowEmpty = true) => {
        let newSelection = selection
        if (selection.length > 1) {
            newSelection = ["all"]
            let allIndex = selection.findIndex(v => v === "all")
            if ((allIndex !== -1 && allIndex !== selection.length - 1) || (allIndex === -1 && selection.length !== allElement.length - 1)) {
                newSelection = selection.filter(v => v !== "all")
            }
        } else if (selection.length === 0 && !allowEmpty) {
            newSelection = ["all"]
        }
        setter(newSelection)
    }

    const onChangeClient = (value, clients) => {
        let selectedFiscalYearsId = [];
        if (value.length === 1 && value[0] !== 'all') {
            let selectedClientId = value[0]
            setFiscalYears([{
                id: 'all',
                name: 'Tous les exercices fiscaux'
            }, ...clients.find(client => client.id === selectedClientId).missionLetters.map(missionLetters => missionLetters.fiscalYears).reduce((f1, f2) => f1.concat(f2), [])])
            setDisableFiscalYears(false)
            let urlFiscalYearsId = StateInUrlDAO.getQueryParam('fiscal_years_id');
            if (urlFiscalYearsId) {
                selectedFiscalYearsId = urlFiscalYearsId.split(',').map(v => {
                    let id = parseInt(v)
                    return isNaN(id) ? v : id
                })
            }
        } else {
            setFiscalYears([{id: 'all', name: 'Tous les exercices fiscaux'}])
            setDisableFiscalYears(true)
        }
        form.setFieldValue('fiscal_years_id', selectedFiscalYearsId)
        onChangeFilter({...form.getFieldsValue(), fiscal_years_id: selectedFiscalYearsId})
    }

    return <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
            {(provided, snapshot) => (
                <div style={{width: "100%"}}>
                    <Row
                        gutter={8}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.draggingFromThisWith)}
                        {...provided.droppableProps}
                    >
                        {items.map((item, index) => (
                            <Draggable key={item.key} draggableId={item.key}
                                       index={index}>
                                {(provided, snapshot) => (
                                    <Col span={8}
                                         className="dnd-filter-item"
                                         ref={provided.innerRef}
                                         {...provided.draggableProps}
                                         {...provided.dragHandleProps}
                                         style={{
                                             userSelect: 'none',
                                             padding: '0.25em 0.25em 0.25em 0.25em',
                                             borderRadius: "0.5em",
                                             ...provided.draggableProps.style
                                         }}
                                    >
                                        <Row style={{width: "100%"}}>
                                            <Col
                                                span={24}
                                                style={{
                                                    padding: "0 30% 0.25em 30%",
                                                }}
                                            >
                                                <div
                                                    className="dnd-filter-bar"
                                                    style={getDragBarStyle(snapshot.isDragging)}
                                                />
                                            </Col>
                                        </Row>
                                        <Row style={{width: "100%"}}>
                                            <Col span={24}>
                                                {item.content({
                                                    clients: clients,
                                                    users: users,
                                                    loading: loading,
                                                    validating: validating,
                                                    fiscalYears: fiscalYears,
                                                    disableFiscalYears: disableFiscalYears,
                                                    tasks: tasks,
                                                    allowEmpty: index !== 0
                                                })}
                                            </Col>
                                        </Row>
                                    </Col>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </Row>
                </div>
            )}
        </Droppable>
    </DragDropContext>
}

export default DashboardDnDFilters;