import {makeAutoObservable} from "mobx";
import UserAuthority from "../utils/UserAuthority";
import {AuthenticationService} from "../services/AuthenticationService";

class Session {
    user = null;
    token = null;
    infoBalise = null;
    timerBeforeLogout = null;
    constructor() {
        makeAutoObservable(this)
    }
    setToken(token) {
        this.token = token;
    }
    clear() {
        this.user = null;
        this.token = null;
    }
    isCollaborateur() {
        return this.isAuthority(UserAuthority.COLLABORATEUR);
    }

    isAssocie() {
        return this.isAuthority(UserAuthority.ASSOCIE);
    }
    isAuthority(authority) {
      return this.user.authority.id === authority.id;
    }
    getAuthority() {
        for (const authority of Object.values(UserAuthority)) {
            if(this.user.role.id === authority.id) {
                return authority
            }
        }
        return null;
    }

    setUser(user) {
        this.user = user;
    }

    isAdministrateur() {
        return this.isAuthority(UserAuthority.ADMINISTRATEUR);
    }

    setInfoBalise(infoBalise) {
        this.infoBalise = infoBalise;
    }

    getInfoBalise() {
        return this.infoBalise;
    }

    resetTimerBeforeLogout() {
        if(this.timerBeforeLogout) {
            clearTimeout(this.timerBeforeLogout);
        }
        this.timerBeforeLogout = setTimeout(() => {
            AuthenticationService.logout();
        }, 1000 * 60 * 15);
    }
}

export default new Session()
