import {Card} from 'antd';
import {useState} from "react";
import FormMode from "../../utils/FormMode";
import WorkTimeReadingComponent from "./WorkTimeReadingComponent";
import WorkTimeForm from "../../utils/calandar/WorkTimeForm";

const WorkTimeComponent = ({mode, date, workTime, remove, addedWorkTime, editWorkTime, archivedDate, filters}) => {

    const [wt, setWt] = useState(workTime);
    const [md, setMd] = useState(mode)

    const edit = () => {
        setMd(FormMode.EDITING)
    }

    let content = <WorkTimeReadingComponent
        archivedDate={archivedDate}
        workTime={wt}
        remove={() => remove(workTime.id)}
        edit={edit}
    />

    if (md === FormMode.CREATING) {
        content = <WorkTimeForm
            filters={filters}
            date={date}
            wtSaved={newWt => {
                newWt.addingId = wt.id
                addedWorkTime(newWt)
            }}
            remove={() => remove(wt.id)}
        />
    } else if (md === FormMode.EDITING) {
        content = <WorkTimeForm
            filters={filters}
            date={date}
            workTimeToEdit={wt}
            wtSaved={wt => {
                setWt(wt)
                setMd(FormMode.READING)
                editWorkTime(wt)
            }}
            remove={() => setMd(FormMode.READING)}
        />
    }

    return (
        <Card
            bodyStyle={{padding: "0.65em 1em"}}
            style={{width: "100%", borderRadius: "0.5em", marginBottom: "0.5em"}}
        >
            {content}
        </Card>
    );
};

export default WorkTimeComponent
