import {useEffect, useState} from "react";
import {Button, Col, Form, Row, Skeleton} from "antd";
import useForm from "antd/es/form/hooks/useForm";
import {SearchOutlined} from "@ant-design/icons";
import dayjs from "dayjs";
import CustomRangePicker from "../../utils/form/datePicker/CustomRangePicker";
import PRESETS from "../../utils/form/datePicker/RangePickerPresetEnum";
import PICKERS from "../../utils/form/datePicker/RangePickerPickerEnum";
import DashboardDnDFilters from "./DashboardDnDFilters";
import DownloadResumeButton from "./DownloadResumeButton";
import StateInUrlDAO from "../../utils/StateInUrlDAO";
import ApiParamBuilder from "../../utils/ApiParamBuilder";

function setUrlFromFormValues(values) {
    let url = new URL(window.location);
    Object.keys(values).forEach(key => {
        if (key !== 'period') {
            if (values[key] && values[key].length > 0) {
                url.searchParams.set(key, values[key].map(value => value).join(','))
            } else {
                url.searchParams.delete(key)
            }
        }
    })
    url.searchParams.sort()
    window.history.replaceState({}, '', url);
}

const DashboardFilter = ({
                             setFilters,
                             loadingDashboard,
                             clients,
                             loadingClients,
                             loadingTasks,
                             tasks,
                             loadingUsers,
                             users
                         }) => {

    const [loading, setLoading] = useState(false);
    const [validating, setValidating] = useState(false)

    const [filtersSequence, setFiltersSequence] = useState(["CLIENT", "TASK", "USER"]);

    const [form] = useForm();

    useEffect(() => {
        setValidating(loadingDashboard)
    }, [loadingDashboard])

    useEffect(() => {
        let filters = {}
        if (StateInUrlDAO.hasQueryParam('filters_sequence')) {
            StateInUrlDAO.getUrl().searchParams.forEach((value, key) => {
                if (key === 'period') {
                    filters[key] = value.split(',').map(value => {
                        let date = dayjs(value);
                        return date.isValid() ? date : null
                    })
                } else if (key === 'period_preset') {
                    filters.period = Object.values(PRESETS).find(preset => preset.query_label === value)?.value;
                } else if (key === 'filters_sequence') {
                    setFiltersSequence(value.split(','))
                } else {
                    filters[key] = value.split(',').map(value => {
                        let id = parseInt(value)
                        return isNaN(id) ? value : id
                    })
                }

            })
        } else {
            filters = {
                clients_id: ['all'],
                tasks_id: ['all'],
                users_id: ['all'],
                period: [dayjs().startOf("month"), dayjs().endOf("month")]
            }
            StateInUrlDAO.setQueryParam('period_preset', PRESETS.CURRENT_MONTH.query_label)
        }
        onFinish(filters);
        onChangeForm(filters)
        form.setFieldsValue(filters)
    }, [])

    useEffect(() => {
        if (!loadingClients && !loadingUsers && !loadingTasks) {
            setLoading(false);
            setValidating(false);
        }
    }, [loadingClients, loadingUsers, loadingTasks])

    useEffect(() => {
        onChangeForm(form.getFieldsValue())
    }, [filtersSequence]);

    const onChangeForm = (values) => {
        values = {...values, filters_sequence: filtersSequence}
        setUrlFromFormValues(values);
    }

    const onFinish = (values) => {
        setValidating(true)
        setFilters({
            ...values,
            period: undefined,
            ...ApiParamBuilder.periodToQueryParam(values.period),
            filters_sequence: filtersSequence
        });
        setValidating(false)
    }

    return (
        <Row>
            <Col span={24} style={{
                backgroundColor: "white",
                padding: "1em",
                marginRight: "1em",
                marginBottom: "1em",
                borderRadius: "0.5em",
                border: "1px solid #f0f0f0"
            }}>
                <Form
                    style={{width: "100%"}}
                    name="dashboardFilter"
                    form={form}
                    onFinish={onFinish}
                    onValuesChange={(_, allValues) => onChangeForm(allValues)}
                    layout="inline"
                >
                    <Row gutter={8} style={{width: "100%"}}>
                        <Col span={19}>
                            <Row gutter={8}>
                                <Col span={18}>
                                    <DashboardDnDFilters
                                        loading={loading}
                                        validating={validating}
                                        clients={clients}
                                        users={users}
                                        tasks={tasks}
                                        setFiltersSequence={setFiltersSequence}
                                        onChangeFilter={onChangeForm}
                                    />
                                </Col>
                                <Col span={6} style={{paddingTop: "0.75em"}}>
                                    <Form.Item
                                        style={{width: "100%", marginBottom: 0}}
                                        name="period"
                                        hasFeedback={validating}
                                        validateStatus={validating ? "validating" : null}
                                    >
                                        <CustomRangePicker
                                            customOnChange={period => {
                                                form.setFieldValue('period', period)
                                            }}
                                            presets={Object.values(PRESETS)}
                                            pickers={Object.values(PICKERS)}
                                            allowEmpty={[true, true]}
                                            disabled={validating}
                                            style={{width: "100%"}}
                                            format="D MMM YYYY"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={5} style={{marginTop: "0.75em"}}>
                            <Row gutter={8}>
                                <Col span={12}>
                                    {
                                        loading ?
                                            <Skeleton.Button active block/> :
                                            <Button type="primary" htmlType="submit"
                                                    style={{width: "100%"}}
                                                    loading={validating}
                                                    icon={<SearchOutlined/>}>
                                                Rechercher
                                            </Button>
                                    }
                                </Col>
                                <Col span={12}>
                                    <DownloadResumeButton
                                        loading={loading}
                                        validating={validating}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>
    );

}

export default DashboardFilter;