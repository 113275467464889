import {Affix, Card, Col, Collapse, Empty, Row, Table} from "antd";
import {useEffect, useState} from "react";
import {MilliFormater} from "../../utils/MilliFormater";
import Loading from "../../utils/Loading";
import TotalResultCard from "./TotalResultCard";
import {PriceFormater} from "../../utils/PriceFormater";
import {WorkTimeService} from "../../services/WorkTimeService";
import ApiParamBuilder from "../../utils/ApiParamBuilder";
import UnShownList from "./UnShownList";

const DashboardTable = ({filters, users, tasks, loadingTasks, loadingUsers, clients, loadingClients}) => {

    const resultWidth = 23;

    let [datas, setDatas] = useState(null)
    let [formatedDatas, setFormatedDatas] = useState([])
    let [datasDisplay, setDatasDisplay] = useState({})
    let [showColumn, setShowColumn] = useState(null)
    const [loadingDatas, setLoadingDatas] = useState(true)
    const [loading, setLoading] = useState(true)
    const [billableSummary, setBillableSummary] = useState(null)
    const [billedSummary, setBilledSummary] = useState(null)
    const [columns, setColumns] = useState(null)
    const [unShownBilled, setUnShownBilled] = useState(null)
    const [unShownFiscalYears, setUnShownFiscalYears] = useState(null)

    useEffect(() => {
        setLoading(loadingClients || loadingTasks || loadingUsers || loadingDatas)
    }, [loadingDatas, loadingClients, loadingTasks, loadingUsers])

    const recursiveValueComputing = (type, values, key, data) => {
        let nbFeuille = 0
        showColumn[type] = true
        setShowColumn(showColumn)
        values.forEach(value => {
            let tmpKey = key + "_" + value.id
            let tmpData = {
                ...data,
                [type]: value.id
            }
            if (value.subSelection) {
                let number = recursiveValueComputing(value.subSelection.type, value.subSelection.values, tmpKey, tmpData);
                let tmp = number;
                Object.keys(datasDisplay).forEach(key => {
                    if (key.startsWith(tmpKey)) {
                        datasDisplay[key][type] = tmp
                        tmp = 0
                    }
                })
                setDatasDisplay(datasDisplay)
                nbFeuille = nbFeuille + number
            } else {
                tmpData.key = tmpKey
                tmpData.result = value
                formatedDatas.push(tmpData)
                setFormatedDatas(formatedDatas)
                nbFeuille = nbFeuille + 1
                datasDisplay[tmpKey] = {...(datasDisplay[tmpKey] || {}), [type]: 1}
                setDatasDisplay(datasDisplay)
            }
        })
        return nbFeuille
    }

    useEffect(() => {
        if (!!filters && Object.keys(filters).length > 0) {
            setLoadingDatas(true)
            WorkTimeService.dashboard({
                group_by_client: ApiParamBuilder.needToGroupBy(filters.clients_id),
                group_by_fiscal_year: ApiParamBuilder.needToGroupBy(filters.fiscal_years_id),
                group_by_task: ApiParamBuilder.needToGroupBy(filters.tasks_id),
                group_by_user: ApiParamBuilder.needToGroupBy(filters.users_id),
                clients_id: ApiParamBuilder.idsToQueryParam(filters.clients_id),
                fiscal_years_id: ApiParamBuilder.idsToQueryParam(filters.fiscal_years_id),
                tasks_id: ApiParamBuilder.idsToQueryParam(filters.tasks_id),
                users_id: ApiParamBuilder.idsToQueryParam(filters.users_id),
                after: filters.after,
                before: filters.before,
                filters_sequence: filters.filters_sequence?.join(","),
            }, (err, data) => {
                if (!err) {
                    setDatas(data.dashboard)
                    if (data.unShownFiscalYears !== null) {
                        setUnShownFiscalYears(data.unShownFiscalYears)
                        setUnShownBilled(data.unShownFiscalYears.reduce((acc, value) => ({
                            billedPrice: acc.billedPrice + value.billedPrice,
                            billedDuration: acc.billedDuration + value.billedDuration
                        }), {billedPrice: 0, billedDuration: 0}))
                    } else {
                        setUnShownBilled(null)
                        setUnShownFiscalYears(null)
                    }
                }

            })
        }
    }, [filters])

    useEffect(() => {
        if (!!showColumn && !!datasDisplay && !loading) {
            let filteredColumn = [
                {
                    filter: "CLIENT",
                    column: [
                        {
                            title: "Client",
                            dataIndex: "client",
                            width: (((100 - resultWidth) / (Object.values(showColumn).filter(value => value).length)) + '%'),
                            colSpan: showColumn.client ? 1 : 0,
                            render: (text, record) => {
                                if (showColumn.client) {
                                    return clients.find(e => e.id === text)?.name;
                                } else {
                                    return null
                                }
                            },
                            onCell: (record, rowIndex) => {
                                return {
                                    rowSpan: datasDisplay[record.key]?.client,
                                    colSpan: showColumn.client ? 1 : 0,
                                }
                            }
                        },
                        {
                            title: "Année fiscale",
                            dataIndex: "fiscalYear",
                            width: (((100 - resultWidth) / (Object.values(showColumn).filter(value => value).length)) + '%'),
                            colSpan: showColumn.fiscalYear ? 1 : 0,
                            render: (text, record) => {
                                if (showColumn.fiscalYear) {
                                    return MilliFormater.milliToDate(clients.find(e => e.id === record.client)?.missionLetters.reduce((ml1, ml2) => ml1.concat(ml2.fiscalYears), []).find(fy => fy.id === text)?.end, true, false);
                                } else {
                                    return null;
                                }
                            },
                            onCell: (record, rowIndex) => {
                                return {
                                    rowSpan: datasDisplay[record.key]?.fiscalYear,
                                    colSpan: showColumn.fiscalYear ? 1 : 0,
                                }
                            }
                        }
                    ]
                },
                {
                    filter: "TASK",
                    column: [{
                        title: "Tâche",

                        dataIndex: "task",
                        width: (((100 - resultWidth) / (Object.values(showColumn).filter(value => value).length)) + '%'),
                        colSpan: showColumn.task ? 1 : 0,
                        render: (text, record) => {
                            if (showColumn.task) {
                                return tasks.find(e => e.id === text)?.name;
                            } else {
                                return null;
                            }
                        },
                        onCell: (record, rowIndex) => {
                            return {
                                rowSpan: datasDisplay[record.key]?.task,
                                colSpan: showColumn.task ? 1 : 0,
                            }
                        }
                    }]

                },
                {
                    filter: "USER",
                    column: [{
                        title: "Collaborateur",
                        dataIndex: "user",
                        width: (((100 - resultWidth) / (Object.values(showColumn).filter(value => value).length)) + '%'),
                        colSpan: showColumn.user ? 1 : 0,
                        render: (text, record) => {
                            if (showColumn.user) {
                                let user = users.find(e => e.id === text);
                                return user?.firstName + " " + user?.lastName;
                            } else {
                                return null;
                            }
                        },
                        onCell: (record, rowIndex) => {
                            return {
                                rowSpan: datasDisplay[record.key]?.user,
                                colSpan: showColumn.user ? 1 : 0,
                            }
                        }
                    }]
                }];
            setColumns([...filters.filters_sequence.map(filter => filteredColumn.find(e => e.filter === filter).column).flat(),
                {
                    title: "Résultat",
                    dataIndex: "result",
                    width: (resultWidth + '%'),
                    align: "right",
                    render: text => (<Row style={{paddingLeft: "8px", paddingRight: "8px"}}>
                        <Col span={10} style={{textAlign: "end"}}>
                            {PriceFormater.priceToEuro(text.billablePrice)}
                        </Col>
                        <Col span={4} style={{paddingLeft: "0.5em", textAlign: "end", color: "lightgray"}}>
                            pour
                        </Col>
                        <Col span={10} style={{textAlign: "end"}}>
                            {MilliFormater.minToHoursMinutes(text.billableDuration)}
                        </Col>
                    </Row>)
                }
            ])
        }
    }, [showColumn, datasDisplay, loading, filters])

    useEffect(() => {
        if (!!datas) {
            if (!!datas.values && datas.values.length > 0) {
                datasDisplay = {}
                formatedDatas = []
                showColumn = {}
                recursiveValueComputing(datas.type, datas.values, '', {})
                setBillableSummary(datas.values
                    .reduce((acc, value) => (
                            {
                                billablePrice: (acc.billablePrice + value.billablePrice),
                                billableDuration: (acc.billableDuration + value.billableDuration)
                            }
                        ),
                        {billablePrice: 0, billableDuration: 0}
                    )
                )
                setBilledSummary(datas.values
                    .reduce((acc, value) => (
                            {
                                billedPrice: (acc.billedPrice + value.billedPrice),
                                billedDuration: (acc.billedDuration + value.billedDuration)
                            }
                        ),
                        {billedPrice: 0, billedDuration: 0}
                    )
                )
            } else {
                setBillableSummary({billablePrice: 0, billableDuration: 0})
                setBilledSummary({billedPrice: 0, billedDuration: 0})
                setFormatedDatas([])
                setDatasDisplay({})
                setShowColumn({})
            }
            setLoadingDatas(false)
        }
    }, [datas])

    return <>{loading ?
        <Loading/> :
        <Row>
            <Col span={24}>
                {formatedDatas.length > 0 || unShownBilled != null ?
                    <>
                        {formatedDatas.length > 0 && <Row>
                            <Col span={24}>
                                <Table
                                    columns={columns}
                                    dataSource={formatedDatas}
                                    pagination={false}
                                    bordered
                                />
                            </Col>
                        </Row>}
                        {unShownFiscalYears !== null && unShownFiscalYears.length > 0 && <Row style={{marginTop: "1em"}} justify="end">
                            <Col style={{width: "30%"}}>
                                <Collapse
                                    size="small"
                                    items={[
                                        {
                                            key: "1",
                                            label: "Années fiscales facturées n'ayant aucune saisie (" + unShownFiscalYears.length + ")",
                                            children: <UnShownList unShownFiscalYears={unShownFiscalYears}/>
                                        },
                                    ]}
                                />
                            </Col>
                        </Row>}
                        <Row style={{marginTop: "1em"}}>
                            <Col style={{
                                width: '7%',
                                marginRight: 0,
                                marginLeft: "auto",
                                height: "100%",
                            }}>
                                <Affix offsetBottom={27} style={{width: "100%", height: "100%"}}>
                                    <Card style={{
                                        height: "10em",
                                        borderRadius: "8px 0 0 8px",
                                        borderRightColor: "white"
                                    }}>
                                        <Row>
                                            <Col span={24} style={{textAlign: "end", color: "lightgray"}}>
                                                Facturable
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24} style={{textAlign: "end", color: "lightgray"}}>
                                                Facturée
                                            </Col>
                                        </Row>
                                    </Card>
                                </Affix>
                            </Col>
                            <Col style={{
                                width: resultWidth + '.05%',
                                marginRight: 0
                            }}>
                                <Affix offsetBottom={27} style={{width: "100%"}}>
                                    <TotalResultCard
                                        unShownBilled={unShownBilled}
                                        {...billableSummary}
                                        {...billedSummary}
                                    />
                                </Affix>
                            </Col>
                        </Row>
                    </>
                    : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Aucune donnée disponible"/>}
            </Col>
        </Row>
    }</>

}

export default DashboardTable;