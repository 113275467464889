import {
    Button,
    Card,
    Col,
    Form,
    InputNumber,
    Row,
    Segmented,
} from "antd";
import CustomDatePicker from "../../../utils/form/datePicker/CustomDatePicker";
import {useState} from "react";
import {DeleteOutlined} from "@ant-design/icons";
import FiscalYearsFormList from "./FiscalYearsFormList";

const MissionLetterForm = ({index, remove, formValidating}) => {

    const form = Form.useFormInstance();

    const [missionLetterType, setMissionLetterType] = useState(!!form.getFieldValue(["missionLetters", index, "billedPrice"]) ? "Au forfait" : "À la mission");

    const onChangeBillableType = (value) => {
        setMissionLetterType(value)
        if(value === "À la mission") {
            form.setFieldValue(["missionLetters", index, "billedPrice"], undefined)
            form.setFieldValue(["missionLetters", index, "billedHours"], undefined)
        }
    }

    return <Card>
        <Row gutter={10} justify="space-between" align="middle">
            <Col span={23}>
                <Form.Item
                    name={[index, "signature"]}
                    label="Date de signature"
                    hasFeedback={form.isFieldValidating(["missionLetters", index, "signature"])}
                    rules={[{required: true, message: "Date de signature à saisir"}]}
                >
                    <CustomDatePicker
                        disabled={form.isFieldValidating(["missionLetters", index, "signature"])}
                    />
                </Form.Item>
                <Segmented
                    disabled={formValidating}
                    block
                    value={missionLetterType}
                    onChange={onChangeBillableType}
                    options={[
                        "Au forfait",
                        "À la mission"
                    ]}
                    style={{marginBottom: "1em"}}
                />
                {missionLetterType === "Au forfait" ? <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name={[index, "billedPrice"]}
                            label="Facturation par année fiscale"
                            hasFeedback={form.isFieldValidating(["missionLetters", index, "billedPrice"])}
                            rules={[{required: true, message: "Prix facturée doit être saisie"}]}
                        >
                            <InputNumber
                                disabled={form.isFieldValidating(["missionLetters", index, "billedPrice"])}
                                style={{width: "100%"}}
                                placeholder="0,00"
                                addonAfter="€"
                                min="0"
                                decimalSeparator=","
                                precision={2}
                                step={1000}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name={[index, "billedHours"]}
                            label="Heures facturées"
                            hasFeedback={form.isFieldValidating(["missionLetters", index, "billedHours"])}
                            rules={[{required: true, message: "Heures facturées doivent être saisie"}]}
                        >
                            <InputNumber
                                disabled={form.isFieldValidating(["missionLetters", index, "billedHours"])}
                                style={{width: "100%"}}
                                min="0"
                                placeholder="0"
                                addonAfter="h"
                                precision={0}
                            />
                        </Form.Item>
                    </Col>
                </Row> : null}
                <FiscalYearsFormList
                    indexMissionLetter={index}
                    formValidating={formValidating}
                />
            </Col>
            <Col span={1}>
                <Button
                    disabled={formValidating || form.getFieldValue(["missionLetters", index, "deletable"]) === false}
                    type="primary"
                    danger
                    icon={<DeleteOutlined/>}
                    onClick={remove}
                />
            </Col>
        </Row>
    </Card>
}

export default MissionLetterForm