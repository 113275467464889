import {Button, Col, Popover, Row} from "antd";
import {DeleteOutlined, EditOutlined, LockOutlined, UnlockOutlined} from "@ant-design/icons";
import ConfirmPopUp from "../../workTimes/ConfirmPopUp";
import FormMode from "../../../utils/FormMode";
import {TaskService} from "../../../services/TaskService";

const TaskReadingComponent = ({task, removed, edit, setMode}) => {

    const setActif = (actif) => {
        edit({...task, actif})
    }

    const deleteTask = () => {
        TaskService.delete(task.id, (err, data) => {
            if (!err) {
                removed()
            }
        })
    }

    return <Row justify="space-between" align="middle" gutter={6}>
        <Col span={19}>
            <div style={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                width: "100%",
                overflow: "hidden"
            }}>{task.name}</div>
        </Col>
        <Col span={5}>
            <Row justify="end" gutter={6}>
                <Col>
                    <Button
                        type={task.actif ? undefined : "dashed"}
                        onClick={() => setMode(FormMode.EDITING)}
                        icon={<EditOutlined/>}
                    />
                </Col>
                <Col>
                    <ConfirmPopUp
                        confirm={() => setActif(!task.actif)}
                        placement="topLeft"
                        action={task.actif ? "désactiver" : "activer"}
                        element={"te tâche"}
                    >
                        <Button type={task.actif ? "primary" : "dashed"}
                                icon={task.actif ? <UnlockOutlined/> : <LockOutlined/>}
                        />
                    </ConfirmPopUp>
                </Col>
                <Col>
                    {
                        task.deletable ?
                            <ConfirmPopUp confirm={() => deleteTask()}
                                          placement="topLeft"
                                          action="supprimer"
                                          element="te tâche"
                            >
                                <Button type={task.actif ? "primary" : "dashed"} danger
                                        icon={<DeleteOutlined/>}/>
                            </ConfirmPopUp>
                            :
                            <Popover
                                content="Cette tâche ne peut pas être supprimé car elle est liée à des temps de travail"
                                trigger="hover">
                                <Button type={task.actif ? "primary" : "dashed"} danger disabled
                                        icon={<DeleteOutlined/>}/>
                            </Popover>
                    }
                </Col>
            </Row>
        </Col>
    </Row>

}

export default TaskReadingComponent;