import WTDayCardItem from "./WTDayCardItem";
import {List} from "antd";
import {useEffect, useState} from "react";

const WTDayCardList = ({ filters, workTimes}) => {

    const [workTimesGroupBy, setWorkTimesGroupBy] = useState([]);

    useEffect(() => {
        if (filters.users.length > 1 || filters.users[0].id === "all") {
            setWorkTimesGroupBy(Object.values(workTimes.reduce(
                (acc, workTime) => {
                    return {
                        ...acc,
                        [workTime.user.id]: {
                            user: workTime.user,
                            workTimes: [
                                ...(acc[workTime.user.id]?.workTimes || []),
                                workTime
                            ]
                        }
                    }
                },
                {}))
                .map(({user, workTimes}) => ({
                        id: user.id,
                        label: user.firstName + " " + user.lastName,
                        duration: workTimes.reduce((acc, workTime) => acc + workTime.duration, 0),
                    })
                )
            );
        } else if (filters.clients.length > 1 || filters.clients[0].id === "all") {
            setWorkTimesGroupBy(Object.values(workTimes.reduce(
                (acc, workTime) => {
                    let key = workTime.task;
                    if (workTime.client) {
                        key = workTime.client;
                    }
                    return {
                        ...acc,
                        [key.id]: {
                            key: key,
                            workTimes: [
                                ...(acc[key.id]?.workTimes || []),
                                workTime
                            ]
                        }
                    }
                },
                {}))
                .map(({key, workTimes}) => ({
                        id: key.id,
                        label: key.name,
                        duration: workTimes.reduce((acc, workTime) => acc + workTime.duration, 0),
                    })
                )
            );
        } else {
            setWorkTimesGroupBy(Object.values(workTimes.reduce(
                (acc, workTime) => {
                    return {
                        ...acc,
                        [workTime.task.id]: {
                            task: workTime.task,
                            workTimes: [
                                ...(acc[workTime.task.id]?.workTimes || []),
                                workTime
                            ]
                        }
                    }
                },
                {}))
                .map(({task, workTimes}) => ({
                        id: task.id,
                        label: task.name,
                        duration: workTimes.reduce((acc, workTime) => acc + workTime.duration, 0),
                    })
                )
            );
        }
    }, [filters, workTimes]);

    return <List
        dataSource={workTimesGroupBy}
        renderItem={workTimeGroupBy => <WTDayCardItem key={workTimeGroupBy.id} item={workTimeGroupBy}/>}
    />
}

export default WTDayCardList;