import {useEffect, useState} from "react";
import Session from "../../session/Session";
import {Col, List, Row, Typography} from "antd";
import DayNotCompletedComponent from "./DayNotCompletedComponent";
import WTListModal from "../../utils/calandar/WTListModal";
import dayjs from "dayjs";
import StateInUrlDAO from "../../utils/StateInUrlDAO";

const DayNotCompletedList = ({archivedDate, days, loading, setDays, user}) => {

    const [dateWTModal, setDateWTModal] = useState(null)
    const [daysNotCompleted, setDaysNotCompleted] = useState([])
    const [allDays, setAllDays] = useState([])

    useEffect(() => {
        if (!loading && days.length > 0) {
            setAllDays(days)
        }
    }, [days, loading])

    useEffect(() => {
        setDaysNotCompleted(allDays.filter(day => day.maxDurations > day.sumDurations))
        setDays(allDays)
    }, [allDays])

    useEffect(() => {
        const queryParam = StateInUrlDAO.getQueryParam('completion_modal_date');
        if(daysNotCompleted.length > 0 && queryParam) {
            let date = dayjs(queryParam, 'YYYY-MM-DD', true)
            if (date.isValid()) {
                setDateWTModal(allDays.find(day => day.date === queryParam))
            } else {
                StateInUrlDAO.removeQueryParam('completion_modal_date')
            }
        }
    }, [daysNotCompleted]);

    useEffect(() => {
        if (!loading && dateWTModal !== null) {
            StateInUrlDAO.setQueryParam('completion_modal_date', dateWTModal.date)
        }
    }, [dateWTModal, loading]);

    const editedWorkTimes = (date, workTimes) => {
        let formatedDate = date.format("YYYY-MM-DD");
        setAllDays(allDays.map(day => {
            if (day.date === formatedDate) {
                if (workTimes.length === 0) {
                    return {
                        date: formatedDate,
                        sumDurations: 0,
                        maxDurations: day.maxDurations
                    }
                } else {
                    return {
                        date: formatedDate,
                        sumDurations: workTimes.filter(workTime => workTime.user.id === user.id).reduce((a, b) => a + b.duration, 0),
                        maxDurations: day.maxDurations
                    }
                }
            } else {
                return day
            }
        }))
    };

    return <>
        <Row>
            <Col span={24}>
                <Row>
                    <Col span={24}>
                        <Typography.Title level={3} style={{color: "lightgray"}}>Jours à compléter</Typography.Title>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <List
                            className="day-not-completed-list"
                            loading={loading}
                            grid={{gutter: 10, column: 2}}
                            pagination={daysNotCompleted.length > 6 && {
                                position: "bottom",
                                align: "center",
                                pageSize: 6
                            }}
                            split={false}
                            dataSource={daysNotCompleted}
                            renderItem={(dayNotCompleted) => <List.Item key={dayNotCompleted.date}>
                                <DayNotCompletedComponent
                                    dayNotCompleted={dayNotCompleted}
                                    openModal={() => setDateWTModal(dayNotCompleted)}
                                />
                            </List.Item>
                            }
                        />
                    </Col>
                </Row>
            </Col>
        </Row>

        {dateWTModal && <WTListModal
            archivedDate={archivedDate}
            close={() => {
                setDateWTModal(null)
                StateInUrlDAO.removeQueryParam('completion_modal_date')
            }}
            filters={{
                users: Session.isCollaborateur() ? [Session.user] : [user],
                clients: [{id: 'all', name: 'Tous les clients', companyType: {name: 'Tous'}}],
                after: dayjs(dateWTModal.date),
                before: dayjs(dateWTModal.date)
            }}
            date={dayjs(dateWTModal.date)}
            editedWorkTimes={editedWorkTimes}
        />}
    </>
}

export default DayNotCompletedList;