import AbstractSelect from "./AbstractSelect";
import {Col, Row} from "antd";

const ClientSelect = ({customOnChange, clients, allowClear, disabled, value, onChange, loading, computeDisabled = false, allowMultiple = false}) => {

    return <AbstractSelect
        onChange={customOnChange}
        elements={clients}
        getValue={(client) => client.id}
        filterOn={(search, option) => {
            if (option.value && option.value !== "all") {
                let client = clients.find(client => client.id === option.value)
                return client.name.toLowerCase().includes(search.toLowerCase()) || client.identifier.toLowerCase().includes(search.toLowerCase())
            }
            return false
        }}
        getPrint={(client) => client.id !== "all" ? <Row>
            <Col span={18}>
                <div style={{
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden"
                }}>{client.name}</div>
                </Col>
            <Col span={6}>
                <Row justify="end">
                    <div style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        color: "lightgray"
                    }}>{client.identifier}</div>
                </Row>
            </Col>
        </Row> : client.name}
        getGroup={(client) => client.companyType.name}
        elementName="Client"
        allowClear={allowClear}
        disabled={disabled}
        disabledOption={computeDisabled ? (client) => !client.actif || !client.missionLetters?.length || !client.missionLetters.find(m => m.fiscalYears?.length && m.fiscalYears.find(f => f.actif)) : (client) => false}
        antValue={value}
        antOnChange={onChange}
        loading={loading}
        mode={allowMultiple ? "multiple" : null}
    />;
}

export default ClientSelect;
