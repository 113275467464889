import {Button, Card, Col, Divider, Popover, Row, Skeleton} from "antd";
import {useEffect, useState} from "react";
import {MilliFormater} from "../../../utils/MilliFormater";
import {
    DeleteOutlined,
    EditOutlined,
    EyeOutlined,
    LockOutlined,
    UnlockOutlined
} from "@ant-design/icons";
import ConfirmPopUp from "../../workTimes/ConfirmPopUp";
import {PriceFormater} from "../../../utils/PriceFormater";

const getRecentFiscalYear = (missionLetters) => {
    return missionLetters.fiscalYears.reduce((f1, f2) => f1.end > f2.end ? f1 : f2)
}

const getRecentMissionLetter = (missionLetters) => {
    return missionLetters.reduce((m1, m2) => m1.signature > m2.signature ? m1 : m2)
}

const getStringBilling = (missionLetter) => {
    if (!!missionLetter.billedDuration) {
        return PriceFormater.priceToEuro(missionLetter.billedPrice) + " pour " + MilliFormater.minToHoursMinutes(missionLetter.billedDuration)
    } else {
        return "Facturé à la mission"
    }
}

const ClientComponent = ({client, deleteClient, setActifClient, openEditForm}) => {
    const [currentFiscalYear, setCurrentFiscalYear] = useState(<Skeleton.Input active block/>)
    const [currentBilling, setCurrentBilling] = useState(<Skeleton.Input active block/>)
    const [loadingButton, setLoadingButton] = useState(false);

    useEffect(() => {
        setLoadingButton(false)
    }, [client.actif])

    useEffect(() => {
        if(!!client.missionLetters && client.missionLetters.length > 0) {
            let missionLetterWithFiscalYear = client.missionLetters.filter(missionLetter => !!missionLetter.fiscalYears && missionLetter.fiscalYears.length > 0)
            if(missionLetterWithFiscalYear.length > 0) {
                let currentMissionLetter = getRecentMissionLetter(missionLetterWithFiscalYear)
                setCurrentBilling(getStringBilling(currentMissionLetter))
                let currentFiscalYear = getRecentFiscalYear(currentMissionLetter)
                setCurrentFiscalYear(MilliFormater.milliToDate(currentFiscalYear.end, true, false))
            } else {
                setCurrentBilling(getStringBilling(getRecentMissionLetter(client.missionLetters)))
                setCurrentFiscalYear("Pas d'année fiscal en cours")
            }
        } else {
            setCurrentBilling("Pas de mission en cours")
            setCurrentFiscalYear("Pas d'année fiscal en cours")
        }
    }, [client.missionLetters]);

    return (
        <Card bodyStyle={{padding: "0.65em 1em", color: (client.actif ? undefined : "lightgray")}} style={{width: "100%", borderRadius: "0.5em", marginBottom: "0.5em"}}>
            <Row justify="space-between" align="middle">
                <Col span={6} style={{paddingRight: "0.5em"}}>
                    <div style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        width: "100%",
                        overflow: "hidden"
                    }}>{client.name}</div>
                </Col>
                <Col span={2} style={{paddingRight: "0.5em"}}>
                    <div style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        width: "100%",
                        overflow: "hidden"
                    }}>{client.identifier}</div>
                </Col>
                <Col span={4} style={{paddingRight: "0.5em"}}>
                    <div style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        width: "100%",
                        overflow: "hidden"
                    }}>{client.companyType.name}</div>
                </Col>
                <Col span={4} style={{paddingRight: "0.5em"}}>
                    <div style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        width: "100%",
                        overflow: "hidden"
                    }}>{currentFiscalYear}</div>
                </Col>
                <Col span={4} style={{paddingRight: "0.5em"}}>
                    <div style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        width: "100%",
                        overflow: "hidden"
                    }}>{currentBilling}</div>
                </Col>
                <Col span={4}>
                    <Row justify="end">
                        <Col style={{marginRight: "0.5em"}}>
                            <Button
                                type={client.actif ? undefined : "dashed"}
                                loading={loadingButton}
                                disabled={loadingButton}
                                onClick={openEditForm}>
                                    <EyeOutlined/>
                                    <Divider type="vertical"/>
                                    <EditOutlined/>
                            </Button>
                        </Col>
                        <Col style={{marginRight: "0.5em"}}>
                            <ConfirmPopUp confirm={() => {
                                setActifClient(!client.actif)
                                setLoadingButton(true)
                            }}
                                          placement="topLeft"
                                          action={client.actif ? "désactiver" : "activer"}
                                          element={" client"}
                            >
                                <Button type={client.actif ? "primary" : "dashed"}
                                        loading={loadingButton}
                                        disabled={loadingButton}
                                        icon={client.actif ? <UnlockOutlined /> : <LockOutlined />}
                                />
                            </ConfirmPopUp>
                        </Col>
                        <Col>
                            {
                                client.deletable ?
                                    <ConfirmPopUp confirm={() => {
                                                        deleteClient()
                                        setLoadingButton(true)
                                                    }}
                                                  placement="topLeft"
                                                  action="supprimer"
                                                  element=" client"
                                    >
                                        <Button type={client.actif ? "primary" : "dashed"} danger
                                                loading={loadingButton}
                                                disabled={loadingButton}
                                                icon={<DeleteOutlined/>}/>
                                    </ConfirmPopUp>
                                    :
                                    <Popover content="Ce client ne peut pas être supprimé car il est lié à des temps de travail" trigger="hover">
                                        <Button type={client.actif ? "primary" : "dashed"} danger disabled
                                                icon={<DeleteOutlined/>}/>
                                    </Popover>
                            }
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Card>
    );
}

export default ClientComponent;