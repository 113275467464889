import {CompanyTypeRepository} from "../repositories/CompanyTypeRepository";
import {NotificationSender} from "../utils/NotificationSender";
import Session from "../session/Session";

const CompanyTypeServiceCache = {
    gets: {},
}

export const CompanyTypeService = {
    gets : (filters, callback) => {
        Session.resetTimerBeforeLogout()
        let stringifyFilters = JSON.stringify(filters);
        if (CompanyTypeServiceCache.gets[stringifyFilters]) {
            callback(false, JSON.parse(JSON.stringify(CompanyTypeServiceCache.gets[stringifyFilters])))
        } else {
            CompanyTypeRepository.gets(filters, (err, data) => {
                if (err) {
                    if (!err.errors) {
                        NotificationSender.error("Chargement", "Une erreur est survenue lors du chargement des types d'organisation")
                    }
                    callback(err)
                } else {
                    CompanyTypeServiceCache.gets[stringifyFilters] = JSON.parse(JSON.stringify(data))
                    callback(false, data)
                }
            })
        }
    },
    delete: (id, callback) => {
        Session.resetTimerBeforeLogout()
        CompanyTypeRepository.delete(id, (err) => {
            if (err) {
                let description = "Une erreur est survenue lors de la suppression du type d'organisation"
                if(!!err.errors) {
                    description = err.errors.globals[0].message
                }
                NotificationSender.error("Suppression", description)
                callback(true)
            } else {
                NotificationSender.success("Suppression", "Le type d'organisation à bien été supprimé")
                CompanyTypeService.clearCache();
                callback(false)
            }
        })
    },
    put: (companyType, callback) => {
        Session.resetTimerBeforeLogout()
        CompanyTypeRepository.put(companyType.id, companyType, (err, data) => {
            if (err) {
                if (!err.errors) {
                    NotificationSender.error("Sauvegarde", "Une erreur est survenue lors de la sauvegarde du type d'organisation")
                }
                callback(err)
            } else {
                NotificationSender.success("Modification", "Le nouveaux type d'organisation à bien été enregistré")
                CompanyTypeService.clearCache();
                callback(false, data)
            }
        })
    },
    clearCache: () => {
        CompanyTypeServiceCache.gets = {};
    }
}
