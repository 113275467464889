import {notification} from "antd";

const openNotificationWithIcon = (type, titre, description) => {
    notification[type]({
        message: titre,
        description: description,
    });
};

export const NotificationSender = {
    success : (titre, description) => {
      openNotificationWithIcon("success", titre, description)
    },
    error : (titre, description) => {
      openNotificationWithIcon("error", titre, description)
    }
}
