import {List} from "antd";
import WTDayCard from "./WTDayCard";
import WTDayCardLoading from "./WTDayCardLoading";

const WTCalandarWeekEnd = ({workTimesByDays, filters, archivedDate, setWorkTimeModalDate }) => {

    return <List
            className="work-time-calandar-week-end"
            style={{width: "100%"}}
            split={false}
            dataSource={workTimesByDays}
            renderItem={day => {
                return <List.Item key={day.date.format("YYYY-MM-DD")}>
                    {day.loading ? <WTDayCardLoading height="1em" width="100%"/> :
                    <WTDayCard
                        height={"1em"}
                        date={day.date}
                        workTimes={day.workTimes}
                        filters={filters}
                        disabled={day.disabled}
                        archivedDate={archivedDate}
                        showWorkTimeList={false}
                        setWorkTimeModalDate={setWorkTimeModalDate}
                    />}
                </List.Item>
            }}
        />
}

export default WTCalandarWeekEnd;