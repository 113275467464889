import {useEffect, useState} from "react";
import {Button, Col, Row} from "antd";
import TaskComponent from "./TaskComponent";
import {PlusOutlined} from "@ant-design/icons";
import GroupTasksReadingComponent from "./GroupTasksReadingComponent";
import FormMode from "../../../utils/FormMode";
import GroupTasksFormComponent from "./GroupTasksFormComponent";
import {GroupTasksService} from "../../../services/GroupTasksService";

const GroupTasksComponent = ({groupTask, removed, edited, saved}) => {

    const [tasks, setTasks] = useState([]);
    const [mode, setMode] = useState(groupTask.newEntity ? FormMode.EDITING : FormMode.READING);

    useEffect(() => {
        setTasks(groupTask.tasks)
    }, [groupTask.tasks]);

    const add = () => {
        setTasks([
            {
                id: Math.random(),
                newEntity: true,
                actif: true,
                groupTask: {
                    id: groupTask.id
                }
            },
            ...tasks
        ])
    }

    const edit = (newGroupTasks, callback) => {
        GroupTasksService.put(newGroupTasks, (err, data) => {
            if (!err) {
                edited({...groupTask, ...data})
                setMode(FormMode.READING)
            } else {
                callback(err)
            }
        })
    }

    let groupTaskContent = <GroupTasksReadingComponent
        groupTask={groupTask}
        removed={() => removed(groupTask.id)}
        setMode={setMode}
    />

    if (mode === FormMode.EDITING) {
        groupTaskContent = <GroupTasksFormComponent
            groupTasks={groupTask}
            edit={edit}
            saved={saved}
            removed={() => removed(groupTask.id)}
            setMode={setMode}
        />
    }

    return <Row>
        <Col span={24}>
            <Row>
                <Col span={24}>
                    {groupTaskContent}
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    {!groupTask.newEntity && <Button
                        block
                        onClick={add}
                        icon={<PlusOutlined/>}
                        style={{marginBottom: "0.5em"}}
                    >
                        Ajouter une tâche {groupTask.name}
                    </Button>}
                    {tasks.map(task => <TaskComponent
                        key={task.id}
                        task={task}
                        removed={(id) => setTasks(tasks.filter(task => task.id !== id))}
                        saved={(newTask, oldId) => setTasks(tasks.map(task => task.id === oldId ? newTask : task))}
                        edited={(editedTask) => setTasks(tasks.map(task => task.id === editedTask.id ? editedTask : task))}
                    />)}
                </Col>
            </Row>
        </Col>
    </Row>

}

export default GroupTasksComponent;