import {Form} from "antd";
import dayjs from "dayjs";
import DurationPicker from "../../../utils/form/timePicker/DurationPicker";

const MaxDurationDayItem = ({weekday}) => {

    const form = Form.useFormInstance();

    let fieldName = [weekday, "maxWorkDuration"];
    let hasFeedback = form.isFieldValidating(["workLimits", ...fieldName]);
    return <Form.Item
        style={{marginBottom: "0.5em"}}
        name={fieldName}
        hasFeedback={hasFeedback}
    >
        <DurationPicker
            placeholder={dayjs().day((weekday + 1)%7).format("ddd")}
            minuteStep={15}
            disabled={hasFeedback}
            allowZero={true}
        />
    </Form.Item>
}

export default MaxDurationDayItem;