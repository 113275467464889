import AbstractSelect from "./AbstractSelect";

const AbstractEnumSelect = ({disabledOption, enums, allowMultiple, disabled, onChange, loading, getGroup, enumName, allowClear, antValue, antOnChange}) => {

    return <AbstractSelect
        onChange={onChange}
        elements={enums}
        getValue={(enumElement) => enumElement.id}
        getPrint={(enumElement) => enumElement.name}
        getGroup={getGroup}
        elementName={enumName}
        allowClear={allowClear}
        mode={allowMultiple ? "multiple" : null}
        disabled={disabled}
        antValue={antValue}
        antOnChange={antOnChange}
        loading={loading}
        disabledOption={disabledOption}
    />;
}

export default AbstractEnumSelect;
