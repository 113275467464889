import {ClientRepository} from "../repositories/ClientRepository";
import {NotificationSender} from "../utils/NotificationSender";
import {WorkTimeRepository} from "../repositories/WorkTimeRepository";
import Session from "../session/Session";

const ClientServiceCache = {
    gets: null,
}

export const ClientService = {
    gets: (callback, forceRefreshCache = false) => {
        Session.resetTimerBeforeLogout()
        if (!forceRefreshCache && ClientServiceCache.gets) {
            callback(false, JSON.parse(JSON.stringify(ClientServiceCache.gets)))
        } else {
            ClientRepository.gets(null, (err, data) => {
                if (err) {
                    if (!err.errors) {
                        NotificationSender.error("Chargement", "Une erreur est survenue lors du chargement des clients")
                    }
                    callback(err)
                } else {
                    ClientServiceCache.gets = JSON.parse(JSON.stringify(data))
                    callback(false, data)
                }
            })
        }
    },
    delete : (id, callback) => {
        Session.resetTimerBeforeLogout()
        ClientRepository.delete(id, (err) => {
            if (err) {
                let description = "Une erreur est survenue lors de la suppression du client"
                if(!!err.errors) {
                    description = err.errors.globals[0].message
                }
                NotificationSender.error("Suppression", description)
                callback(true)
            } else {
                NotificationSender.success("Suppression", "Le client à bien été supprimé")
                callback(false)
            }
        })
    },
    put : (data, callback) => {
        Session.resetTimerBeforeLogout()
        ClientRepository.put(data.id, data, (err, data) => {
            if(err) {
                if(!err.errors) {
                    NotificationSender.error("Modification", "Une erreur est survenue lors de la modification du client")
                }
                callback(err)
            } else {
                NotificationSender.success("Modification", "Le client à bien été modifié")
                ClientServiceCache.gets[ClientServiceCache.gets.findIndex(client => client.id === data.id)] = data
                callback(false, data)
            }
        })
    },
    post : (client, callback) => {
        Session.resetTimerBeforeLogout()
        ClientRepository.post(client, (err, data) => {
            if(err) {
                if(!err.errors) {
                    NotificationSender.error("Ajout", "Une erreur est survenue lors de l'ajout du client")
                }
                callback(err)
            } else {
                NotificationSender.success("Ajout", "Le client à bien été ajouté")
                ClientServiceCache.gets = [data, ...ClientServiceCache.gets]
                callback(false, data)
            }
        })
    },
    clearCache: () => {
        ClientServiceCache.gets = null;
    }
}
