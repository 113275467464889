import {Card, Col, List, Row, Tag} from "antd";
import {MilliFormater} from "../../utils/MilliFormater";

const UnShownList = ({ unShownFiscalYears }) => {

    let fiscalYearsGroupByClient = Object.values(unShownFiscalYears.reduce((acc, unShownFiscalYear) => {
        const clientId = unShownFiscalYear.fiscalYear.client.id;
        if (!acc[clientId]) {
            acc[clientId] = {
                client: unShownFiscalYear.fiscalYear.client,
                fiscalYears: []
            }
        }
        acc[clientId].fiscalYears.push(unShownFiscalYear.fiscalYear)
        return acc
    }, {}))

    return <List
        pagination={fiscalYearsGroupByClient.length > 4 && {
            position: "bottom",
            align: "center",
            pageSize: 4
        }}
        dataSource={fiscalYearsGroupByClient}
        renderItem={(client) => (
            <Col span={24} key={client.client.id}>
                <Card bodyStyle={{padding: "0.65em 1em"}} style={{width: "100%", borderRadius: "0.5em", marginBottom: "0.5em"}}>
                    <Row justify="space-between" align="middle">
                        <Col span={10}>{client.client.name}</Col>
                        <Col span={14}>{client.fiscalYears.map(fy => <Tag key={fy.id}>{MilliFormater.milliToDate(fy.end, true, false)}</Tag>)}</Col>
                    </Row>
                </Card>
            </Col>
        )}
    />

}

export default UnShownList;