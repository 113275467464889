import {Col, Row, Typography} from "antd";
import {useEffect, useState} from "react";
import DashboardTable from "./DashboardTable";
import {ClientService} from "../../services/ClientService";
import {TaskService} from "../../services/TaskService";
import {UserService} from "../../services/UserService";
import UserAuthority from "../../utils/UserAuthority";
import DashboardFilter from "./DashboardFilter";

const DashboardComponent = () => {
    const [filters, setFilters] = useState({})
    const [loadingDashboard, setLoadingDashboard] = useState(true)

    const [clients, setClients] = useState([])
    const [loadingClients, setLoadingClients] = useState(true)
    const [tasks, setTasks] = useState([])
    const [loadingTasks, setLoadingTasks] = useState(true)
    const [users, setUsers] = useState([])
    const [loadingUsers, setLoadingUsers] = useState(true)

    useEffect(() => {
        ClientService.gets((err, data) => {
            if (!err) {
                setClients([{id: 'all', name: 'Tous les clients', companyType: {name: 'Tous'}}, ...data]);
                setLoadingClients(false)
            }
        })

        TaskService.gets({is_billable: true}, (err, data) => {
            if (!err) {
                setTasks([{id: 'all', name: 'Toutes les tâches facturables', groupTask: {name: 'Toutes'}}, ...data]);
                setLoadingTasks(false)
            }
        })

        UserService.gets({authority_ids: [UserAuthority.ASSOCIE.id, UserAuthority.COLLABORATEUR.id]}, (err, data) => {
            if (!err) {
                setUsers([{
                    id: 'all',
                    firstName: 'Tous les collaborateurs',
                    lastName: '',
                    authority: {name: 'Tous'}
                }, ...data]);
                setLoadingUsers(false)
            }
        })

    }, [])

    return <div className="content">
        <Row justify="center">
            <Col span={24} style={{maxWidth: "1500px"}}>
                <Typography.Title level={1} style={{marginBottom: "30px"}}>Tableau de bord</Typography.Title>
                <Row>
                    <Col span={24}>
                        <DashboardFilter
                            setFilters={setFilters}
                            loadingDashboard={loadingDashboard}
                            clients={clients}
                            loadingClients={loadingClients}
                            tasks={tasks}
                            loadingTasks={loadingTasks}
                            users={users}
                            loadingUsers={loadingUsers}
                        />
                    </Col>
                </Row>
                <Row justify="space-between">
                    <Col span={24}>
                        <Row style={{
                            backgroundColor: "white",
                            padding: "1.75em",
                            borderRadius: "0.5em",
                            border: "1px solid #f0f0f0"
                        }}>
                            <div style={{width: "100%"}}>
                                <DashboardTable
                                    setLoadingDashboard={setLoadingDashboard}
                                    filters={filters}
                                    clients={clients}
                                    loadingClients={loadingClients}
                                    tasks={tasks}
                                    loadingTasks={loadingTasks}
                                    users={users}
                                    loadingUsers={loadingUsers}
                                />
                            </div>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    </div>;
}

export default DashboardComponent;