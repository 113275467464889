import {Button, Col, Form, Popover, Row} from "antd";
import {DeleteOutlined, SaveOutlined} from "@ant-design/icons";
import {useState} from "react";
import {NotificationSender} from "../NotificationSender";

const Cell = ({
                  value,
                  style,
                  render,
                  getContent,
                  setContent,
                  updateApi,
                  editInput,
                  onDelete,
                  deleteApi,
                  deletable,
                  contentName,
                  getInitialValue = (value) => value,
                  formValueToContent = (value) => value,
                  createMode = false
              }) => {

    const [editing, setEditing] = useState(false);
    const [form] = Form.useForm();

    const update = (newContent) => {
        let oldContent = getContent(value);
        if (oldContent !== newContent) {
            setContent(newContent);
            updateApi(value, (err, responseBody) => {
                if (!err) {
                    setContent(getContent(responseBody));
                    setEditing(false);
                } else if (err.errors?.fields) {
                    NotificationSender.error("Sauvegarde", err.errors.fields[0].message);
                }
            });
        } else {
            setEditing(false);
        }
    }

    const remove = () => {
        deleteApi((err) => {
            if (!err) {
                onDelete();
            }
        })
    }

    let content = createMode ? editInput :
        <Row justify="space-between" align="middle" style={{width: "100%"}} wrap={false}>
            <Col className="cell-content" style={{width: (onDelete ? "85%" : "100%")}}>
                    {render(getContent(value))}
            </Col>
            {onDelete &&
                <Col style={{marginLeft: "0.25em", width: "15%"}} className="cell-delete-button">
                    {
                        (() => {
                            let button = <Button
                                type="primary"
                                danger
                                disabled={!deletable()}
                                icon={<DeleteOutlined/>}
                                onClick={remove}
                            />
                            if (!deletable()) {
                                button =
                                    <Popover
                                        content={"Ce" + contentName + " ne peut pas être supprimé car il est lié à des clients"}>
                                        {button}
                                    </Popover>
                            }
                            return button
                        })()
                    }
                </Col>}
        </Row>
    if (editing) {
        content = <Form
            form={form}
            initialValues={{value: getInitialValue(getContent(value))}}
            onFinish={(values) => update(formValueToContent(values.value))}
        >
            <Row justify="space-between" align="middle" style={{width: "100%"}} wrap={false}>
                <Col style={{width: "85%"}}>
                    <Form.Item
                        name="value"
                        style={{margin: 0}}
                        rules={[{required: true, message: "Doit être saisie"}]}
                    >
                        {editInput}
                    </Form.Item>
                </Col>
                <Col style={{width: "15%", marginLeft: "0.25em"}}>
                    <Button
                        onClick={() => {
                            form.validateFields()
                                .then(values => update(formValueToContent(values.value)))
                                .catch((e) => {})
                        }}
                        type="primary"
                        icon={<SaveOutlined/>}
                    />
                </Col>
            </Row>
        </Form>
    }
    return <Col
        className="cell"
        style={style}
        onClick={createMode ? undefined : () => setEditing(true)}
    >
        <Row style={{
            height: "4em",
        }} align="middle">
            <Col span={24}>
                {content}
            </Col>
        </Row>
    </Col>
}

export default Cell;