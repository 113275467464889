import {Col, Row, Typography} from "antd";
import {useEffect, useState} from "react";
import {UserService} from "../../../services/UserService";
import UserListComponent from "./UserListComponent";
import UserAuthority from "../../../utils/UserAuthority";

const UserPageComponent = () => {

    const [loadingUsers, setLoadingUsers] = useState(true);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        UserService.gets({authority_ids: [UserAuthority.ASSOCIE.id, UserAuthority.COLLABORATEUR.id]}, (err, data) => {
            if (!err) {
                setUsers(data);
            }
            setLoadingUsers(false)
        }, true)
    }, [])

    const deleteUser = (id) => {
        UserService.delete(id, (err) => {
            if (!err) {
                setUsers(users.filter(user => user.id !== id))
            }
        })
    }

    const setEnabledUser = (user, enabled) => {
        UserService.put({
            ...user,
            authorityId: user.authority.id,
            rolesId: user.roles.map(role => role.id),
            enabled
        }, (err, data) => {
            if (!err) {
                setUsers(users.map(u => u.id === data.id ? data : u))
            }
        })
    }

    const userSaved = (user, isNewUser) => {
        if (isNewUser) {
            setUsers([user, ...users])
        } else {
            setUsers(users.map(u => u.id === user.id ? user : u))
        }
    }

    return (<div className="content">
        <Row justify="center">
            <Col span={24} style={{maxWidth: "1500px"}}>
                <Typography.Title level={1} style={{marginBottom: "20px"}}>Utilisateurs</Typography.Title>
                <Row justify="space-between">
                    <Col span={24}>
                        <Row style={{
                            backgroundColor: "white",
                            padding: "1.75em",
                            borderRadius: "0.5em",
                            border: "1px solid #f0f0f0"
                        }}>
                            <div style={{width: "100%"}}>
                                <UserListComponent
                                    loading={loadingUsers}
                                    users={users}
                                    deleteUser={deleteUser}
                                    setEnabled={setEnabledUser}
                                    userSaved={userSaved}
                                />
                            </div>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    </div>)
}

export default UserPageComponent;
