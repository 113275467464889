import {Col, Divider, Flex, Progress, Row, Typography} from "antd";
import DayNotCompletedList from "./DayNotCompletedList";
import {useEffect, useState} from "react";
import {UserService} from "../../services/UserService";
import Session from "../../session/Session";
import dayjs from "dayjs";
import DayCompletionChart from "./DayCompletionChart";

const DaysNotCompletedComponent = ({archivedDate, loading, user}) => {

    const [days, setDays] = useState([]);
    const [internalLoading, setInternalLoading] = useState(true)

    useEffect(() => {
        if (!loading) {
            UserService.getDailyActivity({
                users_id: user.id,
                after: archivedDate ? archivedDate.add(1, "day").format("YYYY-MM-DD") : undefined,
                before: dayjs().subtract(1, "week").endOf("week").format("YYYY-MM-DD"),
            }, (err, data) => {
                if (!err) {
                    setDays(data)
                }
                setInternalLoading(false)
            })
        }
    }, [loading, archivedDate, user])

    return <Row justify="space-between">
        <Col span={18}>
            <DayNotCompletedList
                user={user}
                archivedDate={archivedDate}
                loading={internalLoading}
                days={days}
                setDays={setDays}
            />
        </Col>
        <Col span={6}>
            <DayCompletionChart days={days}/>
        </Col>
    </Row>
}

export default DaysNotCompletedComponent;