import {Button, Col, Form, Input, Row} from "antd";
import {
    CloseOutlined,
    SaveOutlined,
} from "@ant-design/icons";
import FormMode from "../../../utils/FormMode";
import {TaskService} from "../../../services/TaskService";

const TaskFormComponent = ({task, edit, saved, removed, setMode}) => {

    const [form] = Form.useForm();

    const onErrors = (err) => {
        if (err.errors) {
            err.errors.fields.forEach(field => {
                form.setFields([{name: field.field, errors: [field.message]}])
            })
        }
    }
    const onFinish = (newTask) => {
        if (task.newEntity) {
            TaskService.post({...task, ...newTask, groupTaskId: task.groupTask.id}, (err, data) => {
                if (!err) {
                    saved(data, task.id)
                } else {
                    onErrors(err)
                }
            })
        } else {
            if (newTask.name !== task.name) {
                edit({...task, ...newTask}, onErrors)
            } else {
                setMode(FormMode.READING)
            }
        }
    }

    return <Form
        form={form}
        initialValues={task.newEntity ? undefined : task}
        onFinish={onFinish}
    >
        <Row justify="space-between" align="middle" gutter={6}>
            <Col span={19}>
                <Form.Item
                    name="name"
                    style={{marginBottom: 0}}
                    rules={[{required: true, message: "Nom à saisir"}]}
                >
                    <Input placeholder="Nom"/>
                </Form.Item>
            </Col>
            <Col span={5}>
                <Row justify="end" gutter={6}>
                    <Col>
                        <Button
                            type="primary"
                            htmlType="submit"
                            icon={<SaveOutlined/>}
                        />
                    </Col>
                    <Col>
                        <Button
                            type="primary"
                            danger
                            onClick={task.newEntity ? () => removed() : () => setMode(FormMode.READING)}
                            icon={<CloseOutlined/>}/>
                    </Col>
                </Row>
            </Col>
        </Row>
    </Form>

}

export default TaskFormComponent;