import dayjs from "dayjs";

const quarterOfYear = require('dayjs/plugin/quarterOfYear');
dayjs.extend(quarterOfYear)
dayjs.locale("fr")

const PRESETS = {
    CURRENT_MONTH: {
        label: "Mois en cours",
        query_label: "current_month",
        value: [dayjs().startOf("month"), dayjs().endOf("month")]
    },
    LAST_MONTH: {
        label: "Mois précédent",
        query_label: "last_month",
        value: [dayjs().subtract(1, "month").startOf("month"), dayjs().subtract(1, "month").endOf("month")]
    },
    CURRENT_QUARTER: {
        label: "Trimestre en cours",
        query_label: "current_quarter",
        value: [dayjs().startOf("quarter"), dayjs().endOf("quarter")]
    },
    LAST_QUARTER: {
        label: "Trimestre précédent",
        query_label: "last_quarter",
        value: [dayjs().subtract(1, "quarter").startOf("quarter"), dayjs().subtract(1, "quarter").endOf("quarter")]
    },
    CURRENT_YEAR: {
        label: "Année en cours",
        query_label: "current_year",
        value: [dayjs().startOf("year"), dayjs().endOf("year")]
    },
    LAST_YEAR: {
        label: "Année précédente",
        query_label: "last_year",
        value: [dayjs().subtract(1, "year").startOf("year"), dayjs().subtract(1, "year").endOf("year")]
    }
}

export default PRESETS;