import {GeneratorRequest} from "./GeneratorRequest";

const nomApi = "clients"

export const ClientRepository = {
    gets : GeneratorRequest.gets(nomApi, true),
    delete : GeneratorRequest.delete(nomApi, true),
    put : GeneratorRequest.put(nomApi, true),
    post: GeneratorRequest.post(nomApi, true)
}
