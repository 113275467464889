import {Badge, Col, Row} from "antd";
import {MilliFormater} from "../MilliFormater";

const WTDayCardItem = ({ item }) => {

    return <Row style={{width: "100%"}}>
        <Col span={2}><Badge color={"green"}/></Col>
        <Col span={16} style={{
            color: "black",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap"
        }}>{item.label}</Col>
        <Col span={6} style={{
            color: "lightgray"
        }}>{MilliFormater.minSimpleHourDate(item.duration)}</Col>
    </Row>

}

export default WTDayCardItem;