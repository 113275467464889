import './App.css';
import {useEffect, useState} from "react";
import Cookie from "js-cookie";
import Session from "./session/Session";
import MenuComponent from "./component/menu/MenuComponent";
import {UserService} from "./services/UserService";
import Loading from "./utils/Loading";
import {PingRepository} from "./repositories/PingRepository";

const App = () => {
  const [checkCookie, setCheckCookie] = useState(false)
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (!checkCookie){
      setCheckCookie(true)
      let token = Cookie.get("token");
      Session.setToken(token);
      if (token) {
        UserService.get(-1, (err, res) => {
          if (err) {
            Cookie.remove("token")
          } else {
            Session.setUser(res)
          }
          setLoading(false)
        })
      } else {
        PingRepository.get(null, (err, res) => {})
        setLoading(false)
      }
    }
  }, [checkCookie])

  return (<div>
    {loading ? <Loading /> : <MenuComponent/>}
  </div>)
};

export default App;
