import {NotificationSender} from "../utils/NotificationSender";
import Session from "../session/Session";
import {ArchivingRepository} from "../repositories/ArchivingRepository";
import dayjs from "dayjs";

const ArchivingCache = {
    currentArchivingDate: undefined
}

export const ArchivingService = {
    post: (date, callback) => {
        Session.resetTimerBeforeLogout()
        ArchivingRepository.post({date: date}, (err, data) => {
            if (err) {
                if (!err.errors) {
                    NotificationSender.error("Sauvegarde", "Une erreur est survenue lors de la sauvegarde de la date d'archivage")
                }
                callback(err)
            } else {
                NotificationSender.success("Modification", "La date d'archivage à bien été enregistrée")
                ArchivingCache.currentArchivingDate = undefined
                callback(false, data)
            }
        })
    },
    getCurrentArchivingDate: (callback) => {
        Session.resetTimerBeforeLogout()
        if (ArchivingCache.currentArchivingDate !== undefined) {
            callback(false, ArchivingCache.currentArchivingDate)
            return
        }
        ArchivingRepository.gets({limit : 1}, (err, data) => {
            if (err) {
                if (!err.errors) {
                    NotificationSender.error("Chargement", "Une erreur est survenue lors du chargement de la date d'archivage")
                }
                ArchivingCache.currentArchivingDate = null
                callback(err)
            } else {
                let currentArchivingDate = data.length ? dayjs(data[0].date) : null;
                ArchivingCache.currentArchivingDate = currentArchivingDate
                callback(false, currentArchivingDate)
            }
        })
    }
}
