import AbstractEnumSelect from "../AbstractEnumSelect";

const TaskSelect = ({customOnChange, tasks, allowMultiple, disabled, value, onChange, loading, allowClear, computeDisabled = false}) => {

    return <AbstractEnumSelect
        onChange={customOnChange}
        enums={tasks}
        enumName="Tâche"
        getGroup={task => task.groupTask.name}
        allowMultiple={allowMultiple}
        allowClear={allowClear}
        disabled={disabled}
        antValue={value}
        antOnChange={onChange}
        loading={loading}
        disabledOption={computeDisabled ? (task) => !task.actif : (_) => false}
    />;
}

export default TaskSelect;
