import {BrowserRouter, Link, Route, Routes} from "react-router-dom";
import {observer} from "mobx-react";
import {Layout, Menu} from 'antd';
import '../../App.css';
import HomeComponent from "../home/HomeComponent";
import ErrorPathComponent from "../error/ErrorPathComponent";
import LoginComponent from "../authentication/login/LoginComponent";
import Session from "../../session/Session";
import WorkTimesComponent from "../workTimes/WorkTimesComponent";
import Avatar from "antd/es/avatar/avatar";
import {LogoutOutlined, UserOutlined} from "@ant-design/icons";
import {AuthenticationService} from "../../services/AuthenticationService";
import {useState} from "react";
import Environment from "../../utils/Environment";
import BaliseComponent from "../../utils/BaliseComponent";
import ResetPasswordComponent from "../authentication/reset_password/ResetPasswordComponent";
import DashboardComponent from "../dashboard/DashboardComponent";
import AdministrationMenuComponent from "../administration/AdministrationMenuComponent";

import TempoComptaFooter from "./TempoComptaFooter";
import ForgotPasswordComponent from "../authentication/forgot_password/ForgotPasswordComponent";

const {Content} = Layout;

const MenuComponent = () => {
    const urlToKey = [

        {
            path: "/temps_travail",
            key: "workTimes"
        },
        {
            path: "/tableau_de_bord",
            key: "dashboard"
        },
        {
            path: "/administration",
            key: "administration"
        },
        {
            path: "/",
            key: "home"
        }
    ]

    const avatar_menu_item = {
        key: "avatar",
        style: {marginLeft: "auto"},
        label: (
            <div><Avatar size={40} style={{color: "white", backgroundColor: "#87d068"}}><UserOutlined
                style={{fontSize: "100%"}}/></Avatar></div>),
        children: [
            {
                key: "profile",
                icon: (<UserOutlined/>),
                disabled: true,
                label: Session?.user?.firstName + " " + Session?.user?.lastName
            },
            {
                key: "logout",
                icon: (<LogoutOutlined/>),
                label: (<Link to="/" onClick={AuthenticationService.logout}>Déconnexion</Link>)
            }
        ]
    }

    const default_menu_items = [
        {
            key: "logo",
            label: (<Link to="/"><img style={{height:"3em"}} src="/logo.png" alt="logo"/></Link>)
        },
        {
            key: "home",
            label: (<Link to="/">Accueil</Link>)
        },
        {
            key: "workTimes",
            label: (<Link to="/temps_travail">Temps de travail</Link>)
        }
    ]

    const associe_menu_items = [
        {
            key: "dashboard",
            label: (<Link to="/tableau_de_bord">Tableau de bord</Link>)
        },
        {
            key: "administration",
            label: (<Link to="/administration/clients">Administration</Link>)
        }
    ]

    const [current, setCurrent] = useState(urlToKey.find((urlToKey) => window.location.pathname.startsWith(urlToKey.path))?.key || "home");

    const onClick = e => {
        setCurrent(e.key === "logout" ? "home" : e.key);
    };

    const getNavigationDefault = () => {
        return <Layout style={{minHeight: "100vh", marginTop: (Environment.info_balise ? "1.5em" : undefined)}}>
            <Menu
                onClick={onClick}
                selectedKeys={[current]}
                theme="light"
                mode="horizontal"
                style={{
                    lineHeight: "64px",
                    width: "100%",
                    position: "fixed",
                    zIndex: 1,
                    paddingLeft: "2em",
                    paddingRight: "2em"
                }}
                items={[
                    ...default_menu_items,
                    ...(!Session.isCollaborateur() ? associe_menu_items : []),
                    avatar_menu_item
                ]}
            />
            <Content>
                <Routes>
                    <Route path="/" exact element={<HomeComponent/>}/>
                    <Route path="/reinitialiser_mot_de_passe/:token" exact element={<ResetPasswordComponent/>}/>
                    <Route path="/mot_de_passe_oublie" exact element={<ForgotPasswordComponent/>}/>
                    <Route path="/temps_travail" exact element={<WorkTimesComponent/>}/>
                    {!Session.isCollaborateur() ? <Route path="/tableau_de_bord" exact element={<DashboardComponent />}/> : null}
                    {!Session.isCollaborateur() ? <Route path="/administration/*" element={<AdministrationMenuComponent/>}/> : null}
                    <Route path="*" element={<ErrorPathComponent/>}/>
                </Routes>
            </Content>
            <TempoComptaFooter />
        </Layout>
    }

    const getNavigationNonConnecte = () => {
        return <Layout style={{height: "100vh"}}>
            <Content>
                <Routes>
                    <Route path="/reinitialiser_mot_de_passe/:token" exact element={<ResetPasswordComponent/>}/>
                    <Route path="/mot_de_passe_oublie" exact element={<ForgotPasswordComponent/>}/>
                    <Route path="/" exact element={<LoginComponent/>}/>
                    <Route path="*" element={<LoginComponent/>}/>
                </Routes>
            </Content>
            <TempoComptaFooter />
        </Layout>
    }

    const getNavigation = () => {
        if (Session.user === null) {
            return getNavigationNonConnecte();
        } else {
            return getNavigationDefault();
        }
    }

    return <div>
        <BaliseComponent />
        <BrowserRouter>
            {getNavigation()}
        </BrowserRouter>
    </div>
}
export default observer(MenuComponent)
