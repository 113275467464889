import {Button, Col, Divider, Row} from 'antd';
import {DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons";
import {MilliFormater} from "../../utils/MilliFormater";
import {useEffect, useState} from "react";
import ConfirmPopUp from "./ConfirmPopUp";
import {WorkTimeService} from "../../services/WorkTimeService";
import Session from "../../session/Session";

const WorkTimeComponent = ({workTime, edit, remove, archivedDate}) => {

    const [client, setClient] = useState(null)
    const [user, setUser] = useState(null)
    const [task, setTask] = useState(null)
    const [duration, setDuration] = useState(null)

    useEffect(() => {
        setDuration(MilliFormater.milliToHoursMinutes(workTime.duration * 60000))
    }, [workTime.duration])

    useEffect(() => {
        if (!workTime.client) {
            setClient("Non définie");
        } else {
            setClient(<Row>
                <Col span={18}>
                    <div style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden"
                    }}>{workTime.client.name}</div>
                </Col>
                <Col span={6}>
                    <Row justify="end">
                        <div style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden"
                        }}>{workTime.client.identifier}</div>
                    </Row>
                </Col>
            </Row>)
        }
    }, [workTime.client])

    useEffect(() => {
        setTask(workTime.task.name)
    }, [workTime.task.name])

    useEffect(() => {
        setUser(<div
            style={
                {
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    width: "100%",
                    overflow: "hidden"
                }
            }>{workTime.user.firstName} {workTime.user.lastName}</div>)
    }, [workTime.user])

    const removeWorkTime = () => {
        WorkTimeService.delete(workTime.id, (err) => {
            if(!err) {
                remove()
            }
        })
    }

    return (
        <Row justify="space-between" align="middle">
            <Col style={{paddingRight: "0.5em", width: "20%"}}>{user}</Col>
            <Col style={{paddingRight: "0.5em", width: "30%"}}>{client}</Col>
            <Col style={{paddingRight: "0.5em", width: "25%"}}>
                <div style={{textOverflow: "ellipsis", whiteSpace: "nowrap", width: "100%", overflow: "hidden"}}>{task}</div>
            </Col>
            <Col style={{width: "10%"}}>
                <Row justify="center">
                    {duration}
                </Row>
            </Col>
            <Col style={{width: "15%"}}>
                <Row justify="end">
                    <Col style={{marginRight: "0.5em"}}>
                        <Button
                            onClick={edit}
                            icon={<EditOutlined/>}
                            disabled={Session.isCollaborateur() && archivedDate}
                        />
                    </Col>
                    <Col>
                        <ConfirmPopUp confirm={removeWorkTime}
                                      placement="topLeft"
                                      action="supprimer"
                                      element={" temps de travail"}
                        >
                            <Button type="primary"
                                    danger
                                    icon={<DeleteOutlined/>}
                                    disabled={Session.isCollaborateur() && archivedDate}
                            />
                        </ConfirmPopUp>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default WorkTimeComponent
