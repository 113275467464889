import axios from "axios";
import Session from "../session/Session";
import Environment from "../utils/Environment";

const apiUrl = Environment.back_url + "/api"

const getConnecteHeader = () => {
    return {
        headers: {Authorization: "Bearer " + Session.token},
        withCredentials: true
    }
}

const getConfig = (connecte) => {
    return connecte ? getConnecteHeader() : {withCredentials: true}
}

const gestionError = (err, callback) => {
    console.log(err)
    callback(err?.response?.data || true)
    if(!err?.response || err.response.status === 502) {
        Session.setInfoBalise({
            color: "darkorange",
            message: (Session.user ? "Problème de connexion au serveur, vérifiez votre connexion internet ou rafraichissez la page" : "Maintenance en cours, veuillez réessayer plus tard ou contacter le support")
        })
    } else {
        Session.setInfoBalise(null)
    }
}

export const GeneratorRequest = {
    get: (nomApi, connecte) => (id, callback) => {
        axios.get(apiUrl + "/" + nomApi + (id ? "/" + id : ""), getConfig(connecte)).then(res => {
            callback(false, res.data)
        }).catch((err) => gestionError(err, callback))
    },
    gets: (nomApi, connecte) => (filtre, callback) => {
        let config = getConfig(connecte);
        if (filtre !== null) {
            config.params = filtre
        }
        axios.get(apiUrl + "/" + nomApi, config).then(res => {
            callback(false, res.data)
        }).catch((err) => gestionError(err, callback))
    },
    post: (nomApi, connecte) => (data, callback) => {
        axios.post(apiUrl + "/" + nomApi, data, getConfig(connecte)).then(res => {
            callback(false, res.data)
        }).catch((err) => gestionError(err, callback))
    },
    put: (nomApi, connecte) => (id, data, callback) => {
        axios.put(apiUrl + "/" + nomApi + "/" + id, data, getConfig(connecte)).then(res => {
            callback(false, res.data)
        }).catch((err) => gestionError(err, callback))
    },
    delete: (nomApi, connecte) => (id, callback) => {
        axios.delete(apiUrl + "/" + nomApi + "/" + id, getConfig(connecte)).then(_ => {
            callback(false)
        }).catch((err) => gestionError(err, callback))
    },
    download: (nomApi, connecte) => (filter, callback) => {
        let config = {...getConfig(connecte), responseType: "arraybuffer"};
        if (filter !== null) {
            config.params = filter
        }
        axios.get(apiUrl + "/" + nomApi, config).then(res => {
            let blob = new Blob([res.data], {type: res.headers['content-type']});
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', res.headers['content-disposition'].split("filename=")[1]);
            document.body.appendChild(link);
            link.click();
            callback(false)
        }).catch((err) => gestionError(err))
    }
}
