const StateInUrlDAO = {
    getUrl: () => {
        return new URL(window.location);
    },
    hasQueryParam: (label) => {
        let url = StateInUrlDAO.getUrl();
        return url.searchParams.has(label)
    },
    getQueryParam: (label) => {
        let url = StateInUrlDAO.getUrl();
        return url.searchParams.get(label)
    },
    getQueryParams: () => {
        let url = StateInUrlDAO.getUrl();
        let queryParams = {}
        url.searchParams.forEach((value, key) => {
            queryParams[key] = value
        })
        return queryParams
    },
    setQueryParam: (label, value) => {
        let url = StateInUrlDAO.getUrl();
        url.searchParams.set(label, value)
        url.searchParams.sort()
        window.history.replaceState({}, '', url)
    },
    removeQueryParam: (label) => {
        let url = StateInUrlDAO.getUrl();
        url.searchParams.delete(label)
        window.history.replaceState({}, '', url)
    },
    setQueryParams: (params) => {
        let url = StateInUrlDAO.getUrl();
        Object.keys(params).forEach(key => {
            if (params[key] === undefined) {
                url.searchParams.delete(key)
            } else if (params[key] !== null) {
                url.searchParams.set(key, params[key])
            }
        })
        url.searchParams.sort()
        window.history.replaceState({}, '', url)
    }
}

export default StateInUrlDAO;