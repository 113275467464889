import {UserRepository} from "../repositories/UserRepository";
import {NotificationSender} from "../utils/NotificationSender";
import Session from "../session/Session";

const UserServiceCache = {
    get: {},
    gets: {}
};

export const UserService = {
    get : (id, callback) => {
        Session.resetTimerBeforeLogout()
        if(UserServiceCache.get[id]) {
            callback(false, JSON.parse(JSON.stringify(UserServiceCache.get[id])))
        } else {
            UserRepository.get(id, (err, data) => {
                if(err) {
                    if(!err.errors) {
                        NotificationSender.error("Chargement", "Une erreur est survenue lors du chargement de l'utilisateur")
                    }
                    callback(err)
                } else {
                    UserServiceCache.get[id] = JSON.parse(JSON.stringify(data))
                    callback(false, data)
                }
            })
        }
    },
    gets : (filter, callback, forceRefreshCache = false) => {
        Session.resetTimerBeforeLogout()
        if(filter["authority_ids"] && filter["authority_ids"].length > 0) {
            filter["authority_ids"] = filter["authority_ids"].join(",")
        }
        let stringifyFilters = JSON.stringify(filter);
        if(!forceRefreshCache && UserServiceCache.gets[stringifyFilters]) {
            callback(false, JSON.parse(JSON.stringify(UserServiceCache.gets[stringifyFilters])))
        } else {
            UserRepository.gets(filter, (err, data) => {
                if (err) {
                    if(!err.errors) {
                        NotificationSender.error("Chargement", "Une erreur est survenue lors du chargement des utilisateurs")
                    }
                    callback(err)
                } else {
                    UserServiceCache.gets[stringifyFilters] = JSON.parse(JSON.stringify(data))
                    callback(false, data)
                }
            })
        }
    },
    delete : (id, callback) => {
        Session.resetTimerBeforeLogout()
        UserRepository.delete(id, (err, data) => {
            if(err) {
                if(!err.errors) {
                    NotificationSender.error("Suppression", "Une erreur est survenue lors de la suppression de l'utilisateur")
                }
                callback(err)
            } else {
                NotificationSender.success("Suppression", "L'utilisateur a bien été supprimé")
                UserService.clearCache();
                callback(false, data)
            }
        })
    },
    put : (data, callback) => {
        Session.resetTimerBeforeLogout()
        UserRepository.put(data.id, data, (err, data) => {
            if(err) {
                if(!err.errors) {
                    NotificationSender.error("Modification", "Une erreur est survenue lors de la modification de l'utilisateur")
                }
                callback(err)
            } else {
                NotificationSender.success("Modification", "L'utilisateur a bien été modifié")
                UserService.clearCache();
                callback(false, data)
            }
        })
    },
    post : (data, callback) => {
        Session.resetTimerBeforeLogout()
        UserRepository.post(data, (err, data) => {
            if(err) {
                if(!err.errors) {
                    NotificationSender.error("Création", "Une erreur est survenue lors de la création de l'utilisateur")
                }
                callback(err)
            } else {
                NotificationSender.success("Création", "L'utilisateur a bien été créé")
                UserService.clearCache();
                callback(false, data)
            }
        })
    },
    getDailyActivity: (filters, callback) => {
        Session.resetTimerBeforeLogout()
        UserRepository.getUserDailyActivity(filters, (err, data) => {
            if (err) {
                if(!err.errors) {
                    NotificationSender.error("Chargement", "Une erreur est survenue lors du chargement des activités journalière")
                }
                callback(err)
            } else {
                callback(false, data)
            }
        })
    },
    clearCache: () => {
        UserServiceCache.get = {};
        UserServiceCache.gets = {};
    }
}
