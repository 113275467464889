import {Col, Progress, Row, Typography} from "antd";
import {useEffect, useState} from "react";

const colors = [
    "#F01200",
    "#F04C16",
    "#F07316",
    "#F09816",
    "#F0AC00",
    "#52c41a"
]

function getColorFromPercentage(percentage) {
    // Clamp the percentage between 0 and 100
    percentage = Math.min(100, Math.max(0, percentage));

    // Calculate the index of the color in the colors array
    let index = Math.floor(percentage / 17);

    // Return the color at the index
    return colors[index];
}

const DayCompletionChart = ({ days }) => {
    const [percent, setPercent] = useState(0);
    const [color, setColor] = useState("#87d068");

    useEffect(() => {
        let sumsDuration = days.reduce((acc, dayNotCompleted) => acc + dayNotCompleted.sumDurations, 0);
        let maxDuration = days.reduce((acc, dayNotCompleted) =>  acc + (dayNotCompleted.maxDurations < dayNotCompleted.sumDurations ? dayNotCompleted.sumDurations : dayNotCompleted.maxDurations), 0);
        setPercent(Math.round((sumsDuration / maxDuration) * 100));
    }, [days]);

    useEffect(() => setColor(getColorFromPercentage(percent)), [percent]);

    return <Row justify="space-around" align="middle" style={{height: "100%"}}>
        <Col span={24}>
            <Row justify="center">
                <Progress type="dashboard" percent={percent} strokeColor={color} strokeLinecap="round" size={225}/>
            </Row>
            <Row justify="center">
                <Typography.Title level={5} style={{textAlign: "center"}}>Taux de complétion</Typography.Title>
            </Row>
        </Col>
    </Row>
}

export default DayCompletionChart;