import {Button, Card, Col, Divider, Progress, Row, Tag} from "antd";
import {EditOutlined, EyeOutlined} from "@ant-design/icons";
import dayjs from "dayjs";
import {MilliFormater} from "../../utils/MilliFormater";
import MaxDurationTag from "../../utils/calandar/MaxDurationTag";

const DayNotCompletedComponent = ({dayNotCompleted, openModal}) => {
    return <Card
        bodyStyle={{padding: "0.65em 1em"}}
        style={{ borderRadius: "0.5em", width: "100%"}}
        hoverable
        onClick={openModal}
    >
        <Row justify="space-between" align="middle">
            <Col span={4} style={{paddingRight: "0.5em"}}>
                <div style={{
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    width: "100%",
                    overflow: "hidden"
                }}>{dayjs(dayNotCompleted.date).format("D MMMM")}</div>
            </Col>
            <Col span={6} style={{paddingRight: "0.5em"}}>
                <MaxDurationTag noColor={true} date={dayNotCompleted.date} sumDurations={dayNotCompleted.sumDurations} maxDurations={dayNotCompleted.maxDurations}/>
            </Col>
            <Col span={8}>
                <Progress percent={Math.round((dayNotCompleted.sumDurations / dayNotCompleted.maxDurations) * 100)} size="small" showInfo={false} />
            </Col>
            <Col span={6}>
                <Row justify="end">
                    <Col style={{marginRight: "0.5em"}}>
                        <Button
                            onClick={console.log}>
                            <EyeOutlined/>
                            <Divider type="vertical"/>
                            <EditOutlined/>
                        </Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    </Card>
}

export default DayNotCompletedComponent;