import {Button, Col, Form, Input, Row, Skeleton} from "antd";
import Session from "../../session/Session";
import UserSelect from "../form/select/UserSelect";
import ClientSelect from "../form/select/ClientSelect";
import FiscalYearSelect from "../form/select/FiscalYearSelect";
import TaskSelect from "../form/select/enums/TaskSelect";
import RoleSelect from "../form/select/enums/RoleSelect";
import DurationPicker from "../form/timePicker/DurationPicker";
import {CloseOutlined, SaveOutlined} from "@ant-design/icons";
import {useEffect, useState} from "react";
import {UserService} from "../../services/UserService";
import UserAuthority from "../UserAuthority";
import {ClientService} from "../../services/ClientService";
import {TaskService} from "../../services/TaskService";
import {MilliFormater} from "../MilliFormater";
import {WorkTimeService} from "../../services/WorkTimeService";
import dayjs from "dayjs";
import {NotificationSender} from "../NotificationSender";

const workTimeEquals = (edit, form) => {
    return edit?.user.id === form.userId &&
        edit?.role?.id === form.roleId &&
        edit?.client?.id === form.clientId &&
        edit?.fiscalYear?.id === form.fiscalYearId &&
        edit?.task.id === form.taskId &&
        edit?.comment === form.comment &&
        edit?.duration === MilliFormater.hourDurationToMin(form.duration);
}

const WorkTimeForm = ({wtSaved, date, remove, workTimeToEdit, filters}) => {
    const [form] = Form.useForm();
    const [roles, setRoles] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [fiscalYears, setFiscalYears] = useState([]);
    const [clients, setClients] = useState([]);
    const [users, setUsers] = useState([]);

    const [currentUser, setCurrentUser] = useState(Session.isAdministrateur() ? undefined : filters?.users[0].id === "all" || filters?.users.length > 1 ? Session.user : filters?.users[0]);
    const [currentTask, setCurrentTask] = useState(null);

    const [loadingClients, setLoadingClients] = useState(true);
    const [loadingUsers, setLoadingUsers] = useState(true);
    const [loadingTasks, setLoadingTasks] = useState(true);

    const [disabledClient, setDisabledClient] = useState(false);
    const [disabledRole, setDisabledRole] = useState(false);

    const [validating, setValidating] = useState(false);

    useEffect(() => {
        if (Session.isCollaborateur()) {
            setUsers([Session.user]);
            setRoles(Session.user.roles)
            setLoadingUsers(false);
        } else {
            UserService.gets({authority_ids: [UserAuthority.ASSOCIE.id, UserAuthority.COLLABORATEUR.id]}, (err, data) => {
                if (!err) {
                    setUsers(data);
                    setLoadingUsers(false);
                    if (!!workTimeToEdit) {
                        let user = data.find(u => u.id === workTimeToEdit.user.id);
                        setCurrentUser(user);
                        setRoles(user.roles);
                    }
                }
            })
        }
        if (!!workTimeToEdit) {
            setCurrentTask(workTimeToEdit.task);
            setDisabledClient(!workTimeToEdit.task.groupTask.billable);
        } else if (!Session.isAdministrateur()){
            let cu = filters?.users[0].id === "all" || filters?.users.length > 1 ? Session.user : filters?.users[0];
            setCurrentUser(cu);
            setRoles(cu.roles);
        }

        ClientService.gets((err, data) => {
            if (!err) {
                setClients(data);
                setLoadingClients(false);
                if (!!workTimeToEdit && !!workTimeToEdit.client) {
                    let client = data.find(c => c.id === workTimeToEdit.client?.id);
                    setFiscalYearsFromClient(client);
                }
            }
        })
        TaskService.gets({}, (err, data) => {
            if (!err) {
                setTasks(data);
                setLoadingTasks(false);
            }
        })
    }, [])

    const onChangeUser = (userId) => {
        let user = users.find(u => u.id === userId);
        setCurrentUser(user);
        setRoles(user.roles);
        form.setFields([
            {
                name: "roleId",
                value: user.roles[0].id
            }
        ])
    }

    const setFiscalYearsFromClient = (client) => {
        let fiscalYears = client
            .missionLetters
            .map(missionLetter => missionLetter.fiscalYears)
            .filter(fiscalYears => fiscalYears.length > 0)
            .map(fiscalYears => fiscalYears.filter(fiscalYear => fiscalYear.actif))
            .flat()
        if (fiscalYears.length > 0) {
            form.setFields([
                {
                    name: "fiscalYearId",
                    value: !!workTimeToEdit ? workTimeToEdit.fiscalYear?.id : fiscalYears[0].id,
                    errors: []
                }
            ])
        }
        setFiscalYears(fiscalYears)
    }

    const onChangeClient = (clientId) => {
        if (!!clientId) {
            let client = clients.find(c => c.id === clientId);
            setFiscalYears(client.fiscalYears);
            setFiscalYearsFromClient(client);
        } else {
            setFiscalYears([]);
            form.setFields([
                {
                    name: "fiscalYearId",
                    value: null,
                    errors: []
                }
            ])
        }
    }

    const onChangeTask = (taskId) => {
        let task = tasks.find(t => t.id === taskId);
        setCurrentTask(task);
        if (!task.groupTask.billable) {
            setDisabledClient(true);
            setDisabledRole(true)
            form.setFields([
                {
                    name: "clientId",
                    value: null,
                    errors: []
                },
                {
                    name: "fiscalYearId",
                    value: null,
                    errors: []
                },
                {
                    name: "roleId",
                    value: null,
                    errors: []
                }
            ])
            onChangeClient(null);
        } else {
            setDisabledClient(false);
            setDisabledRole(false);
            form.setFields([
                {
                    name: "roleId",
                    value: currentUser.roles[0].id,
                    errors: []
                }
            ])
        }
    }

    const onFinish = (values) => {
        setValidating(true);
        form.setFields(Object.keys(values).map(key => ({
            name: key,
            validating: true
        })))
        let request = WorkTimeService.post;
        if (!!workTimeToEdit) {
            if (!form.isFieldsTouched() || workTimeEquals(workTimeToEdit, values)) {
                wtSaved(workTimeToEdit);
                return;
            }
            request = WorkTimeService.put;
        }
        request({
            id: workTimeToEdit?.id,
            date: date.format("YYYY-MM-DD"),
            duration: MilliFormater.hourDurationToMin(values.duration),
            taskId: values.taskId,
            userId: values.userId,
            roleId: values.roleId,
            clientId: values.clientId,
            fiscalYearId: values.fiscalYearId,
            comment: values.comment
        }, (err, data) => {
            if (err?.errors) {
                form.setFields(err.errors.fields.map(field => {
                    if (field.field[0] === "date") {
                        NotificationSender.error("Sauvegarde", field.message);
                    }
                    return {
                        name: field.field,
                        errors: [field.message]
                    }
                }));
            } else {
                wtSaved(data);
            }
            setValidating(false);
        })
    }

    return <Form
        name="workTimeForm"
        onFinish={onFinish}
        form={form}
        layout="horizontal"
        initialValues={!!workTimeToEdit ?
            {
                userId: workTimeToEdit.user.id,
                roleId: workTimeToEdit.role?.id,
                clientId: workTimeToEdit.client?.id,
                fiscalYearId: workTimeToEdit.fiscalYear?.id,
                taskId: workTimeToEdit.task.id,
                duration: dayjs.unix(workTimeToEdit.duration * 60).utc(),
                comment: workTimeToEdit.comment
            }
            : {
                userId: currentUser?.id,
                roleId: currentUser?.roles.length > 0 ? currentUser?.roles[0].id : undefined,
            }}
    >
        <Row gutter={8}>
            <Col style={{width: "20%"}}>
                <Row>
                    <Form.Item
                        style={{width: "100%", marginBottom: "0.5em"}}
                        name="userId"
                        hasFeedback={validating}
                        rules={[{required: true, message: "Utilisateur à sélectionner"}]}
                    >
                        <UserSelect
                            customOnChange={onChangeUser}
                            disabled={validating}
                            users={users}
                            loading={loadingUsers}
                        />
                    </Form.Item>
                </Row>
                <Row>
                    <Form.Item
                        style={{width: "100%", marginBottom: "0.5em"}}
                        name="roleId"
                        hasFeedback={validating}
                        rules={[
                            (_) => {
                                if (!currentTask || currentTask.groupTask.billable) {
                                    return {
                                        required: true,
                                        message: "Role à sélectionner"
                                    }
                                }
                            }
                        ]}
                    >
                        <RoleSelect
                            roles={roles}
                            disabled={validating || disabledRole}
                        />
                    </Form.Item>
                </Row>
            </Col>
            <Col style={{width: "34%"}}>
                <Row>
                    <Form.Item
                        style={{width: "100%", marginBottom: "0.5em"}}
                        name="clientId"
                        hasFeedback={validating}
                        rules={[
                            (_) => {
                                if (!currentTask || currentTask.groupTask.billable) {
                                    return {
                                        required: true,
                                        message: "Client à sélectionner"
                                    }
                                }
                            }
                        ]}
                    >
                        <ClientSelect
                            computeDisabled={true}
                            loading={loadingClients}
                            clients={clients}
                            disabled={validating || disabledClient}
                            customOnChange={onChangeClient}
                        />
                    </Form.Item>
                </Row>
                <Row>
                    <Form.Item
                        style={{width: "100%", marginBottom: "0.5em"}}
                        name="taskId"
                        hasFeedback={validating}
                        rules={[{required: true, message: "Tâche à sélectionner"}]}
                    >
                        <TaskSelect
                            tasks={tasks}
                            disabled={validating}
                            loading={loadingTasks}
                            onChange={onChangeTask}
                            computeDisabled={true}
                        />
                    </Form.Item>
                </Row>
            </Col>
            <Col style={{width: "41%"}}>
                <Row gutter={8}>
                    <Col style={{width: "60%"}}>
                        <Form.Item
                            style={{width: "100%", marginBottom: "0.5em"}}
                            name="fiscalYearId"
                            hasFeedback={validating}
                            rules={[
                                (_) => {
                                    if (!currentTask || currentTask.groupTask.billable) {
                                        return {
                                            required: true,
                                            message: "Année fiscal à sélectionner"
                                        }
                                    }
                                }
                            ]}
                        >
                            <FiscalYearSelect
                                computeDisabled={true}
                                disabled={validating}
                                fiscalYears={fiscalYears}
                            />
                        </Form.Item>
                    </Col>
                    <Col style={{width: "40%"}}>
                        <Form.Item
                            name="duration"
                            hasFeedback={validating}
                            style={{width: "100%", marginBottom: "0.5em"}}
                            rules={[{required: true, message: "Durée à sélectionner"}]}
                        >
                            <DurationPicker
                                date={date}
                                user={currentUser}
                                disabled={validating}
                                minuteStep={15}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Form.Item
                        style={{width: "100%", marginBottom: "0.5em", height: "1em"}}
                        name="comment"
                        hasFeedback={validating}
                    >
                        <Input
                            showCount
                            disabled={validating}
                            loading={loadingTasks}
                            maxLength={200}
                            placeholder="Commentaire"
                        />
                    </Form.Item>
                </Row>
            </Col>
            <Col style={{width: "5%"}}>
                    <Row justify="center">
                        <Col span={24}>
                            <Row>
                                <Col span={24}>
                                    {loadingClients || loadingUsers || loadingTasks ?
                                        <Skeleton.Button active/> :
                                        <Button style={{width: "100%"}} type="primary" htmlType="submit"
                                                icon={<SaveOutlined/>}
                                        />}
                                </Col>
                            </Row>
                            <Row style={{marginTop: "0.5em"}}>
                                <Col span={24}>
                                    {loadingClients || loadingUsers || loadingTasks ?
                                        <Skeleton.Button active/> :
                                        <Button style={{width: "100%"}}
                                                type="primary"
                                                danger
                                                icon={<CloseOutlined/>}
                                                onClick={remove}
                                        />}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
            </Col>
        </Row>
    </Form>
}

export default WorkTimeForm;