import {Card, Col, Row} from "antd";
import {useEffect, useState} from "react";
import Session from "../../session/Session";
import {LockOutlined, PlusOutlined} from "@ant-design/icons";
import WTCalandarDayCardExtraHeader from "./WTCalandarDayCardExtraHeader";
import WTDayCardList from "./WTDayCardList";

const disabledFontColor = `rgba(0, 0, 0, 0.25)`

const WTDayCard = ({
                               workTimes,
                               disabled,
                               filters,
                               date,
                               height,
                               archivedDate,
                               showWorkTimeList = true,
                               setWorkTimeModalDate
                           }) => {

    const [isArchived, setIsArchived] = useState(false);

    useEffect(() => {
        if (archivedDate) {
            setIsArchived(archivedDate.isSame(date, "day") || archivedDate.isAfter(date, "day"))
        }
    }, [archivedDate]);


    return <Card
        className="work-time-card"
        size="small"
        title={date.format("D MMMM")}
        headStyle={{color: (disabled ? disabledFontColor : undefined)}}
        style={{
            width: "100%",
            height: "100%",
            color: (disabled ? disabledFontColor : "lightgray")
        }}
        extra={<WTCalandarDayCardExtraHeader
            filters={filters}
            workTimes={workTimes}
            isArchived={isArchived}
            disabled={disabled}
            date={date}
        />}
        hoverable={!disabled}
        onClick={() => setWorkTimeModalDate(date)}
    >
        <Row>
            <Col span={24} style={{overflow: "auto", height: height}}>
                {showWorkTimeList ?
                    workTimes?.length ? <WTDayCardList workTimes={workTimes} filters={filters}/>:
                    <Row justify="center" align="center">
                        { Session.isCollaborateur() && isArchived ? <LockOutlined className="work-time-card-plus"/> : <PlusOutlined className="work-time-card-plus"/>}
                    </Row> :
                    null}
            </Col>
        </Row>
    </Card>

}

export default WTDayCard