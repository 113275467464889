import {Button, Col, Divider, Popover, Row, Tag, Typography} from "antd";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import ConfirmPopUp from "../../workTimes/ConfirmPopUp";
import {GroupTasksService} from "../../../services/GroupTasksService";
import FormMode from "../../../utils/FormMode";

const GroupTasksComponent = ({groupTask, removed, setMode}) => {

    const deleteTaskGroup = () => {
        GroupTasksService.delete(groupTask.id, (err, _) => {
            if (!err) {
                removed()
            }
        })
    }

    return <Divider orientation="left" style={{marginBottom: "0.25em"}}><Row gutter={10} wrap={false}>
        <Col>
            <Typography.Title style={{marginTop: "0.1em"}} level={4}>{groupTask.name}</Typography.Title>
        </Col>
        <Col>
            <Tag style={{
                marginTop: "0.5em"
            }}>{groupTask.billable ? "Facturable" : "Non facturable"}</Tag>
        </Col>
        <Col>
            <Button
                onClick={() => setMode(FormMode.EDITING)}
                icon={<EditOutlined/>}
            />
        </Col>
        <Col>
            {
                groupTask.deletable ?
                    <ConfirmPopUp confirm={() => deleteTaskGroup()}
                                  placement="topLeft"
                                  action="supprimer"
                                  element=" type de tâche"
                    >
                        <Button
                            type="primary"
                            danger
                            icon={<DeleteOutlined/>}/>
                    </ConfirmPopUp>
                    :
                    <Popover
                        content="Ce type de tâche ne peut pas être supprimé car elle est liée à des temps de travail"
                        trigger="hover">
                        <Button
                            type="primary"
                            danger
                            disabled
                            icon={<DeleteOutlined/>}/>
                    </Popover>
            }
        </Col>
    </Row>
    </Divider>
}

export default GroupTasksComponent;