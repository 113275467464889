import {GeneratorRequest} from "./GeneratorRequest";

const nomApi = "work_times"

export const WorkTimeRepository = {
    gets : GeneratorRequest.gets(nomApi, true),
    post : GeneratorRequest.post(nomApi, true),
    put : GeneratorRequest.put(nomApi, true),
    delete: GeneratorRequest.delete(nomApi, true),
    download : GeneratorRequest.download(nomApi + "/resume", true),
    dashboard: GeneratorRequest.gets(nomApi + "/dashboard", true),

}
