import {Col, Row, Typography} from 'antd';
import Session from "../../session/Session";
import dayjs from "dayjs";
import {useEffect, useState} from "react";
import {ArchivingService} from "../../services/ArchivingService";
import WTCalandar from "../../utils/calandar/WTCalandar";
import DaysNotCompletedComponent from "./DaysNotCompletedComponent";
import UserSelect from "../../utils/form/select/UserSelect";
import {UserService} from "../../services/UserService";
import UserAuthority from "../../utils/UserAuthority";

const {Title} = Typography;

const HomeComponent = () => {
    const [archivedDate, setArchivedDate] = useState(null)
    const [loading, setLoading] = useState(true)
    const [users, setUsers] = useState([])

    const [filters, setFilters] = useState({
        users: [Session.user],
        clients: [{id: 'all', name: 'Tous les clients', companyType: {name: 'Tous'}}],
        after: dayjs().startOf("week"),
        before: dayjs().endOf("week")
    })

    useEffect(() => {
        let loadingArchivedDate = true
        let loadingUsers = true
        let verifyLoading = () => {
            if (!loadingArchivedDate && !loadingUsers) {
                setLoading(false)
            }
        }
        ArchivingService.getCurrentArchivingDate((err, data) => {
            if (!err) {
                setArchivedDate(data)
            }
            loadingArchivedDate = false
            verifyLoading()
        })
        if (!Session.isCollaborateur()) {
            UserService.gets({authority_ids: [UserAuthority.ASSOCIE.id, UserAuthority.COLLABORATEUR.id]}, (err, data) => {
                if (!err) {
                    setUsers(data)
                    if (Session.isAdministrateur()) {
                        setFilters({
                            ...filters,
                            users: [data[0]]
                        })
                    }
                }
                loadingUsers = false
                verifyLoading()
            })
        } else {
            loadingUsers = false
            verifyLoading()
        }
    }, [])

    return (
        <div className="content">
            <Row justify="center">
                <Col span={24} style={{maxWidth: "1500px"}}>
                    <Col span={24}>
                        <Row align="top" className="home-title">
                            <Col>
                                <Title level={1}>Bonjour </Title>
                            </Col>
                            <Col>
                                {
                                    Session.isCollaborateur() ?
                                        <Title level={1} style={{marginLeft: "0.25em"}}>{Session.user.firstName} {Session.user.lastName}</Title> :
                                        <UserSelect
                                            loading={loading}
                                            variant="borderless"
                                            style={{marginTop: "0.2em"}}
                                            size="large"
                                            value={filters.users[0].id}
                                            users={users}
                                            onChange={(value) => {
                                                setFilters({
                                                    ...filters,
                                                    users: [users.find(u => u.id === value)]
                                                })
                                            }}
                                        />
                                }
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24} style={{
                        backgroundColor: "white",
                        maxWidth: "1500px",
                        padding: "2em",
                        borderRadius: "0.5em",
                        border: "1px solid #f0f0f0"
                    }}>
                        <WTCalandar
                            loading={loading}
                            archivedDate={archivedDate}
                            showWeekNumber
                            filters={filters}
                            setLoadingWorkTimes={() => {}}
                        />
                    </Col>
                    <Col span={24} style={{
                        backgroundColor: "white",
                        maxWidth: "1500px",
                        padding: "2em 0 2em 2em",
                        borderRadius: "0.5em",
                        border: "1px solid #f0f0f0",
                        marginTop: "1em"
                    }}>
                            <DaysNotCompletedComponent
                                archivedDate={archivedDate}
                                loading={loading}
                                user={filters.users?.[0]}
                            />
                    </Col>
                </Col>
            </Row>
        </div>
    );
};

export default HomeComponent
