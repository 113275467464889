import AbstractSelect from "./AbstractSelect";

const UserSelect = ({customOnChange, users, allowClear, disabled, value, onChange, loading, allowMultiple, size, style, variant}) => {

    return <AbstractSelect
        onChange={customOnChange}
        elements={users}
        getValue={(user) => user.id}
        getPrint={(user) => user.firstName + " " + user.lastName}
        getGroup={(user) => user.authority.name}
        elementName="Utilisateur"
        allowClear={allowClear}
        disabled={disabled}
        antValue={value}
        antOnChange={onChange}
        loading={loading}
        mode={allowMultiple ? "multiple" : null}
        size={size}
        variant={variant}
        style={style}
    />;
}

export default UserSelect;
