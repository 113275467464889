import {NotificationSender} from "../utils/NotificationSender";
import {HourlyRateRepository} from "../repositories/HourlyRateRepository";
import {CompanyTypeService} from "./CompanyTypeService";
import Session from "../session/Session";
import dayjs from "dayjs";

let dayFormated = dayjs().format("YYYY-MM-DD");

export const getCurrentBillablePrice = (hourlyRate) => {
    return sortHourlyRatePrices(hourlyRate.hourlyRatePrices
        .filter((a) => a.applicable <= dayFormated || a.applicable === null))[0].currentBillablePrice
}

export const sortHourlyRatePrices = (hourlyRatePrices) => {
    return hourlyRatePrices
        .sort((a, b) => a.applicable == null || a.applicable < b.applicable ? -1 : 1)
        .reverse()
}

export const HourlyRateService = {
    gets : (callback) => {
        Session.resetTimerBeforeLogout()
        HourlyRateRepository.gets(undefined, (err, data) => {
            if (err) {
                if (!err.errors) {
                    NotificationSender.error("Chargement", "Une erreur est survenue lors du chargement des taux horaires")
                }
                callback(err)
            } else {
                callback(false, data.map(hourlyRate => (
                    {
                        ...hourlyRate,
                        hourlyRatePrices: sortHourlyRatePrices(hourlyRate.hourlyRatePrices),
                        currentBillablePrice: getCurrentBillablePrice(hourlyRate)
                    }
                )))
            }
        })
    },
    put: (hourlyRate, callback) => {
        Session.resetTimerBeforeLogout()
        HourlyRateRepository.put(hourlyRate.id, hourlyRate, (err, data) => {
            if (err) {
                if (!err.errors) {
                    NotificationSender.error("Sauvegarde", "Une erreur est survenue lors de la sauvegarde du taux horaire")
                }
                callback(err)
            } else {
                NotificationSender.success("Modification", "Le nouveaux taux horaire à bien été enregistré")
                callback(false, data)
            }
        })
    },
    postRole: (hourlyRates, callback) => {
        Session.resetTimerBeforeLogout()
        HourlyRateRepository.post(hourlyRates, (err, data) => {
            if (err) {
                if (!err.errors) {
                    NotificationSender.error("Sauvegarde", "Une erreur est survenue lors de la sauvegarde du rôle et des taux horaires")
                }
                callback(err)
            } else {
                NotificationSender.success("Sauvegarde", "Le nouveau rôle et les nouveaux taux horaires ont bien été enregistrés")
                callback(false, data.map(hourlyRate => (
                    {
                        ...hourlyRate,
                        hourlyRatePrices: sortHourlyRatePrices(hourlyRate.hourlyRatePrices),
                        currentBillablePrice: getCurrentBillablePrice(hourlyRate)
                    }
                )))
            }
        })
    },
    postCompanyType: (hourlyRates, callback) => {
        Session.resetTimerBeforeLogout()
        HourlyRateRepository.post(hourlyRates, (err, data) => {
            if (err) {
                if (!err.errors) {
                    NotificationSender.error("Sauvegarde", "Une erreur est survenue lors de la sauvegarde du type d'organisation et des taux horaires")
                }
                callback(err)
            } else {
                NotificationSender.success("Sauvegarde", "Le nouveau type d'organisation et les nouveaux taux horaires ont bien été enregistrés")
                CompanyTypeService.clearCache();
                callback(false, data.map(hourlyRate => (
                    {
                        ...hourlyRate,
                        hourlyRatePrices: sortHourlyRatePrices(hourlyRate.hourlyRatePrices),
                        currentBillablePrice: getCurrentBillablePrice(hourlyRate)
                    }
                )))
            }
        })
    }
}
