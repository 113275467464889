import {GeneratorRequest} from "./GeneratorRequest";

const nomApi = "authentication"

export const AuthenticationRepository = {
    login: GeneratorRequest.post(nomApi + "/login", false),
    logout: GeneratorRequest.post(nomApi + "/logout", true),
    reset_password: GeneratorRequest.post(nomApi + "/reset_password", false),
    forgot_password: GeneratorRequest.post(nomApi + "/forgot_password", false)
}
