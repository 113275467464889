import {
    Button,
    Col,
    Form, Row
} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import FiscalYearForm from "./FiscalYearForm";
import dayjs from "dayjs";

const FiscalYearsFormList = ({indexMissionLetter, formValidating}) => {

    return <Form.List name={[indexMissionLetter, "fiscalYears"]}>
        {
            (fields, {add, remove}) => (
                <>
                    <Row>
                        <Col span={24} style={{marginBottom: "0.5em"}}>
                            <Button
                                block
                                icon={<PlusOutlined/>}
                                onClick={() => add({dates: [dayjs().startOf("month"), dayjs().add(11, "month").endOf("month")], actif: true}, 0)}
                                disabled={formValidating}
                            >
                                Ajouter une année fiscal
                            </Button>
                        </Col>
                    </Row>
                    {
                        fields.map(field => (
                                <FiscalYearForm
                                    key={field.key}
                                    index={field.name}
                                    indexMissionLetter={indexMissionLetter}
                                    remove={() => remove(field.name)}
                                    formValidating={formValidating}
                                />
                            )
                        )
                    }
                </>
            )
        }
    </Form.List>
}

export default FiscalYearsFormList;