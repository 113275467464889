import {Component} from "react";
import {Button, Col, Result, Row} from "antd";
import {Link} from "react-router-dom";

class ErrorPathComponent extends Component {
    render() {
        return <div className="content">
            <Row justify="center" align="middle">
                <Col style={{height: "100%"}}>
                    <Result
                        status="404"
                        title="404"
                        subTitle="Désolé, la page que vous avez demandée n'existe pas."
                        extra={<Link to="/"><Button type="primary">Retour à l'accueil</Button></Link>}
                    />
                </Col>
            </Row>
        </div>
    }
}

export default ErrorPathComponent;
