import Environment from "./Environment";
import Session from "../session/Session";
import {observer} from "mobx-react";

const BaliseComponent = () => {
    let infoBaliseElement = Session.getInfoBalise() || Environment.info_balise;
    if(infoBaliseElement) {
        return <div
            style={{
                display: 'flex',
                height: '1.5em',
                background: infoBaliseElement.color,
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                color: '#FFF',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <div>{infoBaliseElement.message}</div>
        </div>
    } else {
        return null
    }
}

export default observer(BaliseComponent);