import {Col, Row, Typography} from "antd";
import WTCalandarWeekStart from "./WTCalandarWeekStart";
import WTCalandarWeekEnd from "./WTCalandarWeekEnd";

const WTCalandarWeek = ({ startOfWeek, workTimesByDays, filters, showWeekNumber, archivedDate, setWorkTimeModalDate }) => {

    return <Row style={{width: "100%"}}>
        <Col span={24}>
            {showWeekNumber ? <Row>
                <Col>
                    <Typography.Title level={3} style={{color: "lightgray"}}>Semaine {startOfWeek.week()}</Typography.Title>
                </Col>
            </Row> : null}
            <Row style={{width: "100%"}}>
                <Col span={20}>
                    <WTCalandarWeekStart
                        setWorkTimeModalDate={setWorkTimeModalDate}
                        filters={filters}
                        archivedDate={archivedDate}
                        workTimesByDays={Object.values(workTimesByDays).splice(0, 5)}
                    />
                </Col>
                <Col span={4} style={{paddingLeft: "0.75em"}}>
                    <WTCalandarWeekEnd
                        setWorkTimeModalDate={setWorkTimeModalDate}
                        filters={filters}
                        archivedDate={archivedDate}
                        workTimesByDays={Object.values(workTimesByDays).splice(5, 2)}
                    />
                </Col>
            </Row>
        </Col>
    </Row>
}

export default WTCalandarWeek;