import {Button, Col, Form, Input, Row, Typography} from "antd";
import {CheckCircleTwoTone, LockOutlined, UserOutlined} from "@ant-design/icons";
import {useEffect, useState} from "react";
import {AuthenticationService} from "../../../services/AuthenticationService";
import {NotificationSender} from "../../../utils/NotificationSender";
import {useNavigate, useParams} from "react-router-dom";
import Session from "../../../session/Session";

const {Title} = Typography;

const ResetPasswordComposent = () => {
    let navigate = useNavigate();
    const [loadingButton, setLoadingButton] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        if (Session.user) {
            AuthenticationService.logout();
        }
    }, []);

    const onFinish = (values) => {
        setLoadingButton(true)
        AuthenticationService.forgot_password(values.username, (err, _) => {
            if (err?.errors?.fields) {
                form.setFields(err.errors.fields.map(field => ({
                    name: field.field,
                    errors: [field.message]
                })))
            } else {
                NotificationSender.success("Mot de pass oublié", "Un e-mail vous a été envoyé. Si ce n'est pas le cas, contactez le support.")
                navigate("/")
            }
            setLoadingButton(false)
        })
    };

    return (
        <Row justify="center" align="middle" style={{height: "100%"}}>
            <Col span={9}
                 style={{backgroundColor: "white", padding: "3em", borderRadius: "0.5em", maxWidth: "40em"}}>
                <Title level={2} style={{marginBottom: "0.15em"}}>Mot de passe oublié</Title>
                <div style={{marginBottom: "1.5em", color: "gray"}}>Envoyer nous votre demande de réinitialisation de mot de passe.<br/> Suite à votre demande un e-mail vous sera envoyé.</div>
                <Form
                    form={form}
                    name="forgot_password"
                    onFinish={onFinish}
                >
                    <Form.Item
                        style={{marginBottom: "1em"}}
                        name="username"
                        hasFeedback={false}
                        rules={[{required: true, message: "Identifiant à saisir"}]}
                    >
                        <Input
                            prefix={<UserOutlined className="site-form-item-icon"/>}
                            placeholder="Identifiant"
                        />
                    </Form.Item>
                    <Form.Item style={{marginBottom: 0}}>
                        <Row justify="center">
                            <Button type="primary" htmlType="submit"
                                    style={{width: "75%", marginTop: "1em"}} loading={loadingButton}>
                                Envoyer
                            </Button>
                        </Row>
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    );
};

export default ResetPasswordComposent;
