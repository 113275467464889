import {Form, Col, Input, Row} from "antd";
import {RoleService} from "../../../services/RoleService";
import Cell from "../../../utils/table/Cell";

const RolesColumn = ({roles, showAddRoleRow, onDelete}) => {

    return (
        <Row style={{width: "100%"}}>
            <Col span={24} style={{
                padding: "1px 0 0 0",
                borderLeft: "1px solid #F0F0F0",
                borderRadius: "0.5em 0 0 0.5em",
            }}>
                {roles.map((role, index) => (
                    <Row key={role.id} style={{width: "100%"}}>
                        <Cell
                            style={{
                                fontWeight: "bold",
                                width: "100%",
                                padding: "0 1em",
                                backgroundColor: "#FAFAFA",
                                borderTop: index === 0 ? "1px solid #F0F0F0" : "none",
                                borderBottom: index === roles.length - 1 ? "none" : "1px solid #F0F0F0",
                                borderRadius: index === 0 ? "0.5em 0 0 0" : index === roles.length - 1 ? "0 0 0 0.5em" : "0",
                            }}
                            value={role}
                            render={(value) => value}
                            getContent={(value) => value.name}
                            setContent={(content) => role.name = content}
                            editInput={<Input placeholder="Rôle" autoFocus/>}
                            onDelete={() => onDelete(role.id)}
                            deletable={() => role.deletable}
                            contentName=" rôle"
                            updateApi={RoleService.put}
                            deleteApi={(callback) => RoleService.delete(role.id, callback)}
                        />
                    </Row>
                ))}
                {showAddRoleRow &&
                    <Row style={{width: "100%"}}>
                        <Cell
                            style={{
                                fontWeight: "bold",
                                width: "100%",
                                padding: "0 1em",
                                backgroundColor: "#FAFAFA",
                                borderTop: "1px solid #F0F0F0",
                                borderRadius: "0 0 0 0.5em",
                            }}
                            editInput={<Form.Item
                                name="name"
                                style={{margin: 0}}
                                rules={[{required: true, message: "Doit être saisie"}]}
                            ><Input autoFocus placeholder="Rôle"/></Form.Item>}
                            createMode={true}
                        />
                    </Row>}
            </Col>
        </Row>
    );
}

export default RolesColumn;