import {useEffect, useState} from "react";
import {Button, Col, List, Row} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import UserComponent from "./UserComponent";
import UserFormModal from "./UserFormModal";
import StateInUrlDAO from "../../../utils/StateInUrlDAO";

const UserListComponent = ({users, deleteUser, setEnabled, userSaved}) => {

    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const [userToEdit, setUserToEdit] = useState(null);

    const _userSaved = (date, isNewUser) => {
        StateInUrlDAO.removeQueryParam("user_modal")
        setShowAddUserModal(false);
        userSaved(date, isNewUser);
    }

    useEffect(() => {
        if (StateInUrlDAO.hasQueryParam("user_modal") && users.length) {
            if (StateInUrlDAO.getQueryParam("user_modal") === "new") {
                setUserToEdit(null)
                setShowAddUserModal(true)
            } else {
                let user = users.find(client => client.id === parseInt(StateInUrlDAO.getQueryParam("client_modal")))
                if (user) {
                    setUserToEdit(user)
                    setShowAddUserModal(true)
                }
            }
        }
    }, [users]);

    return <>
        <Row>
            <Col span={24}>
                <>
                    <Row>
                        <Col span={24} style={{marginBottom: "0.5em"}}>
                            <Button block onClick={() => {
                                setUserToEdit(null)
                                setShowAddUserModal(true)
                                StateInUrlDAO.setQueryParam("user_modal", "new")
                            }} icon={<PlusOutlined/>}>Ajouter un utilisateur</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <List
                                pagination={{
                                    position: "bottom",
                                    align: "center"
                                }}
                                dataSource={users}
                                renderItem={(user, index) => {
                                    return <Col span={24} key={user.id}>
                                        <UserComponent
                                            user={user}
                                            deleteUser={() => deleteUser(user.id)}
                                            setEnabled={(enabled) => setEnabled(user, enabled)}
                                            openEditForm={() => {
                                                setUserToEdit(user)
                                                setShowAddUserModal(true)
                                                StateInUrlDAO.setQueryParam("user_modal", user.id)
                                            }}
                                        />
                                    </Col>

                                }}
                            />
                        </Col>
                    </Row>
                </>
            </Col>
        </Row>
        {showAddUserModal && <UserFormModal
            user={userToEdit}
            onCancel={() => {
                setShowAddUserModal(false)
                StateInUrlDAO.removeQueryParam("user_modal")
            }}
            userSaved={_userSaved}
        />}
    </>;
}

export default UserListComponent;