import {Card} from 'antd';
import {useState} from "react";
import HourlyRatePriceReadingComponent from "./HourlyRatePriceReadingComponent";
import FormMode from "../../../utils/FormMode";
import HourlyRatePriceForm from "./HourlyRatePriceForm";

const HourlyRatePriceComponent = ({mode, hourlyRatePrice, hourlyRateId, remove, add, edit}) => {

    const [hrp, setHrp] = useState(hourlyRatePrice);
    const [md, setMd] = useState(mode)

    const setEdit = () => {
        setMd(FormMode.EDITING)
    }

    let content = <HourlyRatePriceReadingComponent
        hourlyRatePrice={hrp}
        remove={() => remove(hourlyRatePrice.id)}
        edit={setEdit}
    />

    if (md === FormMode.CREATING) {
        content = <HourlyRatePriceForm
            hourlyRateId={hourlyRateId}
            saved={newHrp => {
                newHrp.addingId = hrp.id
                add(newHrp)
            }}
            remove={() => remove(hrp.id)}
        />
    } else if (md === FormMode.EDITING) {
        content = <HourlyRatePriceForm
            hourlyRateId={hourlyRateId}
            hourlyRatePrice={hrp}
            saved={hrp => {
                setHrp(hrp)
                setMd(FormMode.READING)
                edit(hrp)
            }}
            remove={() => setMd(FormMode.READING)}
        />
    }

    return (
        <Card
            bodyStyle={{padding: "0.65em 1em"}}
            style={{width: "100%", borderRadius: "0.5em", marginBottom: "0.5em"}}
        >
            {content}
        </Card>
    );
};

export default HourlyRatePriceComponent
