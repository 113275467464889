import {Form, List} from "antd";
import MaxDurationDayItem from "./MaxDurationDayItem";

const MaxDurationsForm = () => {

    return <Form.List
        name="workLimits"
    >
        {
            (fields) => {
                return <List
                    split={false}
                    grid={{gutter: 10, column: 7}}
                    dataSource={fields}
                    renderItem={field => {
                        return <List.Item style={{marginBottom: 0}}>
                            <MaxDurationDayItem
                                key={field.key}
                                index={field.index}
                                weekday={field.name}
                            />
                        </List.Item>
                    }}
                />
            }
        }
    </Form.List>
}

export default MaxDurationsForm;