import {Button, Col, Divider, Form, Input, Row, Segmented} from "antd";
import {
    CloseOutlined,
    SaveOutlined,
} from "@ant-design/icons";
import FormMode from "../../../utils/FormMode";
import {GroupTasksService} from "../../../services/GroupTasksService";
import {useState} from "react";

const GroupTasksFormComponent = ({groupTasks, edit, saved, removed, setMode}) => {

    const [form] = Form.useForm();
    const [billableType, setBillableType] = useState(groupTasks.billable ? "Facturable" : "Non facturable");

    const onChangeBillableType = (value) => {
        setBillableType(value)
    }

    const onErrors = (err) => {
        if (err.errors) {
            err.errors.fields.forEach(field => {
                form.setFields([{name: field.field, errors: [field.message]}])
            })
        }
    }
    const onFinish = (newGroupTasks) => {
        newGroupTasks = {...newGroupTasks, billable: billableType === "Facturable"}
        if (groupTasks.newEntity) {
            GroupTasksService.post({...groupTasks, ...newGroupTasks}, (err, data) => {
                if (!err) {
                    saved({...groupTasks, ...data, newEntity: false}, groupTasks.id)
                } else {
                    onErrors(err)
                }
            })
        } else {
            if (newGroupTasks.name !== groupTasks.name || newGroupTasks.billable !== groupTasks.billable) {
                edit({...groupTasks, ...newGroupTasks}, onErrors)
            } else {
                setMode(FormMode.READING)
            }
        }
    }

    return <Form
        style={{width: "100%"}}
        form={form}
        initialValues={groupTasks.newEntity ? undefined : groupTasks}
        onFinish={onFinish}
    >
        <Row gutter={6} wrap={false} style={{width: "100%", marginTop: "16px"}}>
            <Col span={8}>
                <Form.Item
                    name="name"
                    style={{marginBottom: 0}}
                    rules={[{required: true, message: "Nom à saisir"}]}
                >
                    <Input placeholder="Nom"/>
                </Form.Item>
            </Col>
            <Col span={11}>
                <Segmented
                    block
                    value={billableType}
                    onChange={onChangeBillableType}
                    options={[
                        "Facturable",
                        "Non facturable"
                    ]}
                />
            </Col>
            <Col>
                <Button
                    type="primary"
                    htmlType="submit"
                    icon={<SaveOutlined/>}
                />
            </Col>
            <Col>
                <Button
                    type="primary"
                    danger
                    onClick={groupTasks.newEntity ? () => removed() : () => setMode(FormMode.READING)}
                    icon={<CloseOutlined/>}/>
            </Col>
            <Col span={2}>
                <Divider />
            </Col>
        </Row>
    </Form>

}

export default GroupTasksFormComponent;