import {GeneratorRequest} from "./GeneratorRequest";

const nomApi = "group_tasks"

export const GroupTasksRepository = {
    delete: GeneratorRequest.delete(nomApi, true),
    put: GeneratorRequest.put(nomApi, true),
    post: GeneratorRequest.post(nomApi, true),
    gets: GeneratorRequest.gets(nomApi, true),
}
