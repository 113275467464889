import {NotificationSender} from "../utils/NotificationSender";
import Session from "../session/Session";
import {HourlyRatePriceRepository} from "../repositories/HourlyRatePriceRepository";

export const HourlyRatePriceService = {
    delete : (id, callback) => {
        Session.resetTimerBeforeLogout()
        HourlyRatePriceRepository.delete(id, (err) => {
            if (err) {
                let description = "Une erreur est survenue lors de la suppression du taux horaire"
                if (!!err.errors) {
                    description = err.errors.globals[0].message
                }
                NotificationSender.error("Suppression", description)
                callback(true)
            } else {
                NotificationSender.success("Suppression", "Le taux horaire à bien été supprimé")
                callback(false)
            }
        })
    },
    put: (hourlyRatePrice, callback) => {
        Session.resetTimerBeforeLogout()
        HourlyRatePriceRepository.put(hourlyRatePrice.id, hourlyRatePrice, (err, data) => {
            if (err) {
                if (!err.errors) {
                    NotificationSender.error("Sauvegarde", "Une erreur est survenue lors de la sauvegarde du taux horaire")
                }
                callback(err)
            } else {
                NotificationSender.success("Modification", "Le taux horaire à bien été enregistré")
                callback(false, data)
            }
        })
    },
    post: (hourlyRatePrice, callback) => {
        Session.resetTimerBeforeLogout()
        HourlyRatePriceRepository.post(hourlyRatePrice, (err, data) => {
            if (err) {
                if (!err.errors) {
                    NotificationSender.error("Sauvegarde", "Une erreur est survenue lors de la sauvegarde du taux horaire")
                }
                callback(err)
            } else {
                NotificationSender.success("Modification", "Le nouveaux taux horaire à bien été enregistré")
                callback(false, data)
            }
        })
    }
}
