import {Card, Col, Row, Skeleton} from "antd";

const WTDayCardLoading = ({width, height}) => {
    return <Col style={{width: width}}>
        <Card
            size="small"
            title={<Row gutter={5} align="middle"><Col span={12} style={{paddingTop: "0.5em"}}><Skeleton active paragraph={false}/></Col></Row>}
        >
            <Row>
                <Col span={24} style={{height: height}}>
                    {height === "1em" ? <Skeleton active paragraph={false}/> :
                        [<Skeleton active paragraph={false} style={{marginBottom:"0.3em"}} key="0"/>,
                        <Skeleton active paragraph={false} style={{marginBottom:"0.3em"}} key="1"/>,
                        <Skeleton active paragraph={false} style={{marginBottom:"0.3em"}} key="2"/>,
                        <Skeleton active paragraph={false} style={{marginBottom:"0.3em"}} key="3"/>]}
                </Col>
            </Row>
        </Card>
    </Col>
}

export default WTDayCardLoading