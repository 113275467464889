const url = "tempo-compta.fr";

const Environment = {
    dev: {
        back_url: "http://localhost"
    },
    prod: {
        back_url: "https://" + process.env.REACT_APP_SUBDOMAIN + "." + url
    },
    preprod: {
        back_url: "https://preprod." + url,
        info_balise: {
            color: "red",
            message: "Vous êtes sur la version de pré-production"
        }
    },
    demo: {
        back_url: "https://" + url,
        info_balise: {
            color: "green",
            message: "Vous êtes sur la version de démonstration"
        }
    }
}[process.env.REACT_APP_ENVIRONMENT || "dev"];

export default Environment;