import {Card, Col, Row} from "antd";
import {useState} from "react";
import FormMode from "../../../utils/FormMode";
import {TaskService} from "../../../services/TaskService";
import TaskReadingComponent from "./TaskReadingComponent";
import TaskFormComponent from "./TaskFormComponent";

const TaskComponent = ({task, removed, edited, saved}) => {

    const [mode, setMode] = useState(task.newEntity ? FormMode.EDITING : FormMode.READING);

    const edit = (newTask, callback) => {
        TaskService.put({...newTask, groupTaskId: task.groupTask.id}, (err, data) => {
            if (!err) {
                edited(data)
                setMode(FormMode.READING)
            } else {
                callback(err)
            }
        })
    }

    let content = <TaskReadingComponent
        task={task}
        removed={() => removed(task.id)}
        edit={edit}
        setMode={setMode}
    />

    if (mode === FormMode.EDITING) {
        content = <TaskFormComponent
            task={task}
            edit={edit}
            saved={saved}
            removed={() => removed(task.id)}
            setMode={setMode}
        />
    }

    return <Row>
        <Col span={24}>
            <Card
                bodyStyle={{padding: "0.65em 1em", color: (task.actif ? undefined : "lightgray")}}
                style={{width: "100%", borderRadius: "0.5em", marginBottom: "0.5em"}}>
                {content}
            </Card>
        </Col>
    </Row>

}

export default TaskComponent;