import {Button, Checkbox, Col, Form, Input, Row, Typography} from "antd";
import {LockOutlined, UserOutlined} from "@ant-design/icons";
import {useState} from "react";
import {AuthenticationService} from "../../../services/AuthenticationService";

const { Title } = Typography;

const LoginComponent = () => {
    const [globalsError, setGlobalsError] = useState([]);
    const [loadingButton, setLoadingButton] = useState(false);

    const [form] = Form.useForm();

    const onChange = () => {
        setGlobalsError([])
    }

    const onFinish = (values) => {
        setLoadingButton(true)
        AuthenticationService.login(values.username, values.password, values.save_token, (loginErr) => {
            if (loginErr) {
                if(!!loginErr?.errors?.globals) {
                    form.setFields([{name: "password", errors: [loginErr.errors.globals[0].message]}])
                }
                setLoadingButton(false)
            }
        })
    };

    return (
        <Row justify="center" align="middle" style={{height: "100%"}}>
            <Col span={9} style={{backgroundColor: "white", padding: "3em", borderRadius: "0.5em", maxWidth: "40em"}}>
                <Row style={{width: "100%"}}>
                    <Col span={24}>
                        <Title level={2}>Connexion</Title>
                        <Form
                            name="login"
                            form={form}
                            initialValues={{save_token: true}}
                            onFinish={onFinish}
                            onChange={onChange}
                        >
                            <Form.Item
                                name="username"
                                hasFeedback={false}
                                rules={[{required: true, message: "Identifiant à saisir"}]}
                            >
                                <Input prefix={<UserOutlined className="site-form-item-icon"/>}
                                       placeholder="Identifiant"/>
                            </Form.Item>
                            <Form.Item
                                name="password"
                                hasFeedback={false}
                                rules={[
                                    {
                                        required: true,
                                        message: "Mot de passe à saisir"
                                    }
                                ]}
                            >
                                <Input.Password
                                    prefix={<LockOutlined className="site-form-item-icon"/>}
                                    placeholder="Mot de passe"
                                />
                            </Form.Item>
                            <Form.Item>
                                <Form.Item name="save_token" valuePropName="checked" noStyle>
                                    <Checkbox>Se souvenir de moi</Checkbox>
                                </Form.Item>
                            </Form.Item>

                            <Form.Item>
                                <Row justify="center">
                                    <Button type="primary" htmlType="submit" style={{width: "75%"}} loading={loadingButton}>
                                        Connexion
                                    </Button>
                                </Row>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
                <Row style={{width: "100%"}} justify="center">
                    <Col>
                        <Button href="/mot_de_passe_oublie" type="link">Mot de passe oublié ?</Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default LoginComponent
