import AbstractEnumSelect from "../AbstractEnumSelect";

const AuthoritySelect = ({customOnChange, authorities, allowMultiple, disabled, value, onChange, loading}) => {

    return <AbstractEnumSelect
        onChange={customOnChange}
        enums={authorities}
        enumName="Statut"
        allowMultiple={allowMultiple}
        disabled={disabled}
        antValue={value}
        antOnChange={onChange}
        loading={loading}
    />;
}

export default AuthoritySelect;
