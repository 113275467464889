import {RoleRepository} from "../repositories/RoleRepository";
import {NotificationSender} from "../utils/NotificationSender";
import Session from "../session/Session";

export const RoleService = {
    gets : (callback) => {
        Session.resetTimerBeforeLogout()
        RoleRepository.gets(null, (err, data) => {
            if(err) {
                if(!err.errors) {
                    NotificationSender.error("Chargement", "Une erreur est survenue lors du chargement des rôles")
                }
                callback(err)
            } else {
                callback(false, data)
            }
        })
    },
    delete: (id, callback) => {
        Session.resetTimerBeforeLogout()
        RoleRepository.delete(id, (err) => {
            if (err) {
                let description = "Une erreur est survenue lors de la suppression du rôle"
                if (!!err.errors) {
                    description = err.errors.globals[0].message
                }
                NotificationSender.error("Suppression", description)
                callback(true)
            } else {
                NotificationSender.success("Suppression", "Le rôle à bien été supprimé")
                callback(false)
            }
        })
    },
    put: (role, callback) => {
        Session.resetTimerBeforeLogout()
        RoleRepository.put(role.id, role, (err, data) => {
            if (err) {
                if (!err.errors) {
                    NotificationSender.error("Sauvegarde", "Une erreur est survenue lors de la sauvegarde du rôle")
                }
                callback(err)
            } else {
                NotificationSender.success("Modification", "Le nouveaux rôle à bien été enregistré")
                callback(false, data)
            }
        })
    }
}
