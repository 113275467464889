import {GeneratorRequest} from "./GeneratorRequest";

const nomApi = "users"

export const UserRepository = {
    get : GeneratorRequest.get(nomApi, true),
    gets : GeneratorRequest.gets(nomApi, true),
    delete : GeneratorRequest.delete(nomApi, true),
    put : GeneratorRequest.put(nomApi, true),
    post : GeneratorRequest.post(nomApi, true),
    getUserDailyActivity : GeneratorRequest.gets(nomApi + "/daily_activity", true),
}
