import {AuthenticationRepository} from "../repositories/AuthenticationRepository";
import Cookie from "js-cookie";
import Session from "../session/Session";
import {NotificationSender} from "../utils/NotificationSender";
import {UserService} from "./UserService";
import {TaskService} from "./TaskService";
import {ClientService} from "./ClientService";

export const AuthenticationService = {
    login: (username, password, save_token, callback) => {
        AuthenticationRepository.login({
            username: username,
            password: password
        }, (err, data) => {
            if(err) {
                if(!err.errors) {
                    NotificationSender.error("Connexion", "Une erreur est survenue lors de la connexion")
                }
                callback(err)
            } else {
                Session.setToken(data.token)
                UserService.get(-1, (getErr, getRes) => {
                    if (getErr) {
                       callback(getErr)
                    } else {
                        if(save_token) {
                            Cookie.set("token", data.token, {secure: "false", expires: (data.duration/1000/60/60/24)})
                        }
                        Session.setUser(getRes)
                        callback(false)
                    }
                })
            }
        })
    },
    logout: () => {
        AuthenticationRepository.logout(null, (_, __) => {
            Cookie.remove('token')
            Session.clear()
            UserService.clearCache()
            TaskService.clearCache()
            ClientService.clearCache()
        })
    },
    reset_password: (token, username, password, callback) => {
        AuthenticationRepository.reset_password({token: token, username: username, password: password}, (err, data) => {
            if(err) {
                if(!err.errors) {
                    NotificationSender.error("Résiliation mot de passe", "Une erreur est survenue lors de l'enregistrement")
                }
                callback(err)
            } else {
                callback(false, data)
            }
        })
    },
    forgot_password: (username, callback) => {
        AuthenticationRepository.forgot_password({username: username}, (err, data) => {
            if (err) {
                if (!err.errors) {
                    NotificationSender.error("Mot de passe oublié", "Une erreur est survenue lors de l'envoie de la demande")
                }
                callback(err)
            } else {
                callback(false, data)
            }
        })
    }
}
