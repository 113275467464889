import {Button, Card, Col, DatePicker, Form, Row} from "antd";
import {DeleteOutlined, EyeInvisibleOutlined, EyeOutlined, LockOutlined, UnlockOutlined} from "@ant-design/icons";
import {useEffect, useState} from "react";

const FiscalYearForm = ({index, remove, formValidating, indexMissionLetter}) => {
    const form = Form.useFormInstance();

    const [actif, setActif] = useState(form.getFieldValue(["missionLetters", indexMissionLetter, "fiscalYears", index, "actif"]) !== false);

    useEffect(() => {
        form.setFieldValue(["missionLetters", indexMissionLetter, "fiscalYears", index, "actif"], actif)
    }, [actif])

    return <Card bodyStyle={{padding: "0.65em 1em"}} style={{width: "100%", borderRadius: "0.5em", marginBottom: "0.5em"}}>
        <Row>
            <Col span={20}>
                <Form.Item
                    style={{width: "100%", marginBottom: "0"}}
                    name={[index, "dates"]}
                    hasFeedback={form.isFieldValidating(["missionLetters", indexMissionLetter, "fiscalYears", index, "dates"])}
                >
                    <DatePicker.RangePicker
                        disabled={form.isFieldValidating(["missionLetters", indexMissionLetter, "fiscalYears", index, "dates"])}
                        picker="month"
                        style={{width: "100%"}}
                        allowClear={false}
                        format={(value) => {
                            let formatedDate = value.format("MMMM YYYY");
                            return formatedDate.charAt(0).toUpperCase() + formatedDate.slice(1)
                        }}
                    />
                </Form.Item>
            </Col>
            <Col span={4}>
                <Row justify="end">
                    <Col style={{marginRight: "0.5em"}}>
                        <Button
                            disabled={formValidating}
                            type={actif ? "primary" : "dashed" }
                            onClick={() => setActif(!actif)}
                            icon={actif ? <UnlockOutlined /> : <LockOutlined />}
                        />
                    </Col>
                    <Col>
                        <Button type={actif ? "primary" : "dashed" }
                                danger
                                disabled={formValidating || form.getFieldValue(["missionLetters", indexMissionLetter, "fiscalYears", index, "deletable"]) === false}
                                icon={<DeleteOutlined/>}
                                onClick={remove}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    </Card>
}

export default FiscalYearForm