import {useEffect, useRef, useState} from "react";
import {HourlyRateService} from "../../../services/HourlyRateService";
import {Affix, Button, Col, Form, Input, InputNumber, Row} from "antd";
import CompanyTypesRow from "./CompanyTypesRow";
import RolesColumn from "./RolesColumn";
import HourlyRatesPriceTable from "./HourlyRatesPriceTable";
import {DeleteOutlined, PlusOutlined, SaveOutlined} from "@ant-design/icons";
import Cell from "../../../utils/table/Cell";

const HourlyRateTable = () => {

    const ref = useRef(null);

    const [companyTypes, setCompanyTypes] = useState([]);
    const [companyTypesOrder, setCompanyTypesOrder] = useState(null);
    const [roles, setRoles] = useState([]);
    const [rolesOrder, setRolesOrder] = useState(null);
    const [hourlyRates, setHourlyRates] = useState([]);
    const [sortedHourlyRates, setSortedHourlyRates] = useState([]);
    const [refreshHourlyRates, setRefreshHourlyRates] = useState(false);

    const [showAddRoleRow, setShowAddRoleRow] = useState(false);
    const [showAddCompanyTypeColumn, setShowAddCompanyTypeColumn] = useState(false);

    const [form] = Form.useForm();

    useEffect(() => {
        HourlyRateService.gets((err, data) => {
            setHourlyRates(data);
            setRefreshHourlyRates(true);
        })
    }, []);

    useEffect(() => {
        if (refreshHourlyRates) {
            setRefreshHourlyRates(false);
            setCompanyTypes(Object.values(hourlyRates.reduce((acc, hourlyRate) => ({
                ...acc,
                [hourlyRate.companyType.id]: {
                    ...hourlyRate.companyType,
                    sumBillable: (acc[hourlyRate.companyType.id] ? acc[hourlyRate.companyType.id].sumBillable : 0) + hourlyRate.currentBillablePrice,
                }
            }), {})).sort((a, b) => b.sumBillable - a.sumBillable)
                .map((companyType, index) => ({...companyType, index: index})))
            setRoles(Object.values(hourlyRates.reduce((acc, hourlyRate) => ({
                ...acc,
                [hourlyRate.role.id]: {
                    ...hourlyRate.role,
                    sumBillable: (acc[hourlyRate.role.id] ? acc[hourlyRate.role.id].sumBillable : 0) + hourlyRate.currentBillablePrice,
                }
            }), {})).sort((a, b) => b.sumBillable - a.sumBillable)
                .map((role, index) => ({...role, index: index})))
        }
    }, [refreshHourlyRates]);

    useEffect(() => {
        if (companyTypes.length > 0) {
            setCompanyTypesOrder(companyTypes.reduce((acc, companyType) => ({
                ...acc,
                [companyType.id]: companyType.index
            }), {}))
        }
    }, [companyTypes]);

    useEffect(() => {
        if (roles.length > 0) {
            setRolesOrder(roles.reduce((acc, role) => ({
                ...acc,
                [role.id]: role.index
            }), {}))
        }
    }, [roles]);

    useEffect(() => {
        if (companyTypesOrder && rolesOrder) {
            setSortedHourlyRates(hourlyRates.reduce((acc, hourlyRate) => {
                acc[rolesOrder[hourlyRate.role.id]][companyTypesOrder[hourlyRate.companyType.id]] = hourlyRate;
                return acc;
            }, Array.from({length: roles.length}, () => Array.from({length: companyTypes.length}))))
        }
    }, [companyTypesOrder, rolesOrder]);

    const create = (values) => {
        if (showAddRoleRow) {
            HourlyRateService.postRole(Object.keys(values)
                .filter(key => key !== "name")
                .map(key => ({
                    companyType: {id: parseInt(key)},
                    role: {name: values.name},
                    currentBillablePrice: values[key] * 100
                })), (err, data) => {
                if (!err) {
                    setHourlyRates([...hourlyRates, ...data]);
                    setRefreshHourlyRates(true);
                    setShowAddRoleRow(false);
                    setRoles([...roles, {
                        ...data[0].role,
                        index: roles.length,
                    }]);
                    form.resetFields();
                } else if (err.errors?.fields) {
                    form.setFields([{name: err.errors.fields[0].field, errors: [err.errors.fields[0].message]}])
                }
            });
        } else if (showAddCompanyTypeColumn) {
            HourlyRateService.postCompanyType(Object.keys(values)
                .filter(key => key !== "name")
                .map(key => ({
                    role: {id: parseInt(key)},
                    companyType: {name: values.name},
                    currentBillablePrice: values[key] * 100
                })), (err, data) => {
                if (!err) {
                    setHourlyRates([...hourlyRates, ...data]);
                    setRefreshHourlyRates(true);
                    setShowAddCompanyTypeColumn(false);
                    setCompanyTypes([...companyTypes, {
                        ...data[0].companyType,
                        index: companyTypes.length,
                    }]);
                    form.resetFields();
                } else if (err.errors?.fields) {
                    form.setFields([{name: err.errors.fields[0].field, errors: [err.errors.fields[0].message]}])
                }
            });
        }
    }

    const onDeleteRole = (id) => {
        setRoles(roles.filter(role => role.id !== id).map(role => ({
            ...role,
            index: role.index > rolesOrder[id] ? role.index - 1 : role.index
        })));
        setHourlyRates(hourlyRates.filter(hourlyRate => hourlyRate.role.id !== id));
        setRefreshHourlyRates(true);
    }

    const onDeleteCompanyType = (id) => {
        setCompanyTypes(companyTypes.filter(companyType => companyType.id !== id).map(companyType => ({
            ...companyType,
            index: companyType.index > companyTypesOrder[id] ? companyType.index - 1 : companyType.index
        })));
        setHourlyRates(hourlyRates.filter(hourlyRate => hourlyRate.companyType.id !== id));
        setRefreshHourlyRates(true);
    }

    return (
        <Row style={{width: "100%", maxWidth: "100%", display: "inline-block"}}>
            <Form
                form={form}
                onFinish={showAddRoleRow || showAddCompanyTypeColumn ? (values) => create(values) : undefined}
            >
                <Col span={24}>
                    <Row style={{width: "100%", maxWidth: "100%"}} wrap={false}>
                        <Col span={5}>
                            <Row style={{height: "4em"}}></Row>
                            <Row style={{borderBottom: "1px solid #F0F0F0", borderRadius: "0 0 0 0.5em"}}>
                                <RolesColumn
                                    roles={roles}
                                    showAddRoleRow={showAddRoleRow}
                                    onDelete={onDeleteRole}
                                />
                            </Row>
                        </Col>
                        <Col span={showAddCompanyTypeColumn ? 15 : 18} style={{
                            overflowX: "auto",
                            whiteSpace: "nowrap",
                            border: "1px solid #F0F0F0",
                            borderRadius: (showAddCompanyTypeColumn ? "0.5em 0 0 0" : "0.5em 0.5em 0 0"),
                            borderBottom: "none"
                        }}>
                            <Row>
                                <CompanyTypesRow
                                    companyTypes={companyTypes}
                                    onDelete={onDeleteCompanyType}
                                    showAddCompanyTypeColumn={showAddCompanyTypeColumn}
                                />
                            </Row>
                            <Row>
                                <HourlyRatesPriceTable
                                    hourlyRatesTable={sortedHourlyRates}
                                    companyTypes={companyTypes}
                                    showAddRoleRow={showAddRoleRow}
                                    showAddCompanyTypeColumn={showAddCompanyTypeColumn}
                                />
                            </Row>
                        </Col>
                        {showAddCompanyTypeColumn && <Col span={3}>
                            <Row style={{width: "100%"}}>
                                <Cell
                                    style={{
                                        fontWeight: "bold",
                                        padding: "0 1em",
                                        backgroundColor: "#FAFAFA",
                                        borderRadius: "0 0.5em 0 0",
                                        borderTop: "1px solid #F0F0F0",
                                        borderRight: "1px solid #F0F0F0",
                                    }}
                                    editInput={<Form.Item
                                        name="name"
                                        style={{margin: 0}}
                                        rules={[{required: true, message: "Doit être saisie"}]}
                                    ><Input autoFocus placeholder="Type d'organisation"/></Form.Item>}
                                    createMode={true}
                                />
                            </Row>
                            {roles.map((role, index) => (
                                <Row key={role.id} style={{width: "100%"}}>
                                    <Cell
                                        style={{
                                            padding: "0 1em",
                                            borderBottom: "1px solid #F0F0F0",
                                            borderTop: index === 0 ? "1px solid #F0F0F0" : "none",
                                            borderRight: "1px solid #F0F0F0",
                                        }}
                                        editInput={<Form.Item
                                            name={Object.keys(rolesOrder).filter(key => index === rolesOrder[key]).toString()}
                                            style={{margin: 0}}
                                            rules={[{required: true, message: "Doit être saisie"}]}
                                        ><InputNumber
                                            style={{width: "100%"}}
                                            placeholder="0,00"
                                            addonAfter="€"
                                            min="0"
                                            decimalSeparator=","
                                            precision={2}
                                            step={10}
                                        /></Form.Item>}
                                        createMode={true}
                                    />
                                </Row>
                            ))}
                        </Col>}
                        <Col span={1}>
                            {showAddCompanyTypeColumn ? <Row style={{height: "100%"}}>
                                <Col span={24} style={{marginLeft: "1em"}}>
                                    <Row style={{height: "50%", paddingBottom: "0.3em"}}>
                                        <Button
                                            style={{height: "100%", width: "2.5em"}}
                                            block
                                            icon={<SaveOutlined/>}
                                            type="primary"
                                            htmlType="submit"
                                        />
                                    </Row>
                                    <Row style={{height: "50%", paddingBottom: "0.3em"}}>
                                        <Button
                                            style={{height: "100%", width: "2.5em"}}
                                            block
                                            icon={<DeleteOutlined/>}
                                            type="primary"
                                            onClick={() => {
                                                form.resetFields();
                                                setShowAddCompanyTypeColumn(false)
                                            }}
                                            danger/>
                                    </Row>
                                </Col>
                            </Row> : <Button
                                disabled={showAddRoleRow}
                                style={{height: "100%", width: "2.5em", marginLeft: "1em"}}
                                block
                                icon={<PlusOutlined/>}
                                onClick={() => {
                                    setShowAddCompanyTypeColumn(true);
                                }}
                            />}
                        </Col>
                    </Row>
                    <Row style={{width: "100%", paddingTop: "0.5em"}} ref={ref} wrap={false}>
                        <Col span={23}>
                            {showAddRoleRow ?
                                <Row gutter={6}>
                                    <Col span={12}>
                                        <Button
                                            block
                                            icon={<SaveOutlined/>}
                                            type="primary"
                                            htmlType="submit"
                                        >
                                            Enregistrer
                                        </Button>
                                    </Col>
                                    <Col span={12}>
                                        <Button
                                            block
                                            icon={<DeleteOutlined/>}
                                            type="primary"
                                            onClick={() => {
                                                form.resetFields();
                                                setShowAddRoleRow(false)
                                            }}
                                            danger>
                                            Annuler
                                        </Button>
                                    </Col>
                                </Row> :
                                <Affix offsetBottom={55} style={{width: "100%"}}>
                                    <Button
                                        block
                                        disabled={showAddCompanyTypeColumn}
                                        icon={<PlusOutlined/>}
                                        onClick={() => {
                                            setShowAddRoleRow(true);
                                            ref.current.scrollIntoView();
                                        }}
                                    >
                                        Ajouter un rôle
                                    </Button>
                                </Affix>}
                        </Col>
                        <Col span={1}></Col>
                    </Row>
                </Col>
            </Form>
        </Row>

    );

}

export default HourlyRateTable;