import {Button, Col, DatePicker, Popover, Row, Tooltip} from "antd";
import {FileProtectOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import {useEffect, useState} from "react";
import {ArchivingService} from "../../services/ArchivingService";

const WorkTimesArchivingButton = ({endFilterDate, validating, setArchivedDate, archivedDate}) => {
    const [open, setOpen] = useState(false);
    const [archivingDate, setArchivingDate] = useState(endFilterDate);

    useEffect(() => {
        setArchivingDate(endFilterDate)
    }, [endFilterDate]);

    const confirm = () => {
        ArchivingService.post(archivingDate.format("YYYY-MM-DD"), (err) => {
            if (!err) {
                setOpen(false)
                setArchivedDate(archivingDate)
            }
        })
    }

    return <Popover placement="bottomLeft" trigger="click" open={open} onOpenChange={setOpen}
                    content={<div>
                        <Row style={{marginBottom: "0.5em"}}>
                            <Col span={24}>
                                <Row gutter={4}>
                                    <Col>
                                        Modifier la date de clôture au
                                    </Col>
                                    <Col>
                                        <DatePicker
                                            defaultValue={archivingDate}
                                            onChange={setArchivingDate}
                                            size="small"
                                            format="D MMM YYYY"
                                            allowClear={false}/>
                                    </Col>
                                    <Col>
                                        <Tooltip placement="topRight"
                                                 title={<>Plus aucun temps de travail ne pourra être ajouté, modifié ou supprimé par les collaborateurs avant cette date incluse.<br/> Cela permet aussi de bloquer le prix facturable des temps de travail saisis.</>}
                                        >
                                            <QuestionCircleOutlined />
                                        </Tooltip>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row justify="end">
                            <Col span={8} style={{marginRight: "0.5em"}}>
                                <Button style={{width: "100%"}} type="primary"
                                        onClick={() => {
                                            setOpen(false)
                                            confirm()
                                        }}>Enregistrer</Button>
                            </Col>
                            <Col span={8}>
                                <Button style={{width: "100%"}} type="primary" danger
                                        onClick={() => setOpen(false)}>Annuler</Button>
                            </Col>
                        </Row>
                        <Row style={{color: "gray", textAlign: "end"}}>
                            <Col span={24}>
                                {archivedDate ? `Clôture actuel : ${archivedDate.format("D MMM YYYY")}` : "Aucune date de clôture enregistrée"}
                            </Col>
                        </Row>
                    </div>}>
        <Button style={{width: "100%"}}
                loading={validating}
                icon={<FileProtectOutlined/>}>
            Clôturer
        </Button>
    </Popover>
}

export default WorkTimesArchivingButton;