const PICKERS = {
    DATE: {
        label: "Dates",
        picker: "date",
        onChange: (period) => period
    },
    MONTH: {
        label: "Mois",
        picker: "month",
        onChange: (period) => period ? [period[0]?.startOf("month"), period[1]?.endOf("month")] : period
    },
    YEAR: {
        label: "Années",
        picker: "year",
        onChange: (period) => period ? [period[0]?.startOf("year"), period[1]?.endOf("year")] : period
    }
}

export default PICKERS;