import {Button, Col, Form, InputNumber, Row} from "antd";
import {CloseOutlined, SaveOutlined} from "@ant-design/icons";
import {useState} from "react";
import dayjs from "dayjs";
import {HourlyRatePriceService} from "../../../services/HourlyRatePriceService";
import CustomDatePicker from "../../../utils/form/datePicker/CustomDatePicker";

const hourlyRatePriceEquals = (edit, form) => {
    return edit.currentBillablePrice === form.currentBillablePrice
        && edit.applicable === form.applicable;
}

const HourlyRatePriceForm = ({saved, remove, hourlyRatePrice, hourlyRateId}) => {
    const [form] = Form.useForm();

    const [validating, setValidating] = useState(false);

    const onFinish = (values) => {
        setValidating(true);
        form.setFields(Object.keys(values).map(key => ({
            name: key,
            validating: true
        })))
        let request = HourlyRatePriceService.post;
        values.currentBillablePrice *= 100;
        values.applicable = values.applicable === undefined ? null : values.applicable.format("YYYY-MM-DD");
        if (!!hourlyRatePrice) {
            if (!form.isFieldsTouched() || hourlyRatePriceEquals(hourlyRatePrice, values)) {
                saved(hourlyRatePrice);
                return;
            }
            request = HourlyRatePriceService.put;
        }
        request({
            id: hourlyRatePrice?.id,
            hourlyRateId,
            ...values
        }, (err, data) => {
            if (err?.errors) {
                form.setFields(err.errors.fields.map(field => {
                    return {
                        name: field.field,
                        errors: [field.message]
                    }
                }));
            } else {
                saved(data);
            }
            setValidating(false);
        })
    }

    return <Form
        style={{marginTop: "0"}}
        name="hourlyRatePriceForm"
        onFinish={onFinish}
        form={form}
        layout="horizontal"
        initialValues={!!hourlyRatePrice ?
            {
                applicable: dayjs(hourlyRatePrice.applicable),
                currentBillablePrice: hourlyRatePrice.currentBillablePrice / 100
            }
            : {
                applicable: dayjs()
            }}
    >
        <Row>
            <Col style={{paddingRight: "0.5em", width: "30%"}}>
                <Row justify="center">
                    <Form.Item
                        style={{width: "100%", marginBottom: "0"}}
                        name="currentBillablePrice"
                        hasFeedback={validating}
                        rules={[{required: true, message: "Taux horaire à saisir"}]}
                    >
                        <InputNumber
                            disabled={validating}
                            style={{width: "100%"}}
                            placeholder="0,00"
                            addonAfter="€"
                            min="0"
                            decimalSeparator=","
                            precision={2}
                            step={10}
                        />
                    </Form.Item>
                </Row>
            </Col>
            <Col style={{paddingRight: "0.5em", width: "54%"}}>
                <Row align="middle">
                    {hourlyRatePrice?.applicable === null ?
                        <Col style={{paddingTop: "0.3em"}}>depuis la nuit des temps ;)</Col> :
                        <Col span={24}>
                            <Row gutter={8} align="middle">
                                <Col>à partir du </Col>
                                <Col>
                                    <Form.Item
                                        style={{width: "100%", marginBottom: 0}}
                                        name="applicable"
                                        hasFeedback={validating}
                                        rules={[{required: true, message: "Date d'application à saisir"}]}
                                    >
                                        <CustomDatePicker
                                            disabled={validating}
                                            format="D MMM YYYY"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>}
                </Row>
            </Col>
            <Col style={{width: "16%"}}>
                <Row justify="end">
                    <Col style={{marginRight: "0.5em"}}>
                        <Button type="primary" htmlType="submit"
                                icon={<SaveOutlined/>}
                        />
                    </Col>
                    <Col>
                        <Button
                            type="primary"
                            danger
                            icon={<CloseOutlined/>}
                            onClick={remove}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    </Form>
}

export default HourlyRatePriceForm;