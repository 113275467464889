import {Layout, Menu} from "antd";
import {Link, Route, Routes} from "react-router-dom";
import {useState} from "react";
import Session from "../../session/Session";
import ErrorPathComponent from "../error/ErrorPathComponent";
import {Content} from "antd/es/layout/layout";
import ClientPageComponent from "./clients/ClientPageComponent";
import HourlyRatePageComponent from "./hourlyRates/HourlyRatePageComponent";
import TasksPageComponent from "./tasks/TasksPageComponent";
import UserPageComponent from "./users/UserPageComponent";

const AdministrationMenuComponent = () => {
    const urlToKey = [
        {
            path: "/administration/clients",
            key: "clients"
        },
        {
            path: "/administration/taux_horaire",
            key: "hourlyRates"
        },
        {
            path: "/administration/taches",
            key: "tasks"
        },
        {
            path: "/administration/utilisateurs",
            key: "users"
        }
    ]

    const [current, setCurrent] = useState(urlToKey.find((urlToKey) => window.location.pathname.startsWith(urlToKey.path))?.key || "clients");

    const onClick = e => {
        setCurrent(e.key);
    };

    return <Layout style={{marginTop: "4.6em"}}>
        <Menu
            selectedKeys={[current]}
            onClick={onClick}
            theme="light"
            mode="horizontal"
            style={{
                lineHeight: "64px",
                width: "100%",
                position: "fixed",
                zIndex: 1,
                paddingLeft: "2em",
                paddingRight: "2em"
            }}
            items={[
                {
                    key: "clients",
                    label: (<Link to="/administration/clients">Clients</Link>)
                },
                {
                    key: "hourlyRates",
                    label: (<Link to="/administration/taux_horaire">Taux horaire</Link>)
                },
                {
                    key: "tasks",
                    label: (<Link to="/administration/taches">Tâches</Link>)
                },
                {
                    key: "users",
                    label: (<Link to="/administration/utilisateurs">Utilisateurs</Link>)
                }
            ]}
        />
        <Content>
            <Routes>
                {!Session.isCollaborateur() && <Route path="/clients" element={<ClientPageComponent/>}/>}
                {!Session.isCollaborateur() && <Route path="/taux_horaire" element={<HourlyRatePageComponent/>}/>}
                {!Session.isCollaborateur() && <Route path="/taches" element={<TasksPageComponent/>}/>}
                {!Session.isCollaborateur() && <Route path="/utilisateurs" element={<UserPageComponent/>}/>}
                <Route path="*" element={<ErrorPathComponent/>}/>
            </Routes>
        </Content>
    </Layout>
}

export default AdministrationMenuComponent