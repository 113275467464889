import {List} from "antd";
import WTDayCard from "./WTDayCard";
import WTDayCardLoading from "./WTDayCardLoading";

const WTCalandarWeekStart = ({ workTimesByDays, filters, archivedDate, setWorkTimeModalDate }) => {

    return <List
            split={false}
            grid={{gutter: 10, column: 5}}
            dataSource={workTimesByDays}
            renderItem={day => {
                return <List.Item key={day.date.format("YYYY-MM-DD")}>
                    {day.loading ? <WTDayCardLoading height="7em"/> :<WTDayCard
                        height={"7em"}
                        date={day.date}
                        workTimes={day.workTimes}
                        filters={filters}
                        disabled={day.disabled}
                        archivedDate={archivedDate}
                        setWorkTimeModalDate={setWorkTimeModalDate}
                    />}
                </List.Item>
            }}
        />
}

export default WTCalandarWeekStart;