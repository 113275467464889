import {Card, Col, Divider, Row} from "antd";
import {MilliFormater} from "../../utils/MilliFormater";
import {PriceFormater} from "../../utils/PriceFormater";

const TotalResultCard = ({billedPrice, billablePrice, billedDuration, billableDuration, unShownBilled}) => {
    let unShownBilledPrice = unShownBilled ? unShownBilled.billedPrice : 0;
    let unShownBilledDuration = unShownBilled ? unShownBilled.billedDuration : 0;

    let priceGain = (billedPrice + unShownBilledPrice) - billablePrice;
    let durationGain = (billedDuration + unShownBilledDuration) - billableDuration;

    return <Card style={{paddingRight: 0, borderRadius: "0 8px 8px 0", borderLeftColor: "white", height: "10em",}}>
        <Row>
            <Col span={10} style={{textAlign: "end"}}>
                {PriceFormater.priceToEuro(billablePrice)}
            </Col>
            <Col span={4} style={{paddingLeft: "0.5em", textAlign: "end", color: "lightgray"}}>
                pour
            </Col>
            <Col span={10} style={{textAlign: "end"}}>
                {MilliFormater.minToHoursMinutes(billableDuration)}
            </Col>
        </Row>
        <Row>
            <Col span={10} style={{textAlign: "end"}}>
                {PriceFormater.priceToEuro(billedPrice + unShownBilledPrice)}
            </Col>
            <Col span={4} style={{paddingLeft: "0.5em", textAlign: "end", color: "lightgray"}}>
                pour
            </Col>
            <Col span={10} style={{textAlign: "end"}}>
                {MilliFormater.minToHoursMinutes(billedDuration + unShownBilledDuration)}
            </Col>
        </Row>
        <Divider style={{marginTop: "12px", marginBottom: "12px"}} />
        <Row>
            <Col span={10} style={{textAlign: "end", color: (priceGain > 0 ? "darkgreen" : "red")}}>
                {PriceFormater.priceToEuro(priceGain, true)}
            </Col>
            <Col span={14} style={{textAlign: "end", color: (durationGain > 0 ? "darkgreen" : "red")}}>
                {MilliFormater.minToHoursMinutes(durationGain, true)}
            </Col>
        </Row>
    </Card>
}

export default TotalResultCard;