import {Button, Col, List, Modal, Row} from "antd";
import {useEffect, useState} from "react";
import FormMode from "../../../utils/FormMode";
import HourlyRatePriceComponent from "./HourlyRatePriceComponent";
import {PlusOutlined} from "@ant-design/icons";
import {getCurrentBillablePrice, sortHourlyRatePrices} from "../../../services/HourlyRateService";

const HourlyRatePriceModal = ({hourlyRate, setShowEdit}) => {

    const [hourlyRatePrices, setHourlyRatePrices] = useState([])
    const [hourlyRatePricesCreating, setHourlyRatePricesCreating] = useState([]);

    const addCreatingHourlyRatePrice = () => {
        setHourlyRatePricesCreating([
            ...hourlyRatePricesCreating,
            {
                id: Math.random(),
                mode: FormMode.CREATING
            }
        ])
    }

    useEffect(() => {
        if (hourlyRatePrices.length > 0) {
            hourlyRate.hourlyRatePrices = sortHourlyRatePrices(hourlyRatePrices)
            hourlyRate.currentBillablePrice = getCurrentBillablePrice(hourlyRate)
        }
    }, [hourlyRatePrices]);

    useEffect(() => {
        setHourlyRatePrices(hourlyRate.hourlyRatePrices.map(hourlyRatePrice => ({
            ...hourlyRatePrice,
            mode: FormMode.READING
        })))
        addCreatingHourlyRatePrice()
    }, []);

    const addHourlyRatePrice = (newHourlyRatePrice) => {
        setHourlyRatePricesCreating(hourlyRatePricesCreating.filter(hourlyRatePrice => hourlyRatePrice.id !== newHourlyRatePrice.addingId));
        setHourlyRatePrices(sortHourlyRatePrices([...hourlyRatePrices, newHourlyRatePrice]));
    }

    const removeHourlyRatePriceFromId = (id) => {
        setHourlyRatePrices(hourlyRatePrices.filter(hourlyRatePrice => hourlyRatePrice.id !== id));
    }

    const removeEditingHourlyRatePriceFromId = (id) => {
        setHourlyRatePricesCreating(hourlyRatePricesCreating.filter(hourlyRatePrice => hourlyRatePrice.id !== id));
    }

    const editHourlyRatePrice = (hourlyRatePrice) => {
        setHourlyRatePrices(sortHourlyRatePrices(hourlyRatePrices.map(hrp => hrp.id === hourlyRatePrice.id ? hourlyRatePrice : hrp)));
    }

    return <Modal open
                  onCancel={() => setShowEdit(false)}
                  title={"Taux horaire des " + hourlyRate.role.name + " pour un/une " + hourlyRate.companyType.name}
                  footer={null}
                  width={600}
                  centered>
        <Row style={{marginBottom: "0.5em"}}>
            <Col span={24}>
                <Button block onClick={addCreatingHourlyRatePrice} icon={<PlusOutlined/>}>
                    Ajouter un taux horaire
                </Button>
            </Col>
        </Row>
        <Row>
            {hourlyRatePricesCreating.length ? <List
                style={{width: "100%"}}
                split={false}
                itemLayout="vertical"
                dataSource={hourlyRatePricesCreating}
                renderItem={hourlyRatePrice => <HourlyRatePriceComponent
                    key={hourlyRatePrice.id}
                    mode={hourlyRatePrice.mode}
                    hourlyRatePrice={hourlyRatePrice}
                    hourlyRateId={hourlyRate.id}
                    remove={removeEditingHourlyRatePriceFromId}
                    add={addHourlyRatePrice}
                />}
            /> : null}
            <List
                style={{width: "100%"}}
                split={false}
                itemLayout="vertical"
                dataSource={hourlyRatePrices}
                renderItem={hourlyRatePrice => (
                    <HourlyRatePriceComponent
                        key={hourlyRatePrice.id}
                        hourlyRatePrice={hourlyRatePrice}
                        mode={hourlyRatePrice.mode}
                        hourlyRateId={hourlyRate.id}
                        remove={removeHourlyRatePriceFromId}
                        edit={editHourlyRatePrice}
                        add={addHourlyRatePrice}
                    />
                )}
            />
        </Row>
    </Modal>
}

export default HourlyRatePriceModal;