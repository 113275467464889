import {Button, Col, ConfigProvider, Empty, List, Modal, Row, Typography} from "antd";
import {useEffect, useState} from "react";
import Session from "../../session/Session";
import WorkTimeComponent from "../../component/workTimes/WorkTimeComponent";
import {LockOutlined, PlusOutlined} from "@ant-design/icons";
import FormMode from "../FormMode";
import {WorkTimeService} from "../../services/WorkTimeService";
import MaxDurationTag from "./MaxDurationTag";
import {UserService} from "../../services/UserService";
import UserAuthority from "../UserAuthority";

const WTListModal = ({close, filters, date, editedWorkTimes, archivedDate}) => {

    const [title, setTitle] = useState("")
    const [workTimeList, setWorkTimeList] = useState([]);
    const [workTimesCreating, setWorkTimesCreating] = useState([]);

    const [isArchived, setIsArchived] = useState(false);

    const [loading, setLoading] = useState(true)
    const [modalInit, setModalInit] = useState(false)

    const [users, setUsers] = useState([])

    useEffect(() => {
        if (archivedDate) {
            setIsArchived(archivedDate.isSame(date, "day") || archivedDate.isAfter(date, "day"))
        }
    }, [archivedDate]);

    useEffect(() => {
        WorkTimeService.gets({
            after: date.format("YYYY-MM-DD"),
            before: date.format("YYYY-MM-DD"),
            users_id: filters.users[0].id === "all" ? undefined : filters.users.map(user => user.id).join(","),
            clients_id: filters.clients[0].id === "all" ? undefined : filters.clients.map(client => client.id).join(","),
        }, (err, data) => {
            if (!err) {
                setWorkTimeList(data.map((workTime) => ({
                    ...workTime,
                    mode: FormMode.READING
                })))
            }
            setLoading(false)
        })
    }, []);

    useEffect(() => {
        if (filters.users.length === 1 && filters.users[0].id === "all") {
            UserService.gets({authority_ids: [UserAuthority.ASSOCIE.id, UserAuthority.COLLABORATEUR.id]}, (err, data) => {
                if (!err) {
                    setUsers(data);
                }
            })
        } else {
            setUsers(filters.users);
        }
    }, [filters.users]);

    useEffect(() => {
        if (!loading && !modalInit) {
            let dayOfWeek = date.weekday();
            let anyUserDayNotCompleted = users.findIndex((user) => {
                let durationSum = workTimeList?.filter(wt => wt.user.id === user.id).reduce((a, b) => a + b.duration, 0)
                let maxDayWorkingDuration = user.workLimits.find(workLimit => workLimit.dayOfWeek === dayOfWeek)?.maxWorkDuration
                return maxDayWorkingDuration === -1 || durationSum < maxDayWorkingDuration
            }) !== -1;
            if (anyUserDayNotCompleted && (!Session.isCollaborateur() || !isArchived)) {
                addCreatingWorkTime();
            }
            setModalInit(true);
        }
    }, [loading, filters, date, isArchived, workTimeList, modalInit, users]);

    useEffect(() => {
        if (!loading) {
            editedWorkTimes(date, workTimeList);
        }
    }, [workTimeList, loading]);

    const addCreatingWorkTime = () => {
        setWorkTimesCreating([
            ...workTimesCreating,
            {
                id: Math.random(),
                mode: FormMode.CREATING
            }
        ])
    }

    useEffect(() => {
        let tempsDeTravail = " temps de travail";
        let prefixe = "Les";
        let postfixeUser = "";
        let postfixeClient = "";
        let postFixeDate = " du " + date.format("D MMMM");
        if (!!filters.users) {
            if (filters.users.length === 1 && filters.users[0].id === Session.user.id) {
                prefixe = "Vos";
            } else {
                postfixeUser = " de " + filters.users.map(user => user.firstName + " " + user.lastName).join(", ");
            }
        }
        if (!!filters.clients && filters.clients[0].id !== "all") {
            postfixeClient = " pour " + filters.clients.map(client => client.name).join(", ");
        }
        if (!filters.users && !filters.clients) {
            prefixe = "Tous les";
        }
        return setTitle(prefixe + tempsDeTravail + postfixeUser + postfixeClient + postFixeDate);
    }, [filters.clients, filters.users])

    const addWorkTime = (newWorkTime) => {
        setWorkTimesCreating(workTimesCreating.filter(workTime => workTime.id !== newWorkTime.addingId));
        setWorkTimeList([...workTimeList, newWorkTime]);
    }

    const removeWorkTimeFromId = (id) => {
        setWorkTimeList(workTimeList.filter(workTime => workTime.id !== id));
    }

    const removeEditingWorkTimeFromId = (id) => {
        setWorkTimesCreating(workTimesCreating.filter(workTime => workTime.id !== id));
    }

    const editWorkTime = (workTime) => {
        setWorkTimeList(workTimeList.map(wt => wt.id === workTime.id ? workTime : wt));
    }

    let dayOfWeek = date.weekday();
    let showAddButton  = users.findIndex((user) => {
        let maxDayWorkingDuration = user.workLimits.find(workLimit => workLimit.dayOfWeek === dayOfWeek)?.maxWorkDuration
        return maxDayWorkingDuration !== 0
    }) !== -1

    return <Modal
        open
        onOk={close}
        onCancel={close}
        title={<Row gutter={6} >
            <Col>
                <Typography.Title level={4}>{title}</Typography.Title>
            </Col>
            <Col style={{margin: "0.3em 0 0 0"}}>
                <MaxDurationTag date={date} workTimes={workTimeList} filters={filters}/>
            </Col>
            <Col style={{margin: "0.3em 0 0 0"}}>
                {isArchived && <LockOutlined />}
            </Col>
        </Row>}
        footer={null}
        width={1000}
        centered
    >
        <Row style={{marginBottom: "0.5em"}}>
            <Col span={24}>
                {Session.isCollaborateur() && !showAddButton ? null :
                    <Button block onClick={addCreatingWorkTime} icon={<PlusOutlined/>} disabled={Session.isCollaborateur() && isArchived}>
                        Ajouter un temps de travail
                    </Button>
                }
            </Col>
        </Row>
        <Row>
            {workTimesCreating.length ? <List
                style={{width: "100%"}}
                split={false}
                itemLayout="vertical"
                dataSource={workTimesCreating}
                renderItem={workTime => <WorkTimeComponent
                    filters={filters}
                    archivedDate={isArchived}
                    date={date}
                    key={workTime.id}
                    mode={workTime.mode}
                    workTime={workTime}
                    remove={removeEditingWorkTimeFromId}
                    addedWorkTime={addWorkTime}
                />}
            /> : null}
            <ConfigProvider renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Aucun temps de travail enregistrés"/>}>
                <List
                    style={{width: "100%"}}
                    split={false}
                    itemLayout="vertical"
                    dataSource={workTimeList}
                    renderItem={workTime => <WorkTimeComponent
                        filters={filters}
                        archivedDate={isArchived}
                        date={date}
                        key={workTime.id}
                        mode={workTime.mode}
                        workTime={workTime}
                        remove={removeWorkTimeFromId}
                        editWorkTime={editWorkTime}
                        addedWorkTime={addWorkTime}
                    />}
                />
            </ConfigProvider>
        </Row>
    </Modal>
}

export default WTListModal;