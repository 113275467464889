import dayjs from "dayjs";

var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

export const MilliFormater = {
    milliToHoursMinutes: (milli) => {
        let milliMinute = milli % 3600000;
        let milliHour = milli - milliMinute;
        let hour = milliHour / 3600000;
        let minute = Math.round(milliMinute / 60000);
        return hour + " h " + (minute < 10 ? "0" : "") + minute + " min";
    },
    minToHoursMinutes: (min, sign = false) => {
        let signString = !sign || min === 0 ? '' : min > 0 ? '+ ' : '- ';
        min = Math.abs(min);
        let minute = min % 60;
        let hour = (min - minute) / 60;
        return signString + hour.toLocaleString() + "h" + (minute < 10 ? "0" : "") + minute;
    },
    dateToHoursMinutes: (date) => {
        return MilliFormater.milliToHoursMinutes(MilliFormater.hourDurationToMilli(date))
    },
    simpleHourDate: (milli) => {
        return dayjs.unix(milli / 1000).utc().format("H[h]mm")
    },
    minSimpleHourDate: (min) => {
        return dayjs.unix(min * 60).utc().format("H[h]mm")
    },
    milliToDate: (milli, withYear = false, withDay = true) => {
        let date = dayjs(milli);
        let formatDate = "MMMM";
        if (withYear || !date.isSame(dayjs(), "year")) {
            formatDate += " YYYY";
        }
        if (withDay) {
            return date.format("D " + formatDate);
        } else {
            let res = date.format(formatDate);
            return res.charAt(0).toUpperCase() + res.slice(1);
        }
    },
    hourDurationToMilli: (date) => {
        return date.hour() * 3600000 + date.minute() * 60000;
    },
    hourDurationToMin: (date) => {
        return date.hour() * 60 + date.minute();
    },
    dateToMilli: (date) => {
        return date.hour(0).minute(0).second(0).millisecond(0).valueOf();
    }
}
