import {useEffect, useState} from "react";
import {Button, Col, List, Row} from "antd";
import ClientComponent from "./ClientComponent";
import {PlusOutlined} from "@ant-design/icons";
import ClientFormModal from "./ClientFormModal";
import StateInUrlDAO from "../../../utils/StateInUrlDAO";

const ClientListComponent = ({clients, deleteClient, setActifClient, refreshClients}) => {

    const [showAddClientModal, setShowAddClientModal] = useState(false);
    const [clientToEdit, setClientToEdit] = useState(null);

    const clientSaved = () => {
        StateInUrlDAO.removeQueryParam("client_modal")
        setShowAddClientModal(false);
        refreshClients();
    }

    useEffect(() => {
        if (StateInUrlDAO.hasQueryParam("client_modal") && clients.length) {
            if (StateInUrlDAO.getQueryParam("client_modal") === "new") {
                setClientToEdit(null)
                setShowAddClientModal(true)
            } else {
                let client = clients.find(client => client.id === parseInt(StateInUrlDAO.getQueryParam("client_modal")))
                if (client) {
                    setClientToEdit(client)
                    setShowAddClientModal(true)
                }
            }
        }
    }, [clients]);

    return <>
        <Row>
            <Col span={24}>
                <>
                    <Row>
                        <Col span={24} style={{marginBottom: "0.5em"}}>
                            <Button block onClick={() => {
                                setClientToEdit(null)
                                setShowAddClientModal(true)
                                StateInUrlDAO.setQueryParam("client_modal", "new")
                            }} icon={<PlusOutlined/>}>Ajouter un client</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <List
                                pagination={{
                                    position: "bottom",
                                    align: "center"
                                }}
                                dataSource={clients}
                                renderItem={(client, index) => (
                                    <Col span={24}>
                                        <ClientComponent
                                            client={client}
                                            deleteClient={() => deleteClient(client.id)}
                                            setActifClient={(actif) => setActifClient(client, actif)}
                                            openEditForm={() => {
                                                setClientToEdit(client)
                                                setShowAddClientModal(true)
                                                StateInUrlDAO.setQueryParam("client_modal", client.id)
                                            }}
                                        />
                                    </Col>
                                )}
                            />
                        </Col>
                    </Row>
                </>
            </Col>
        </Row>
        {showAddClientModal ? <ClientFormModal
            client={clientToEdit}
            onCancel={() => {
                setShowAddClientModal(false)
                StateInUrlDAO.removeQueryParam("client_modal")
            }}
            clientSaved={clientSaved}
        /> : null}
    </>;
}

export default ClientListComponent;