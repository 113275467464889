import {Col, Row, Typography} from "antd";
import HourlyRateTable from "./HourlyRateTable";

const HourlyRatePageComponent = () => {

    return (
        <div className="content">
            <Row justify="center">
                <Col span={24} style={{maxWidth: "1500px", width: "95%"}}>
                    <Typography.Title level={1} style={{marginBottom: "20px"}}>Taux horaire</Typography.Title>
                    <Row justify="space-between">
                        <Col span={24}>
                            <Row style={{
                                backgroundColor: "white",
                                padding: "1.75em",
                                borderRadius: "0.5em",
                                border: "1px solid #f0f0f0"
                            }}>
                                <div style={{width: "100%"}}>
                                    <HourlyRateTable/>
                                </div>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}

export default HourlyRatePageComponent;