import {MilliFormater} from "../MilliFormater";
import {Col, Row, Tag} from "antd";
import {useEffect, useState} from "react";
import {UserService} from "../../services/UserService";
import UserAuthority from "../UserAuthority";

const MaxDurationTag = ({date, workTimes, filters, maxDurations, sumDurations, noColor = false}) => {

    const [maxDayWorkingDuration, setMaxDayWorkingDuration] = useState(0);

    const [users, setUsers] = useState([]);

    useEffect(() => {
        if (filters?.users?.length === 1 && filters?.users?.[0]?.id === "all") {
            UserService.gets({authority_ids: [UserAuthority.ASSOCIE.id, UserAuthority.COLLABORATEUR.id]}, (err, data) => {
                if (!err) {
                    setUsers(data);
                }
            })
        } else {
            setUsers(filters?.users);
        }
    }, [filters?.users]);

    useEffect(() => {
        if (maxDurations !== undefined) {
            setMaxDayWorkingDuration(maxDurations);
        } else {
            let weekDay = date.weekday();
            if (users.length === 1 && users[0].workLimits.find(workLimit => workLimit.dayOfWeek === weekDay)?.maxWorkDuration !== -1) {
                setMaxDayWorkingDuration(users[0].workLimits.find(workLimit => workLimit.dayOfWeek === weekDay)?.maxWorkDuration);
            } else if (users.length > 0 && users.filter(user => user.workLimits.find(workLimit => workLimit.dayOfWeek === weekDay)?.maxWorkDuration === -1).length === users.length) {
                setMaxDayWorkingDuration(-1);
            } else {
                setMaxDayWorkingDuration(Object.values(workTimes.reduce((acc, workTime) => ({
                    ...acc,
                    [workTime.user.id]: {
                        user: workTime.user,
                        wts:[...(acc[workTime.user.id]?.wts || []), workTime]
                    }
                }), users.reduce((acc, user) => ({...acc, [user.id]: {user: user, wts: []}}), {}))).map(({user, wts}) => {
                    let sumDurations = wts.reduce((acc, workTime) => acc + workTime.duration, 0);
                    let maxDurations = user.workLimits.find(workLimit => workLimit.dayOfWeek === weekDay)?.maxWorkDuration;
                    return (maxDurations > sumDurations ? maxDurations : sumDurations)
                }).reduce((acc, maxDuration) => acc + maxDuration, 0))
            }
        }
    }, [date, users, maxDurations, workTimes]);

    let durationsSum = sumDurations || workTimes?.reduce((a, b) => a + b.duration, 0) || 0;

    let color = !noColor && durationsSum < maxDayWorkingDuration && (!filters || filters.clients[0].id === "all") ? "red" : undefined;
    return <Tag color={color} style={{margin: 0}}>
        <Row>
            <Col style={{fontSize: "0.9em"}}>{MilliFormater.minToHoursMinutes(durationsSum)}</Col>
            {maxDayWorkingDuration !== -1 ? <>
                <Col className="max-day-working-duration" style={{margin: "0 0.2em", fontSize: "0.9em"}}> / </Col>
                <Col className="max-day-working-duration"
                     style={{fontSize: "0.9em"}}>{MilliFormater.minToHoursMinutes(maxDayWorkingDuration)}</Col>
            </> : ""}
        </Row>
    </Tag>
}

export default MaxDurationTag;