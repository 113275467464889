import {Input, Row} from "antd";
import {CompanyTypeService} from "../../../services/CompanyTypeService";
import Cell from "../../../utils/table/Cell";

const CompanyTypesRow = ({companyTypes, onDelete, showAddCompanyTypeColumn}) => {

    return (
        <Row style={{width: "100%"}} wrap={false}>
            {companyTypes.map((companyType, index) => (
                <Cell
                    style={{
                        fontWeight: "bold",
                        padding: "0 1em",
                        backgroundColor: "#FAFAFA",
                        borderRight: index === companyTypes.length - 1 ? "none" : "1px solid #F0F0F0",
                        width: (Math.round(100 / companyTypes.length) + "%"),
                        minWidth: "15em",
                        borderRadius: index === 0 ? "0.5em 0 0 0" : index === companyTypes.length - 1 && !showAddCompanyTypeColumn ? "0 0.5em 0 0" : "0",
                    }}
                    key={companyType.id}
                    value={companyType}
                    render={(value) => value}
                    getContent={(value) => value.name}
                    setContent={(content) => companyType.name = content}
                    editInput={<Input autoFocus placeholder="Type d'organisation"/>}
                    onDelete={() => onDelete(companyType.id)}
                    deletable={() => companyType.deletable}
                    contentName=" type d'organisation"
                    updateApi={CompanyTypeService.put}
                    deleteApi={(callback) => CompanyTypeService.delete(companyType.id, callback)}
                />
            ))}
        </Row>
    );
}

export default CompanyTypesRow;