import {TimePicker} from "antd";
import dayjs from "dayjs";
import {useEffect, useState} from "react";

const arrayRange = (start, stop, step) =>
    Array.from(
        { length: (stop - start) / step + 1 },
        (value, index) => start + index * step
    );


const getDisabledHours = (maxDayWorkingDuration) => {
    if(!!maxDayWorkingDuration) {
        const maxHour = dayjs.unix(maxDayWorkingDuration * 60).utc().hour();
        return arrayRange(maxHour+1, 23, 1);
    } else {
        return [];
    }
};

const getDisabledMinutes = (selectedHour, maxDayWorkingDuration, minuteStep, allowZero) => {
    let disabledMinutes = arrayRange(0, 59, 1).filter(minute => minute % minuteStep !== 0);
    if(!!maxDayWorkingDuration) {
        const maxHour = dayjs.unix(maxDayWorkingDuration * 60).utc().hour();
        if ((selectedHour === -1 || selectedHour === 0) && !allowZero) {
            return [0, ...disabledMinutes];
        } else if(selectedHour === maxHour) {
            return arrayRange(1, 59, 1);
        } else {
            return disabledMinutes;
        }
    } else if (selectedHour === -1 || selectedHour === 0 && !allowZero) {
        return [0, ...disabledMinutes]
    } else {
        return disabledMinutes;
    }
};

const DurationPicker = ({user, date, disabled, value, minuteStep, onChange, placement, placeholder, allowZero = false}) => {

    const [open, setOpen] = useState(undefined);
    const [disabledHours, setDisabledHours] = useState([]);
    const [disabledMinutes, setDisabledMinutes] = useState({});

    useEffect(() => {
        let dayOfWeek = date?.weekday();
        let maxDayWorkingDuration = user?.workLimits.find(workLimit => workLimit.dayOfWeek === dayOfWeek)?.maxWorkDuration
        setDisabledHours(getDisabledHours(maxDayWorkingDuration))
        arrayRange(0, 23, 1).forEach(hour => {
            setDisabledMinutes(prevState => {
                return {
                    ...prevState,
                    [hour]: getDisabledMinutes(hour, maxDayWorkingDuration, minuteStep, allowZero)
                }
            })
        })
    }, [user, date])

    const _onChange = (_value) => {
        if(!!value && (value.isSame(_value) || (!!value && _value?.get("minute") !== value?.get("minute")) || (!value && _value?.get("minute") !== 0))) {
            setOpen(false)
        } else if (!value && !!_value) {
            let now = dayjs();
            let isMinuteNow = _value.get("minute") === now.get("minute")
            let isHourNow = _value.get("hour") === now.get("hour")
            if (!isMinuteNow && isHourNow) {
                _value = _value.hour(0)
                setOpen(false)
            }
            if (!isHourNow && isMinuteNow) {
                _value = _value.minute(0)
            }
        }
        if (_value) {
            let dayOfWeek = date?.weekday();
            let maxDayWorkingDuration = user?.workLimits.find(workLimit => workLimit.dayOfWeek === dayOfWeek)?.maxWorkDuration
            if (!!maxDayWorkingDuration && _value?.hour() > dayjs.unix(maxDayWorkingDuration * 60).utc().hour()) {
                _value = _value.hour(dayjs.unix(maxDayWorkingDuration * 60).utc().hour())
            }
            if (disabledMinutes[_value.hour()].includes(_value.minute())) {
                let minute = (Math.round(_value.minute()/15) * 15) % 60
                if (_value.hour() === 0 && minute === 0 && !allowZero) {
                    _value = _value.minute(15)
                } else if (!!maxDayWorkingDuration && _value.hour() === dayjs.unix(maxDayWorkingDuration * 60).utc().hour() && minute !== 0) {
                    _value = _value.minute(0)
                } else {
                    _value = _value.minute(minute)
                }
            }
        }
        onChange(_value);
    }

    useEffect(() => {
        if(open === false) {
            setOpen(undefined);
        }
    }, [open])

    return <TimePicker
        open={open}
        disabled={disabled}
        disabledTime={(_) => ({disabledHours: () => disabledHours, disabledMinutes: (selectedHour) => disabledMinutes[selectedHour]})}
        onChange={_onChange}
        onSelect={_onChange}
        value={value}
        showNow={false}
        minuteStep={minuteStep}
        popupClassName="durationPicker"
        placement={placement}
        style={{width: "100%"}}
        placeholder={placeholder || "Durée"}
        format="H [h] mm"
        changeOnBlur={true}
    />;
}

export default DurationPicker;
