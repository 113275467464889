import dayjs from "dayjs";
import {Badge, Col, Row} from "antd";
import {LockOutlined} from "@ant-design/icons";
import MaxDurationTag from "./MaxDurationTag";
const disabledFontColor = `rgba(0, 0, 0, 0.25)`

const WTCalandarDayCardExtraHeader = ({filters, workTimes, isArchived, date, disabled}) => {

    return <Row gutter={5} align="middle">
        {dayjs().isSame(date, "day") ? <Col>
            <Badge status="processing"/>
        </Col> : null}
        {isArchived && <LockOutlined style={{color: (disabled ? disabledFontColor : undefined)}}/>}
        {disabled ? null :
            <Col>
                <MaxDurationTag date={date} workTimes={workTimes} filters={filters}/>
            </Col>}
    </Row>
}

export default WTCalandarDayCardExtraHeader;