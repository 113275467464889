import {Button, Col, Row, Form} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import MissionLetterForm from "./MissionLetterForm";
import dayjs from "dayjs";

const MissionLettersFormList = ({formValidating}) => {
    return <Form.List
        name="missionLetters"
    >
        {
            (fields, {add, remove}) => (
                <>
                    <Row>
                        <Col span={24} style={{marginBottom: "0.5em"}}>
                            <Button
                                block
                                icon={<PlusOutlined/>}
                                onClick={() => add({signature: dayjs().startOf("month")}, 0)}
                                disabled={formValidating}>
                                Ajouter une lettre de mission
                            </Button>
                        </Col>
                    </Row>
                    {
                        fields.map(field => {
                            return (
                                <MissionLetterForm
                                    formValidating={formValidating}
                                    key={field.key}
                                    index={field.name}
                                    remove={() => remove(field.name)}
                                />
                            )
                        })
                    }
                </>
            )
        }
    </Form.List>
}

export default MissionLettersFormList;