import {Affix, Col, Row, Typography} from 'antd';
import WorkTimesFilterComponent from "./WorkTimesFilterComponent";
import {useEffect, useState} from "react";
import {ArchivingService} from "../../services/ArchivingService";
import WTCalandar from "../../utils/calandar/WTCalandar";

const {Title} = Typography;

const WorkTimesComponent = () => {

    const [filters, setFilters] = useState(null)
    const [loadingWorkTimes, setLoadingWorkTimes] = useState(true)
    const [archivedDate, setArchivedDate] = useState(null)

    useEffect(() => {
        ArchivingService.getCurrentArchivingDate((err, data) => {
            if (!err) {
                setArchivedDate(data)
            }
        })
    }, [])

    return (
        <div className="content">
        <Row justify="center">
            <Col span={24} style={{maxWidth: "1500px"}}>
                <Title level={1} style={{marginBottom: "30px"}}>Temps de travail</Title>
                <Row>
                    <Col span={24}>
                        <Affix offsetTop={75}>
                            <Row>
                                <Col span={24} style={{
                                    backgroundColor: "white",
                                    padding: "1.75em",
                                    marginRight: "0.75em",
                                    marginBottom: "0.75em",
                                    borderRadius: "0.5em",
                                    border: "1px solid #f0f0f0",
                                }}>
                                    <WorkTimesFilterComponent
                                        archivedDate={archivedDate}
                                        setArchivedDate={setArchivedDate}
                                        setFilters={setFilters}
                                        loadingWorktimes={loadingWorkTimes}
                                    />
                                </Col>

                            </Row>
                        </Affix>
                    </Col>
                </Row>
                <Row justify="space-between">
                    <Col span={24}>
                        <Row style={{backgroundColor: "white", padding: "1.75em", borderRadius: "0.5em", border: "1px solid #f0f0f0"}}>
                            <div style={{width: "100%"}}>
                                <WTCalandar
                                    archivedDate={archivedDate}
                                    setLoadingWorkTimes={setLoadingWorkTimes}
                                    filters={filters}
                                    showWeekNumber={false}
                                />
                            </div>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
        </div>
    );
};

export default WorkTimesComponent
