import {Select, Skeleton, Typography} from "antd";
import {useCallback, useEffect, useState} from "react";

const AbstractSelect = ({
                            getValue,
                            getPrint,
                            disabled,
                            getGroup,
                            elements,
                            antValue,
                            antOnChange,
                            loading,
                            allowClear,
                            mode,
                            onChange,
                            elementName,
                            disabledOption = (_) => false,
                            filterOn,
                            size,
                            style,
                            variant
                        }) => {
    const [options, setOptions] = useState([]);

    const elementToOption = useCallback((element) => {
        return <Select.Option value={getValue(element)}
                              key={getValue(element)}
                              disabled={disabledOption(element)}><div className="option-text">{getPrint(element)}</div></Select.Option>;
    }, [])

    useEffect(() => {
        if (!disabled) {
            if (!!getGroup) {
                setOptions(Object.values(elements.reduce(
                    (acc, element) => {
                        let group = getGroup(element);
                        return {
                            ...acc,
                            [group]: {
                                name: group,
                                options:[
                                    ...acc[group]?.options || [],
                                    elementToOption(element)
                                ]
                            }
                        }
                    },
                    {}))
                    .map(group => <Select.OptGroup label={group.name} key={group.name}>
                        {group.options}
                    </Select.OptGroup>)
                )
            } else {
                setOptions(elements
                    .map(elementToOption));
            }
        }
    }, [elements, elementToOption, disabled])

    useEffect(() => {
        if (disabled && !!antValue && elements?.length > 0) {
            if (mode === "multiple") {
                setOptions(antValue.map(value => elementToOption(elements.find(element => getValue(element) === value))));
            } else {
                setOptions([elementToOption(elements.find(element => getValue(element) === antValue))]);
            }
        }
    }, [disabled, antValue, elements?.length])

    const _onChange = (value) => {
        if (onChange) {
            onChange(value);
        }
        antOnChange(value);
    }

    if (loading) {
        return <Skeleton.Input active block size={size} style={style}/>
    } else {
        return <Select
            style={style}
            size={size}
            allowClear={allowClear}
            value={antValue}
            onChange={_onChange}
            mode={mode}
            placeholder={elementName}
            disabled={options?.length < 2 && !allowClear ? true : disabled}
            showSearch={true}
            filterOption={(input, option) => {
                if(filterOn) {
                    return filterOn(input, option);
                } else {
                    if (!!option.children?.props.children) {
                        return option.children?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    } else {
                        return false;
                    }
                }
            }}
            variant={variant}
        >
            {options}
        </Select>;
    }
}

export default AbstractSelect;
