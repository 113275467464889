const ApiParamBuilder = {
    idsToQueryParam : (ids) => {
        return ids ? (ids.includes('all') ? null : ids.map(id => "" + id).join(',')) : null;
    },
    dateToQueryParam : (date) => {
        return date ? date.format('YYYY-MM-DD') : undefined;
    },
    periodToQueryParam : (period) => {
        return period ? {
            after: ApiParamBuilder.dateToQueryParam(period[0]),
            before: ApiParamBuilder.dateToQueryParam(period[1])
        } : {};
    },
    needToGroupBy : (ids) => {
        return ids?.length;
    }
}

export default ApiParamBuilder;