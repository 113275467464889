import AbstractSelect from "./AbstractSelect";
import {MilliFormater} from "../../MilliFormater";

const FiscalYearSelect = ({customOnChange, fiscalYears, allowClear, disabled, value, onChange, loading, computeDisabled= false, allowMultiple}) => {

    return <AbstractSelect
        onChange={customOnChange}
        elements={fiscalYears}
        getValue={(fiscalYear) => fiscalYear.id}
        getPrint={(fiscalYear) => fiscalYear.end ? MilliFormater.milliToDate(fiscalYear.end, true, false) : fiscalYear.name}
        disabledOption={computeDisabled ? (fiscalYear) => !fiscalYear.actif : (fiscalYear) => false}
        elementName="Exercice fiscal"
        allowClear={allowClear}
        disabled={disabled}
        antValue={value}
        antOnChange={onChange}
        loading={loading}
        mode={allowMultiple ? "multiple" : null}
    />;
}

export default FiscalYearSelect;
