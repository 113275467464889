import {Button, Col, Form, Input, Row, Typography} from "antd";
import {CheckCircleTwoTone, LockOutlined, UserOutlined} from "@ant-design/icons";
import {useEffect, useState} from "react";
import {AuthenticationService} from "../../../services/AuthenticationService";
import {NotificationSender} from "../../../utils/NotificationSender";
import {useNavigate, useParams} from "react-router-dom";
import Session from "../../../session/Session";

const {Title} = Typography;

const ResetPasswordComposent = () => {
    let navigate = useNavigate();
    const [loadingButton, setLoadingButton] = useState(false);
    const [currentPassword, setCurrentPassword] = useState("");
    const [form] = Form.useForm();
    const {token} = useParams();

    const [passwordLength, setPasswordLength] = useState(false);
    const [passwordLowercase, setPasswordLowercase] = useState(false);
    const [passwordUppercase, setPasswordUppercase] = useState(false);
    const [passwordNumber, setPasswordNumber] = useState(false);
    const [passwordSpecial, setPasswordSpecial] = useState(false);

    useEffect(() => {
        if (Session.user) {
            AuthenticationService.logout();
        }
    }, []);

    useEffect(() => {
        setPasswordLength(currentPassword.length >= 8)
        setPasswordLowercase(/[a-z]/.test(currentPassword))
        setPasswordUppercase(/[A-Z]/.test(currentPassword))
        setPasswordNumber(/[0-9]/.test(currentPassword))
        setPasswordSpecial(/[@$!%*#?&]/.test(currentPassword))
    }, [currentPassword])

    const onChangePassword = (e) => {
        setCurrentPassword(e?.target?.value);
    }

    const onFinish = (values) => {
        setLoadingButton(true)
        let passwordCriteria = passwordLength && passwordLowercase && passwordUppercase && passwordNumber && passwordSpecial;
        let passwordCheck = currentPassword === values.check_password;
        if (passwordCriteria && passwordCheck) {
            AuthenticationService.reset_password(token, values.username, values.password, (err, data) => {
                if (err?.errors?.fields) {
                    form.setFields(err.errors.fields.map(field => ({
                        name: field.field,
                        errors: [field.message]
                    })))
                } else {
                    NotificationSender.success("Réinitialisation", "Votre nouveau mot de passe à bien été enregistré. Si ce n'est pas le cas, contactez le support.")
                    navigate("/")
                }
                setLoadingButton(false)
            })
        } else {
            form.setFields([
                {
                    name: "check_password",
                    errors: (!passwordCheck ? ["La valeur saisie ne correspond pas"] : null)
                },
                {
                    name: "password",
                    errors: (!passwordCriteria ? ["L'ensemble des critères doivent être respectés"] : null)
                }
            ])
            setLoadingButton(false)
        }
    };

    return (
        <Row justify="center" align="middle" style={{height: "100%"}}>
            <Col span={9}
                 style={{backgroundColor: "white", padding: "3em", borderRadius: "0.5em", maxWidth: "40em"}}>
                <Title level={2} style={{marginBottom: "0.75em"}}>Réinitialisation mot de passe</Title>
                <Form
                    form={form}
                    name="reset_password"
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="username"
                        hasFeedback={false}
                        rules={[{required: true, message: "Identifiant à saisir"}]}
                    >
                        <Input
                            prefix={<UserOutlined className="site-form-item-icon"/>}
                            placeholder="Identifiant"
                        />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        style={{marginBottom: "0.7em"}}
                        hasFeedback={false}
                        rules={[{required: true, message: "Mot de passe à saisir"}]}
                    >
                        <Input.Password
                            onChange={onChangePassword}
                            prefix={<LockOutlined className="site-form-item-icon"/>}
                            placeholder="Mot de passe"
                        />
                    </Form.Item>
                    <Row style={{marginTop: "0.7em", marginBottom: "0.7em"}}>
                        <Col span={24}>
                            <Row><Col style={{marginRight: "0.5em", marginLeft: "0.5em"}}><CheckCircleTwoTone
                                twoToneColor={passwordLength ? "#52c41a" : "lightgray"}/></Col><Col>Au moins 8
                                caractères</Col></Row>
                            <Row><Col style={{marginRight: "0.5em", marginLeft: "0.5em"}}><CheckCircleTwoTone
                                twoToneColor={passwordUppercase ? "#52c41a" : "lightgray"}/></Col><Col>Au moins une
                                majuscule</Col></Row>
                            <Row><Col style={{marginRight: "0.5em", marginLeft: "0.5em"}}><CheckCircleTwoTone
                                twoToneColor={passwordLowercase ? "#52c41a" : "lightgray"}/></Col><Col>Au moins une
                                minuscule</Col></Row>
                            <Row><Col style={{marginRight: "0.5em", marginLeft: "0.5em"}}><CheckCircleTwoTone
                                twoToneColor={passwordNumber ? "#52c41a" : "lightgray"}/></Col><Col>Au moins un
                                chiffre</Col></Row>
                            <Row><Col style={{marginRight: "0.5em", marginLeft: "0.5em"}}><CheckCircleTwoTone
                                twoToneColor={passwordSpecial ? "#52c41a" : "lightgray"}/></Col><Col>Au moins un
                                caractère spécial (@, $, !, %, *, #, ?, &)</Col></Row>
                        </Col>
                    </Row>
                    <Form.Item
                        name="check_password"
                        hasFeedback={false}
                        rules={[{required: true, message: "Confirmation de mot de passe à saisir"}]}
                    >
                        <Input.Password
                            prefix={<LockOutlined className="site-form-item-icon"/>}
                            placeholder="Confirmation de mot de passe"
                        />
                    </Form.Item>
                    <Form.Item style={{marginBottom: 0}}>
                        <Row justify="center">
                            <Button type="primary" htmlType="submit"
                                    style={{width: "75%", marginTop: "1em"}} loading={loadingButton}>
                                Enregistrer
                            </Button>
                        </Row>
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    );
};

export default ResetPasswordComposent;
