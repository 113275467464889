import {Affix, Col, Row, Typography} from "antd";
import ClientFiltreComponent from "./ClientFiltreComponent";
import ClientListComponent from "./ClientListComponent";
import {useEffect, useState} from "react";
import {ClientService} from "../../../services/ClientService";

const ClientPageComponent = () => {

    const [filters, setFilters] = useState(null);
    const [loadingClients, setLoadingClients] = useState(true);
    const [clients, setClients] = useState([]);
    const [filteredClients, setFilteredClients] = useState([]);
    const [forceRefresh, setForceRefresh] = useState(false);

    useEffect(() => {
        ClientService.gets((err, data) => {
            if (!err) {
                setClients(data);
            }
            setLoadingClients(false)
        }, true)
    }, [])

    useEffect(() => {
        if (!loadingClients) {
            if (filters?.search) {
                let searchString = filters.search.toLowerCase();
                setFilteredClients(clients.filter(client => (client.name.toLowerCase() + " " + client.identifier.toLowerCase()).includes(searchString)))
            } else {
                setFilteredClients(clients)
            }
        }
    }, [filters, loadingClients, clients.length, forceRefresh])

    const deleteClient = (id) => {
        ClientService.delete(id, (err) => {
            if (!err) {
                setClients(clients.filter(client => client.id !== id))
            }
        })
    }

    const setActifClient = (client, actif) => {
        ClientService.put({
            ...client,
            tasksId: client.tasks.map(task => task.id),
            companyTypeId: client.companyType.id,
            actif: actif
        }, (err, data) => {
            if (!err) {
                refreshClients()
            }
        })
    }

    const refreshClients = () => {
        ClientService.gets((err, data) => {
            if (!err) {
                setClients(data);
                setForceRefresh(!forceRefresh)
            }
        })
    }

    return (<div className="content">
        <Row justify="center">
            <Col span={24} style={{maxWidth: "1500px"}}>
                <Typography.Title level={1} style={{marginBottom: "20px"}}>Clients</Typography.Title>
                <Row>
                    <Col span={24}>
                        <Affix offsetTop={135}>
                            <Row>
                                <Col span={24} style={{
                                    backgroundColor: "white",
                                    padding: "1.75em",
                                    marginRight: "0.75em",
                                    marginBottom: "0.75em",
                                    borderRadius: "0.5em",
                                    border: "1px solid #f0f0f0"
                                }}>
                                    <ClientFiltreComponent
                                        setFilters={setFilters}
                                        loadingClients={loadingClients}
                                    />
                                </Col>

                            </Row>
                        </Affix>
                    </Col>
                </Row>
                <Row justify="space-between">
                    <Col span={24}>
                        <Row style={{
                            backgroundColor: "white",
                            padding: "1.75em",
                            borderRadius: "0.5em",
                            border: "1px solid #f0f0f0"
                        }}>
                            <div style={{width: "100%"}}>
                                <ClientListComponent
                                    loading={loadingClients}
                                    clients={filteredClients}
                                    deleteClient={deleteClient}
                                    setActifClient={setActifClient}
                                    forceRefresh={forceRefresh}
                                    refreshClients={refreshClients}
                                />
                            </div>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    </div>)
}

export default ClientPageComponent;
