import {WorkTimeRepository} from "../repositories/WorkTimeRepository";
import {NotificationSender} from "../utils/NotificationSender";
import Session from "../session/Session";
import dayjs from "dayjs";

const arrayRangeDate = (start, stop, step) => {
    return Array.from(
        {length: stop.diff(start, 'day') / step + 1},
        (value, index) => start.add(index * step, 'day')
    );}

export const WorkTimeService = {
    getsGroupByWeekAndDaySkeleton: (after, before, loading = true) => {
        const dayjsAfter = dayjs(after);
        const dayjsBefore = dayjs(before);
        return arrayRangeDate(dayjsAfter.startOf("week"), dayjsBefore.endOf("week"), 1)
            .map(date => {
                return ({
                    date: date,
                    loading: loading,
                    workTimes: [],
                    disabled: date.isAfter(dayjsBefore) || date.isBefore(dayjsAfter)
                })
            })
            .reduce((acc, date) => {
                let startOfWeek = date.date.startOf("week");
                let startOfWeekFormated = startOfWeek.format("YYYY-MM-DD");
                let day = date.date.weekday()
                return {
                    ...acc,
                    [startOfWeekFormated]: {
                        ...acc[startOfWeekFormated],
                        startOfWeek: startOfWeek,
                        days: {
                            ...acc[startOfWeekFormated]?.days,
                            [day]: date
                        }
                    }
                }
            }, {}
        )
    },
    getsGroupByWeekAndDay: (filter, callback) => {
        let skeleton = WorkTimeService.getsGroupByWeekAndDaySkeleton(filter.after, filter.before, false)
        WorkTimeService.gets(filter, (err, workTimes) => {
            if (err) {
                callback(err)
            }
            callback(false, workTimes.reduce((acc, worktime) => {
                let formatedDate = dayjs(worktime.date);
                let startOfWeek = formatedDate.startOf("week").format("YYYY-MM-DD");
                let day = formatedDate.weekday()
                return {
                    ...acc,
                    [startOfWeek]: {
                        ...acc[startOfWeek],
                        days: {
                            ...acc[startOfWeek].days,
                            [day]: {
                                ...acc[startOfWeek].days[day],
                                workTimes: [
                                    ...acc[startOfWeek].days[day].workTimes,
                                    worktime
                                ]
                            }
                        }
                    }
                }
            }, skeleton))
        })
    },
    gets: (filter, callback) => {
        Session.resetTimerBeforeLogout()
        WorkTimeRepository.gets(filter, (err, workTimes) => {
            if (err) {
                if (!err.errors) {
                    NotificationSender.error("Chargement", "Une erreur est survenue lors du chargement des temps de travail")
                }
                callback(err)
            } else {
                callback(false, workTimes)
            }
        })
    },
    post: (workTime, callback) => {
        Session.resetTimerBeforeLogout()
        WorkTimeRepository.post(workTime, (err, data) => {
            if (err) {
                if (!err.errors) {
                    NotificationSender.error("Ajout", "Une erreur est survenue lors de l'ajout du temps de travail")
                }
                callback(err)
            } else {
                NotificationSender.success("Ajout", "Le temps de travail à bien été ajouté")
                callback(false, data)
            }
        })
    },
    put: (workTime, callback) => {
        Session.resetTimerBeforeLogout()
        if (Array.isArray(workTime)) {
            workTime = workTime[0]
        }
        WorkTimeRepository.put(workTime.id, workTime, (err, data) => {
            if (err) {
                if (!err.errors) {
                    NotificationSender.error("Modification", "Une erreur est survenue lors de la modification du temps de travail")
                }
                callback(err)
            } else {
                NotificationSender.success("Modification", "Le temps de travail à bien été modifié")
                callback(false, data)
            }
        })
    },
    delete: (id, callback) => {
        Session.resetTimerBeforeLogout()
        WorkTimeRepository.delete(id, (err) => {
            if (err) {
                let description = "Une erreur est survenue lors de la suppression du temps de travail"
                if (!!err.errors) {
                    description = err.errors.globals[0].message
                }
                NotificationSender.error("Suppression", description)
                callback(true)
            } else {
                NotificationSender.success("Suppression", "Le temps de travail à bien été supprimé")
                callback(false)
            }
        })
    },
    download: (filter, callback) => {
        Session.resetTimerBeforeLogout()
        WorkTimeRepository.download(filter, (err) => {
            if (err) {
                if (!err.errors) {
                    NotificationSender.error("Téléchargement", "Une erreur est survenue lors du téléchargement des temps de travail")
                }
                callback(err)
            } else {
                callback(false)
            }
        })
    },
    dashboard: (filters, callback) => {
        Session.resetTimerBeforeLogout()
        WorkTimeRepository.dashboard(filters, (err, data) => {
            if (err) {
                if (!err.errors) {
                    NotificationSender.error("Chargement", "Une erreur est survenue lors du chargement du tableau de bord")
                }
                callback(err)
            } else {
                callback(false, data)
            }
        })
    }
}
